import * as Yup from "yup";

export const EditNewClientSchema = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .required('First name is required.')
        .min(3, 'First name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .required('Last name is required.')
        .min(3, 'Last name should be more than 3 characters.'),
    // businessname: Yup.string()
    //     .trim()
    //     .required('Business name is required')
    //     .min(3, 'Business name should be more than 3 characters.'),
    // email: Yup.string()
    //     .email('Your email address is invalid')
    //     .required('Email address is required'),
    // phone: Yup.string()
    //     .nullable()
    //     .min(10, "Phone number is invalid")
    //     .required('Phone number is required!')
    //     .matches(
    //         /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    //         "Phone number is invalid"
    //     ),
    // role: Yup.string()
    // .required('Role is required!'),
});
