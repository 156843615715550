import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from "utils/eventbus";
import FormField from "layouts/accountants/user/components/FormFieldAddCategory";
import { useAPICall } from "utils/useapicall";
import { AddCategoryApi ,GetCategoryListForParent} from "services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { EditCategorySchema } from "layouts/clients/components/schemas/editcategory.js";
import { EditAddParentsCategorySchema } from "layouts/clients/components/schemas/addparentscategory";
import { data } from "layouts/clients/QboAccountTypes.js";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";
import {  Autocomplete, Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";

function AddNewCategoryDialog({ Edit, onClose, invoiceId, businessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [classification, setClassification] = useState("");
  const [accountType, setAccountType] = useState("");
  const [jsondata] = useState(data[0].classifications);
  const [jsonName, setJsonName] = useState("");
  const [accountSubTypeJson, setAccountSubTypeJson] = useState([]);
  const [classificationJson, setClassificationJson] = useState([]);
  const [accountTypeName, setAccountTypeName] = useState("");
  const [accountSubTypeName, setAccountSubTypeName] = useState("");
  const [number , setNumber] = useState("");
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scrollLoading ,setScrollLoading] = useState(false);
  const [parentAccount , setParentAccount] = useState("");
  const [parentAccountObj , setParentAccountObj] = useState("");
  const [parentAccountId , setParentAccountId] = useState("");
  const [ { isLoading: isAddCategoryLoading, isError: isAddCategoryError, response: AddCategoryResponse }, AddCategoryListAPICall ] = useAPICall(AddCategoryApi, { onLoad: false });
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [isSubAccount , setIsSubAccountant] = useState(false);
  const [parentCategoryName, setParentCategoryName] = useState('');
  const [editStyle, setEditStyle] = useState({ marginTop: "35px", borderTop: "0px", borderLeft: "0px", borderRight: "0px", color: "slategray",});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    Edit === true
      ? setEditStyle({
          marginTop: "35px",
          borderTop: "0px",
          borderLeft: "0px",
          borderRight: "0px",
          color: "slategray",
        })
      : setEditStyle({
          marginTop: "24px",
          borderTop: "0px",
          borderLeft: "0px",
          borderRight: "0px",
          color: "slategray",
        });
  }, []);

  useEffect(()=>{
    setCategoryName(accountSubTypeName);
  },[accountSubTypeName])

  useEffect(() => {
    if (isAddCategoryLoading) {
      return () => null;
    }
    if (isAddCategoryError) {
      eventBus.$emit("AddnewCategoryFailed", AddCategoryResponse?.data);
      setErrorMessage(AddCategoryResponse?.data?.message);
      setLoading(false);
      setBtnDisable(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (AddCategoryResponse && AddCategoryResponse?.data?.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("AddnewCategoryComplete", AddCategoryResponse?.data);
      onClose();
    }
  }, [AddCategoryResponse]);

  useEffect(() => {
    if(!accountTypeName){
      setAccountSubTypeName('');
      setCategoryName('');
    }
    // Find the account type and its parent category
    const accountCategory = jsondata.find(category =>
      category.accountTypes.some(type => type.name === accountTypeName)
    );

    if (accountCategory) {
      // Find the matching account type
      const accountType = accountCategory.accountTypes.find(type => type.name === accountTypeName);
      if (accountType) {
        // Update the parent category name and subtypes
        setParentCategoryName(accountCategory.name);
        setClassificationJson(accountType.subTypes);
        setAccountSubTypeName(accountType.subTypes[0].name);
      }
    }
  }, [accountTypeName, jsondata]);

  useEffect(() => {
    // Update accountSubTypeJson based on classificationJson
    if (classificationJson.length > 0) {
      const obj = {};
      classificationJson.forEach((x, index) => {
        obj[index] = x; // Collect subTypes in an object
      });
      setAccountSubTypeJson(Object.values(obj)); // Update accountSubTypeJson
    }
  }, [classificationJson]);

  let initialValues = {
    category: categoryName,
    accountType: accountType,
    classification: classification,
    accountSubType: accountSubTypeName,
    isSubAccountant:isSubAccount,
    accountNo : number,
    ParentAccount: parentAccount
  };

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await AddCategoryListAPICall({
      payload: [
        {
          clientId: businessId,
          name: categoryName,
          description: values.description,
          subAccount: isSubAccount,
          parentId: isSubAccount == true && parentAccountId ? parentAccountId : null,
          classification: parentCategoryName,
          accountType: accountTypeName,
          accountSubType: accountSubTypeName,
          accountNo: values.accountNo,
        },
      ],
    });
  };

  const fetchItems = async (search = "", newOffset = 0) => {
      setScrollLoading(true);
     const response =  await GetCategoryListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${search}`,
            offset: newOffset,
            limit: 20, // Fetch 10 items at a time
          },
        ],
      });
      
        const newItems = response?.data?.data?.records || [];
        setItems(prevItems => newOffset === 0 ? newItems : [...prevItems, ...newItems]);
        setHasMore(newItems.length > 0);
        setOffset(newOffset);
        setScrollLoading(false);
     
  };
  
  const handleChecked = () => {
    setParentAccountId("");
    setParentAccountObj("");
    setParentAccount("");
    setIsSubAccountant(!isSubAccount);
  };

  const handleChange = (event)=>{
    setAccountType(event?.target?.value);
    setAccountTypeName(event?.target?.value);
  }

  const handleChangeDetailtype = (event)=>{
    setAccountSubTypeName(event?.target?.value)
  }

  const handleChangeNumber = (event)=>{
    setNumber(event?.target?.value)
  }

  const handleChangeName = (event)=>{
    setCategoryName(event?.target?.value)
  }

  const handleChangeParentAccount = (event)=>{
    setParentAccountObj(event);
    setParentAccount(event?.name);
    const selectedItem = items.find(item => item.name === event?.name);
    if (selectedItem) {
      const itemId = selectedItem._id;
      setParentAccountId(itemId);
    }
  }

  return (
    <>
      {
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={ isSubAccount ? EditAddParentsCategorySchema : EditCategorySchema }
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting  ,setFieldValue  }) => (
                    <Form autoComplete="off">
                      <MDBox p={1}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3} sx={{ mb: 1 }}>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth name="accountType" variant="standard" sx={{ m: '5px',ml:0, minWidth: 120 }}>
                                  <InputLabel sx={{
                                    color: darkMode ? '#ffffff !important' : '#7b809a',
                                    '&.Mui-focused': {
                                      top: '-6px', // Adjust the position when focused
                                    },
                                    '&.MuiFormLabel-filled': {
                                      top: '-6px', // Adjust the position when there is a value
                                    },
                                  }} >AccountType *</InputLabel>
                                  <Select
                                    native
                                    value={values.accountType}
                                    onChange={(event) => {
                                      handleChange(event);
                                      setFieldValue('accountType', event.target.value); // Update the value in Formik state
                                    }}
                                    label={"Select"} // Changed to match the other fields
                                    sx={{
                                      '& select': {
                                        "& option": {
                                          background: '#ffffff', // Dark mode option background color
                                          color: '#000000', // Text color for options based on mode
                                        },
                                        '& optgroup': {
                                          color: '#7b809a', // Text color for optgroup
                                          backgroundColor: '#ffffff', // Background color for optgroup
                                        },
                                        color: darkMode ? '#ffffff' : '#000000', // Text color in dark mode and light mode
                                        boxShadow: 'none',
                                        WebkitAppearance: 'none', // Removes default shadow in some browsers
                                        MozAppearance: 'none',
                                        padding: 0,
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: darkMode ? '#ffffff' : '#000000', // Color of the dropdown icon in dark mode and light mode
                                      },
                                      '&:before': {
                                          borderBottomColor: darkMode ? '#ffffff !important' : '#7b809a', // Underline color when not focused
                                        },
                                        '&:after': {
                                          borderBottomColor: darkMode ? '#ffffff  !important' : '#3f51b5', // Underline color when focused
                                        },
                                    }}
                                  >
                                    <option aria-label="None" value="" />
                                    {jsondata.map((dataItem, dataIndex) => (
                                      <optgroup key={dataIndex} label={dataItem.name}>
                                        {dataItem.accountTypes?.map((accountType, accountIndex) => (
                                          <option key={accountIndex} value={accountType.name}>
                                            {accountType.name}
                                          </option>
                                        ))}
                                      </optgroup>
                                    ))}
                                  </Select>
                                  <MDBox mt={0.75}>
                                    <MDTypography
                                      component="div"
                                      variant="caption"
                                      color="error"
                                      fontWeight="regular"
                                    >
                                    <ErrorMessage name="accountType" />
                                    </MDTypography>
                                  </MDBox>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField 
                                  type="text" 
                                  label="Name *"
                                  value={categoryName}
                                  onChange={handleChangeName}
                                  name="category"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 1 }}>
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="standard" sx={{ mt: 0.3 }}>
                                  <InputLabel  sx={{
                                     color: darkMode ? '#ffffff !important' : '#7b809a',
                                  '&.Mui-focused': {
                                    top: '-6px', // Adjust the position when focused
                                  },
                                  '&.MuiFormLabel-filled': {
                                    top: '-6px', // Adjust the position when there is a value
                                  },
                                  }}>Detail Type *</InputLabel>
                                  <Field as={Select} sx={{
                                      '& .MuiSelect-select': {
                                        color: darkMode ? '#ffffff' : '#000000', // Selected text color
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: darkMode ? '#ffffff' : '#000000', // Color of the dropdown icon in dark mode and light mode
                                      },
                                      '&:before': {
                                          borderBottomColor: darkMode ? '#ffffff !important' : '#7b809a', // Underline color when not focused
                                        },
                                        '&:after': {
                                          borderBottomColor: darkMode ? '#ffffff !important' : '#3f51b5', // Underline color when focused
                                        },
                                    }} name="accountSubType" onChange={handleChangeDetailtype}  variant="standard">
                                    <MenuItem value="">
                                      <em>Select Detail Type</em>
                                    </MenuItem>
                                    {accountSubTypeJson.map((x) => (
                                      <MenuItem value={x.name} key={x.name}>
                                        {x.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  <MDBox mt={0.75}>
                                    <ErrorMessage name="accountSubType">
                                      {(msg) => (
                                        <MDTypography
                                          component="div"
                                          variant="caption"
                                          color="error"
                                          fontWeight="regular"
                                        >
                                          {msg}
                                        </MDTypography>
                                      )}
                                    </ErrorMessage>
                                  </MDBox>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField  onChange={handleChangeNumber} type="text" label="Number" name="accountNo" />
                                  {number?.length >= 6 && number?.length <= 7  ? "" :
                                    number?.length !== 0 &&
                                      <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                        >
                                        Account Number should be 6 or 7 characters.
                                      </MDTypography> 
                                  }
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 1 }}>
                              <Grid item xs={12} sm={12}>
                                <FormField type="text" label="Description" name="description" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 1 }}>
                              <Grid item xs={12} sm={6}>
                              <Field
                                  as={FormControlLabel}
                                      type="checkbox"
                                      name="isSubAccountant"
                                      control={<Checkbox />}
                                      label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Is sub-accountant</span>}
                                      checked={isSubAccount}
                                      onChange={handleChecked}
                                      sx={{ color: darkMode ? "#ffffff" : "#000000" }}
                                />                   
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} sx={{ mb: 1 }}>
                              <Grid item xs={12} sm={12}>
                              {isSubAccount && 
                                <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                  <Autocomplete
                                    options={items}
                                    getOptionLabel={(option) =>option.fullyQualifiedName ? option.fullyQualifiedName : option.name || ""}
                                    value={parentAccountObj}
                                    onChange={(event, value) => {
                                        handleChangeParentAccount(value); // Store the full selected object
                                    }}
                                    onInputChange={(event, value) => {
                                        if (value.length > 0) {
                                          fetchItems(value); // Fetch items based on input
                                        }
                                    }}
                                    onOpen={() => fetchItems()} // Fetch initial data when dropdown opens
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                                label="Parent Account *"
                                                variant="standard"
                                                InputLabelProps={{
                                                  sx: {
                                                    color: darkMode? '#ffffff !important' : '#7b809a',
                                                    '&.Mui-focused': { top: '-6px' },
                                                    '&.MuiFormLabel-filled': { top: '-6px' },
                                                  },
                                                }}
                                                InputProps={{
                                                  ...params.InputProps,
                                                  sx:{
                                                      '& .MuiSvgIcon-root': {
                                                        color: darkMode ? '#ffffff' : '#000000', // Color of the dropdown icon in dark mode and light mode
                                                      },
                                                     color: darkMode ? '#ffffff !important' : '#000000', // Text color
                                                     "&:before": {
                                                        borderBottomColor: darkMode ? "#ffffff !important" : "#000000", // Underline color in unfocused state
                                                      },
                                                      "&:after": {
                                                        borderBottomColor: darkMode ? "#ffffff !important" : "#3f51b5", // Underline color in focused state
                                                      },
                                                  },
                                                  endAdornment: (
                                                    <>
                                                      {scrollLoading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                      ) : null}
                                                      {params.InputProps.endAdornment}
                                                    </>
                                                  ),
                                        }}
                                      />
                                    )}
                                    ListboxProps={{
                                              
                                              onScroll: (event) => {
                                                const bottom =
                                                  event.target.scrollHeight - event.target.scrollTop ===
                                                  event.target.clientHeight;
                                                if (bottom && hasMore) {
                                                  fetchItems("", offset + 20); // Load more items when the user scrolls to the bottom
                                                }
                                              },
                                    }}
                                    renderOption={(props, option) => (
                                              <MenuItem {...props} key={option.name} value={option.name}>
                                                {option.name}
                                              </MenuItem>
                                    )}
                                  />
                                  <MDBox mt={0.75}>
                                     <ErrorMessage name="ParentAccount">
                                      {(msg) => (
                                        <MDTypography
                                          component="div"
                                          variant="caption"
                                          color="error"
                                          fontWeight="regular"
                                        >
                                          {msg}
                                        </MDTypography>
                                      )}
                                     </ErrorMessage>
                                  </MDBox>
                                </FormControl>}
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={5} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={errorMessage}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </>
      }
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default AddNewCategoryDialog;
