import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

function FormField({ label, InvoiceMismatch = false, editable = false, name, ...rest }) {
  const [dottedStyle, setDottedStyle] = useState({});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    InvoiceMismatch === true ? editable ? setDottedStyle({ borderBottom: `2px solid red` }) : setDottedStyle({}) : editable ? setDottedStyle({ borderBottom: `dotted 0.8px` }) : setDottedStyle({});
  }, [editable]);

  return (
    <MDBox mb={1.5}>
      <Field {...rest} name={name} as={MDInput}
        sx={{
          "& .MuiInputBase-input": {
            padding: `6px 1px 1px`,
            height: '1.8876em',
            color: darkMode ? '#ffffff' :''
          },
          "& .MuiInputLabel-root.Mui-disabled:before": {
            lineHeight: "32px !important",
          },
          "& .MuiInputLabel-root": {
            lineHeight: "32px !important",
            color: darkMode ? '#ffffff' :''
          },
        }}
        style={darkMode ?  {borderBottom: '2px solid #ffffff'} :dottedStyle  }
        variant="standard"
        label={label}
        fullWidth
        autoComplete="off"
        InputProps={{
          disableUnderline: editable,
        }}
      />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

};

export default FormField;
