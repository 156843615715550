import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useMaterialUIController } from "context";
import AddCategoryItemDialog from '../addCategoryItemDialog';
import AddNewCategoryDialog from '../addnewcategory';
import NewInvoiceDialog from '../newinvoicedialog';
import MDButton from 'components/MDButton';
import Slide from "@material-ui/core/Slide";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

const DraggableNewInvoiceDialog = ({ open , businessId, invoiceId, disButton, onClose }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <React.Fragment>
      <Dialog
       fullWidth="true"
       maxWidth="sm"
       TransitionComponent={Transition}
       open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minHeight: "300px",
              maxWidth: "570px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle style={{ cursor: 'move' ,justifyContent: "center",display: "flex", color: darkMode ? "#ffffff" : "#7b809a"}} id="draggable-dialog-title">
         Upload Documents
        </DialogTitle>
        <DialogContent>
        <NewInvoiceDialog
                invoiceId={invoiceId}
                businessId={businessId}
                disButton={disButton}
              />
        </DialogContent>
        <DialogActions>
              {!disButton && (
                <MDButton
                  sx={darkMode && { backgroundColor: "transparent !important", color: "#ffffff" }}
                  disabled={disButton}
                  onClick={()=>onClose()}
                >
                  Close
                </MDButton>
              )}
            </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DraggableNewInvoiceDialog;
