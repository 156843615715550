import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import brandLogo from "assets/images/logos/paynthr-logo-colored.png";
import theme from "assets/theme";
import { setDarkMode, useMaterialUIController } from "context";
import { getAccountantUserType } from "helpers/getaccountantusertype";
import { getAdminUserType } from "helpers/getadminusertype";
import { getUserRole } from "helpers/getUserRole";
import Billing from "layouts/accountants/user/billing";
import EditUser from "layouts/accountants/user/edit-user";
import EditVendorDialog from "layouts/accountants/user/invoice/editvendordialog";
import EditAccountant from "layouts/admin/accountants/edit-accountant";
import AdminBilling from "layouts/admin/billing";
import AdminClientBilling from "layouts/admin/client/billing";
import EditAdminClient from "layouts/admin/client/edit-clients";
import AdminClientInfo from "layouts/admin/clientInfo.js/index.js";
import PageNotFound from "layouts/auth/404";
import AccountantLogout from "layouts/auth/accountantlogout";
import AdminGeneratePassAuth from "layouts/auth/admin-create-password";
import AdminLogout from "layouts/auth/admin-logout";
import AdminForgotPassAuth from "layouts/auth/admin-reset-password";
import ClientUserLogout from "layouts/auth/client-logout";
import GeneratePassAuth from "layouts/auth/create-password";
import Forbidden from "layouts/auth/forbidden";
import PolicyPage from "layouts/auth/privacy-policy-page";
import ForgotPassAuth from "layouts/auth/reset-password";
import SignInBasicAuth from "layouts/auth/sign-in";
import SignUpAuth from "layouts/auth/sign-up";
import TermsOfAgreement from "layouts/auth/terms-of-agreement.js";
import UserGeneratePassAuth from "layouts/auth/user-create-password";
import UserForgotPassAuth from "layouts/auth/user-reset-password";
import UserSignInAuth from "layouts/auth/user-signin";
import CloseQboWindow from "layouts/clients/components/QBO";
import VendorSettings from "layouts/clients/setting";
import ClientUserBilling from "layouts/clientuser/billing";
import VendorClientSettings from "layouts/clientuser/clients/setting";
import Inventory from "layouts/clientuser/inventory";
import EditRestaurant from "layouts/clientuser/restaurants/edit-restaurant";
import AddNewRestaurant from "layouts/clientuser/restaurants/new-restaurants";
import EditClientUser from "layouts/clientuser/user/edit-user";
import ClientUserInvoice from "layouts/clientuser/user/invoice/invoice";
import Sidenav from "layouts/components/Sidenav";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import ClientUserProfile from "layouts/pages/profile/profile-overview/clientprofile";
import UserProfile from "layouts/pages/profile/profile-overview/userprofile";
import { useEffect, useState } from "react";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import CheckDarkMode from "routes";
import CheckAdminDarkMode from "routes/adminRoutes";
import CheckClientDarkMode from "routes/clientRoutes";
import { GetClientUserProfile } from "services/clientservices";
import { useAPICall } from "utils/useapicall";
import { GetUserProfile } from "./services/userservices";

function App() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const adminThemeMode = localStorage.getItem("PaynthrAdminUiThemeMode");
  const themeMode = localStorage.getItem("PaynthrUiThemeMode");
  const clientThemeMode = localStorage.getItem("PaynthrClientUiThemeMode");
  const accountAccess = localStorage.getItem('accountAccess');
  const accountClientAccess = localStorage.getItem('accountClientAccess');
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [PathName, setPathName] = useState(pathname.split("/").slice(1)[0]);
  const [darkModeSet, setDarkModeSet] = useState(false);
  const AdminUserType = getAdminUserType();
  const AccountantUserType = getAccountantUserType();
  const userRoles = getUserRole();
  const [Captcha, setCaptcha] = useState(process.env.REACT_APP_RECAPTCHA_KEY);
  const { darkMode } = controller;
  const routes = CheckDarkMode();
  const adminRoutes = CheckAdminDarkMode();
  const clientRoutes = CheckClientDarkMode();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  // const isAdmin = location.pathname.includes("/admin/");
  const isAdmin = localStorage.getItem("AdminUserType");
  const isAccounatnt = location.pathname.includes("/accountant/");
  const isClient = location.pathname.includes("/client/");
  const [
    { response: UserprofileResponse },
    getUserProfileAPICall,
  ] = useAPICall(GetUserProfile, { onLoad: false });

  const [
    {response: ClientprofileResponse },
    getclientProfileAPICall,
  ] = useAPICall(GetClientUserProfile, { onLoad: false });

  useEffect(async () => {
    if (UserprofileResponse && UserprofileResponse.data.isSuccess === true) {
      setDarkMode(dispatch,UserprofileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem("PaynthrUiThemeMode", UserprofileResponse?.data?.data?.user?.themeColor);
      localStorage.setItem( "ShowBilling", UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false);
      if(UserprofileResponse?.data?.data?.user?.accountAccess == false){
          localStorage.setItem('accountAccess', false);
          navigate("/accountants/billing");
      }
    }
  }, [UserprofileResponse]);

  useEffect(() => {
    if (ClientprofileResponse && ClientprofileResponse.data.isSuccess === true) {
      setDarkMode(dispatch,ClientprofileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem( "PaynthrClientUiThemeMode", ClientprofileResponse?.data?.data?.user?.themeColor);
      if(ClientprofileResponse?.data?.data?.user?.accountAccess == false){
        localStorage.setItem("accountClientAccess",false);
        navigate("/client/billing");
    }
    }
  }, [ClientprofileResponse]);


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Function to be called when the tab gains focus
        myFunction();
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const myFunction = async() => {
   if (isAccounatnt) {
      await getUserProfileAPICall({ payload: [] });
    }else if(isClient) {
      await getclientProfileAPICall({ payload: [] });
    }
     };

  useEffect(() => {
    if (isAdmin == 'admin') {
      setDarkMode(dispatch, adminThemeMode == "dark" ? true : false);
      setDarkModeSet(true);
    } else if (isAccounatnt) {
      setDarkMode(dispatch, themeMode == "dark" ? true : false);
      setDarkModeSet(true);
    } else {
      setDarkMode(dispatch, clientThemeMode == "dark" ? true : false);
      setDarkModeSet(true);
    }
  }, [adminThemeMode, themeMode, clientThemeMode]);

  useEffect(() => {
    // Perform actions when the path changes

    // You can also conditionally trigger actions based on the path
    if (
      location.pathname === "/sign-in" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/user/reset-password" ||
      location.pathname === "/create-password" ||
      location.pathname === "/user/create-password"
    ) {
      ReactRecaptcha3.init(`${Captcha}`).then((status) => {
        console.log(status);
      });
    } else {
      ReactRecaptcha3.destroy();
    }

    // Cleanup function (optional)
    return () => {
      // Perform cleanup if needed
    };
  }, [location.pathname]);

  var setuserRole = isAdmin == "admin" ? AdminUserType : AccountantUserType;
  var mainRoute = [];
  var checkuser = "";

  if (setuserRole && setuserRole.user && setuserRole.user.userType == "admin") {
    mainRoute = adminRoutes;
    checkuser = "admin";
  } else {
    var userRole =
      setuserRole && setuserRole.user && setuserRole.user.userType
        ? setuserRole.user.userType
        : userRoles;
    mainRoute = userRole == "client" ? clientRoutes : userRole == "accountant" ? routes : [];
    checkuser = "user";
  }

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(dispatch, false);
    //   setOnMouseEnter(true);
    // }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    // if (onMouseEnter) {
    //   setMiniSidenav(dispatch, true);
    //   setOnMouseEnter(false);
    // }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    useEffect(() => {
      document.body.style.backgroundColor = darkMode ? '#344767' : '#ffffff';
    }, [darkMode]);

  return (
    darkModeSet && (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {(accountClientAccess !== 'false' && accountAccess !== 'false') && layout === "dashboard" && (
          <Sidenav
            color={sidenavColor}
            brand={brandLogo}
            brandName="Paynthr"
            routes={mainRoute}
            checkuser={checkuser}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        )}
        {layout === "vr"}
        {(accountClientAccess !== 'false' && accountAccess !== 'false')  ? 
        <Routes>
         <Route path="/admin/sign-in" element={<SignInBasicAuth />} />
         <Route path="/admin/logout" element={<AdminLogout />} />
         <Route path="/sign-in" element={<UserSignInAuth />} />
         <Route path="/sign-up" element={<SignUpAuth />} />
         <Route path="/reset-password" element={<ForgotPassAuth />} />
         <Route path="/admin/reset-password" element={<AdminForgotPassAuth />} />
         <Route path="/create-password" element={<GeneratePassAuth />} />
         <Route path="/admin/create-password" element={<AdminGeneratePassAuth />} />
         <Route path="/privacy-policy" element={<PolicyPage />} />
         <Route path="/terms-of-agreement" element={<TermsOfAgreement />} />
         <Route path="/user/reset-password" element={<UserForgotPassAuth />} />
         <Route path="/user/logout" element={<AccountantLogout />} />
         <Route path="/user/create-password" element={<UserGeneratePassAuth />} />
         <Route path="/admin/profile/profile-overview" element={<ProfileOverview />} />
         <Route path="/admin/client/:id/edit-client" element={<AdminClientInfo />} />
         <Route path="/admin/client/:id/billing" element={<AdminClientInfo />} />
         <Route path="/accountants/profile-overview" element={<UserProfile />} />
         <Route path="/accountants/billing" element={<Billing />} />
         <Route path="/admin/billing/:id" element={<AdminBilling />} />
         <Route path="/client/billing" element={<ClientUserBilling />} />
         <Route path="/admin/client/billing/:id" element={<AdminClientBilling />} />
         <Route path="/client/profile-overview" element={<ClientUserProfile />} />
         <Route path="/admin/accountants/:bussinessId/edit-accountant/" element={<EditAccountant />} />
         <Route path="/admin/client/:bussinessId/edit-client" element={<EditAdminClient />} />
         <Route path="/accountant/users/:bussinessId/edit-user" element={<EditUser />} />
         <Route path="/client/document/:id" element={<ClientUserInvoice />} />
         <Route path="/client/inventory/:id/list" element={<Inventory />} />
         <Route path="/clients/employees/:bussinessId/edit-user" element={<EditClientUser />} />
         <Route path="/client/client/:bussinessId/edit-client" element={<VendorClientSettings />} />
         <Route path="/client/client/:bussinessId/vendor" element={<VendorClientSettings />} />
         <Route path="/client/client/:bussinessId/customer" element={<VendorClientSettings />} />
         <Route path="/client/client/:bussinessId/classes" element={<VendorClientSettings />} />
         <Route path="/client/client/:bussinessId/category" element={<VendorClientSettings />} />
         <Route path="/client/client/:bussinessId/products" element={<VendorClientSettings />} />
         <Route path="/client/restaurant/new-restaurant" element={<AddNewRestaurant />} />
         <Route path="/client/restaurant/:restaurantId/edit-restaurant" element={<EditRestaurant />} />
         <Route path="/accountant/client/:bussinessId/edit-vendor" element={<EditVendorDialog />} />
         <Route path="/accountant/client/:bussinessId/edit-client" element={<VendorSettings />} />
         <Route path="/accountant/client/:bussinessId/vendor" element={<VendorSettings />} />
         <Route path="/accountant/client/:bussinessId/customer" element={<VendorSettings />} />
         <Route path="/accountant/client/:bussinessId/classes" element={<VendorSettings />} />
         <Route path="/accountant/client/:bussinessId/category" element={<VendorSettings />} />
         <Route path="/accountant/client/:bussinessId/products" element={<VendorSettings />} />
         <Route path="layouts/auth/404" element={<PageNotFound />} />
         <Route path="/client/logout" element={<ClientUserLogout />} />
         <Route path="/Page-not-found" element={<PageNotFound />} />
         <Route path="/forbidden/page-not-found" element={<Forbidden />} />
         <Route path="/qbo-cb" element={<CloseQboWindow />} />
         {getRoutes(mainRoute)}
         <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
       :
        <Routes>
         <Route path="/sign-in" element={<UserSignInAuth />} />
         <Route path="/user/logout" element={<AccountantLogout />} />
         <Route path="/accountants/billing" element={<Billing />} />
         <Route path="/client/billing" element={<ClientUserBilling />} />
         <Route path="/client/logout" element={<ClientUserLogout />} />
         {getRoutes(mainRoute)}
         <Route path="*" element={<Navigate to="/user/logout" />} />
        </Routes>
      }
      </ThemeProvider>
    )
  );
}

export default App;
