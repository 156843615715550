import Request from "./adminapicallservice";
const PageLimit = process.env.REACT_APP_PAGE_LIMIT;


export const AddNewAdminclient = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientDetailsData = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditClientInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAdminClientBilling = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params?.bussinessId}/invoices`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetErrorList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/errors?limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetStuckList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/stucks?limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetReportListList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/reported?limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetReportDetailsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/reported/${params.reportId}`;
        delete params.bussinessId;
        delete params.reportId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetInvoiceDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/reported/${params?.inovoiceId}/threads/${params?.threadId}`;
        // const apiUrl = `/admins/documents/reported/${params?.inovoiceId}`;
        delete params.inovoiceId;
        delete params?.threadId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const RetryDocumentUpload = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/${params?.documentId}/retry`;
        delete params.documentId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const RetryMultipuleDocumentUpload = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/retry`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const MesageToUploader = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/reported`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const CloseReportApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/reported/close/`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const DeleteErrorDocument = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/documents/delete`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}