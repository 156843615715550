import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, MenuItem, Pagination, PaginationItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAsyncDebounce, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, } from "react-table";
import { eventBus } from "utils/eventbus";
import DataTableBodyCell from "../invoice/components/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "../invoice/components/Tables/DataTable/DataTableHeadCell";
import Icon from '@mui/material/Icon';

// Light mode theme
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#000', // Checkbox color for light mode
          },
          '&.Mui-checked': {
            '& .MuiSvgIcon-root': {
              color: '#1e88e5', // Color when checked
            },
          },
          '&.Mui-disabled': {
            '& .MuiSvgIcon-root': {
              color: '#aaa', // Color for disabled state
            },
          },
          '&.Mui-disabled.Mui-checked': {
            '& .MuiSvgIcon-root': {
              color: '#777', // Color for disabled and checked state
            },
          },
        },
      },
    },
  },
});

// Dark mode theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: '#fff', // Checkbox color for dark mode
          },
          '&.Mui-checked': {
            '& .MuiSvgIcon-root': {
              color: '#1e88e5', // Color when checked
            },
          },
          '&.Mui-disabled': {
            '& .MuiSvgIcon-root': {
              color: '#555', // Color for disabled state
            },
          },
          '&.Mui-disabled.Mui-checked': {
            '& .MuiSvgIcon-root': {
              color: '#999', // Color for disabled and checked state
            },
          },
        },
      },
    },
  },
});

function DataTable({entriesPerPage,canSearch,showTotalEntries,table,pagination,isSorted,noEndBorder,response,selectTab,loading,availableStatuses,showCheckBoxs,showCheck,ClientHeigth,isClient, uploadPermison }) {
  const limit = localStorage.getItem("limit");
  const defaultValue = limit ? limit : 10;
  const [newpageIndex, setNewpageIndex] = React.useState(0);
  const [selectAllButton, setSelectAllButton] = useState(false);
  const [selectWidth, setSelectwidh] = useState(75);
  const [selectTotalShow, setSelectTotalShow] = useState(defaultValue);
  const [selectedDate, setSelectedDate] = useState(null);
  const entries = entriesPerPage.entries ? entriesPerPage.entries.map((el) => el.toString()) : ["10", "20", "50", "100"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let pageOptions1 = [];
  let pageOptionsFirst = [];

  useEffect(() => {
    const ThisOffsethandle = localStorage.getItem("offset");
    if (newpageIndex === 0) {
      setNewpageIndex(0);
      localStorage.setItem("offset", 0);
      localStorage.setItem("newpageIndex", 0);
    } else {
      const num = ThisOffsethandle.toString();
      const str = num.split("").reverse().join("");
      const result = str.replace(/^0+/, "");
      // localStorage.setItem("newpageIndex", Number(ThisOffsethandle == 0 ? 0 : result))
      setNewpageIndex(Number(ThisOffsethandle == 0 ? 0 : result));
    }
  }, []);

  eventBus.$on("showSelectTabs", (data) => showSelectTabs(data));
  function showSelectTabs(data) {
    setNewpageIndex(0);
  }
  const totalRecords = response;
  for (let i = 0; i < response; ) {
    i = i + Number(selectTotalShow);
    pageOptionsFirst.push(i);
  }
  pageOptionsFirst.map((prop, key) => {
    const num = prop.toString();
    if (num >= 100) {
      const str = num.substring(0, num.length - 1);
      pageOptions1.push(str - 1);
    } else {
      const str = num.split("").reverse().join("");
      const result = str.replace(/^0+/, "");
      pageOptions1.push(result - 1);
    }
  });

  const [inputValues, setInputValues] = useState({});
  const [typingTimeout, setTypingTimeout] = useState(null);
  const typingDelay = 1500;

  const handleInputChange = (headerName, value) => {
    eventBus.$emit("startSearchLoader", true);
    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout
    const timeoutId = setTimeout(() => {
      setInputValues((prevState) => ({
        ...prevState,
        [headerName]: value,
      }));
    }, typingDelay);

    setTypingTimeout(timeoutId);
  };

  useEffect(() => {
    eventBus.$emit("GetDocumentinputValues", inputValues);
  }, [inputValues]);

  eventBus.$on("MoveMenuResponseComplete", (data) => MoveMenuResponseComplete(data));
  function MoveMenuResponseComplete(data) {
    const finaldata = (Number(data) - 10).toString();
    const str = finaldata.split("").reverse().join("");
    const result = str.replace(/^0+/, "");
    setNewpageIndex(Number(result));
  }

  eventBus.$on("showInvoiceStatus", (data) => showInvoiceStatus(data));
  function showInvoiceStatus(data) {
    const found1 = data?.find((obj) => {
      const PendingCheck = obj.ocrStatus === "PENDING";
      const InProgressCheck = obj.ocrStatus === "INPROGRESS";
      const CompletCheck = obj.ocrStatus === "COMPLETED";
      return CompletCheck;
    });
    if (found1) {
      setSelectAllButton(false);
    } else {
      setSelectAllButton(true);
    }
  }

  eventBus.$on("DisableAllCheckBox", (data) => DisableAllCheckBox(data));
  function DisableAllCheckBox(data) {
    setSelectAllButton(data);  
  }

  const IndeterminateCheckbox = React.forwardRef(({ des, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
  
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
  
    return (
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <MDBox display="flex" alignItems="center">
          <Checkbox
            ref={resolvedRef}
            disabled={!des}
            {...rest}
            onClick={(event) => {
              if (event.target.disabled) {
                event.stopPropagation();
                event.preventDefault();
              }
            }}
          />
          <MDBox ml={1}>
            <MDTypography variant="caption" fontWeight="medium" color="text"></MDTypography>
          </MDBox>
        </MDBox>
      </ThemeProvider>
    );
  });
  
  const tableInstance = useTable({columns,data,selectTab,initialState: { pageIndex: 0 }},
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        selectTab === "" || showCheck == false
          ? { ...columns }
          : {
              id: "selection",
              width: selectWidth,
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <>
                  <IndeterminateCheckbox
                    disabled={selectAllButton}
                    {...getToggleAllPageRowsSelectedProps()}
                    sx={{ pl: 1 }}
                  />
                </>
              ),
              Cell: ({ row }) => {
                const isDisabled = !row.original.checkBoxActive; // Adjust according to your logic
                return (
                  <>
                    <IndeterminateCheckbox
                      des={row.original.checkBoxActive}
                      {...row.getToggleRowSelectedProps()}
                      disabled={isDisabled}
                      onClick={(event) => {
                        if (isDisabled) {
                          event.stopPropagation();
                          event.preventDefault();
                        }
                      }}
                    />
                  </>
                );
              },
            },
        ...columns,
      ]);
    }
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    onPageChange = eventBus.$emit("ChangePaginationPage", pageIndex),
  } = tableInstance;
  
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
  useEffect(() => {
    var tempId = [];
    var arr = [];
    // tempId = selectedFlatRows.map(
    //     d => d?.original?.status?.props?.children?.props?.children == "Completed" ? d.original.id : 123
    // )
    tempId = selectedFlatRows.map((d) => {
      const status = d?.original?.status?.props?.children?.props?.children;
      return d?.original?.checkBoxActive ? d.original.id : 123;
    });
    // setSelectedRows(tempId);
    var value = 123;
    arr = tempId.filter(function (item) {
      return item !== value;
    });
    eventBus.$emit("invoiceRowSelected", { selectedRowIds: arr });
  }, [selectedRowIds]);

  const handleChange = (e, p) => {
    setPages(p);
    const dataStr = (p - 1).toString() + 0;
    localStorage.setItem("offset", Number(dataStr));
    eventBus.$emit("onclickchangePage", Number(p - 1)) || setNewpageIndex(Number(p - 1));
  };

  const setEntriesPerPage = (value) => setPageSize(value);
  const [pages, setPages] = useState(1);
  const [overFlow, setOverFlow] = useState("auto");
  const ThisOffsetuse = localStorage.getItem("offset");
  const finalPage = ThisOffsetuse == 0 ? 1 : pages;
  const isFirstPage = finalPage === 1;

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  let entriesEnd;
  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions1.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const changePerPageEntries = (value) => {
    setNewpageIndex(0);
    setEntriesPerPage(parseInt(value, 10));
    setSelectTotalShow(value);
    const NewForOffset = Number(value) === 10 ? null : Number(value);
    localStorage.setItem("newOffsetForAll", NewForOffset);
    eventBus.$emit("onclickchangeTotalPerPage", Number(value));
  };

  useEffect(() => {
    let timeoutId;

    const handleChange = (event) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        const obj = { HeaderName: event.target.tagName, value: event.target.value };
        handleInputChange(obj.HeaderName, obj.value);
      }, 500); // Adjust the debounce delay time as needed (e.g., 500 milliseconds)
    };

    const dropdown = document.querySelector("select");
    if (dropdown) {
      dropdown.addEventListener("change", handleChange);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (dropdown) {
        dropdown.removeEventListener("change", handleChange);
      }
    };
  }, []);

  useEffect(() => {
    if (response == 0) {
      setOverFlow("clip");
    } else {
      setOverFlow("auto");
    }
  }, [response]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Try to find the input element using class name
      const inputElement = document.querySelector(".input-search-field-date");
      
      if (inputElement) {
        // Inject a style tag to change the placeholder color
        const style = document.createElement("style");
        style.innerHTML = `
          .input-search-field-date::placeholder {
            color: ${darkMode ? 'white' : '#666'} !important;
          }
        `;
        document.head.appendChild(style);
  
        // Clear the interval once the input is found and style is applied
        clearInterval(intervalId);
      }
    }, 1000); // Check every second
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Find the input element inside the DatePicker component using class name
      const inputElement = document.querySelector(".input-search-field");
      
      if (inputElement) {
        // Inject a style tag to change the placeholder color
        const style = document.createElement("style");
        style.innerHTML = `
          .input-search-field::placeholder {
            color: ${darkMode ? 'white' : '#666'} !important; /* Change placeholder color based on mode */
          }
        `;
        document.head.appendChild(style);
  
        // Clear the interval once the input is found and style is applied
        clearInterval(intervalId);
      }
    }, 1000); // Check every second
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Re-run effect when darkMode changes
  

  function toCamelCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  function clearDateInput() {
    handleInputChange('uplaoddate', '');
    setSelectedDate(null); 
  }
  return (
    <TableContainer
    sx={{
      boxShadow: "none",
      backgroundColor: darkMode ? "#202940" : "#ffffff",
      height: '65vh',
      overflow: 'hidden', // Prevent scroll in the entire container
    }}
  >
    <Table {...getTableProps()} sx={{ minWidth: 650 }}>
      {/* Sticky header */}
      <MDBox component="thead">
          {headerGroups.map((headerGroup, index) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              style={{
                borderBottom: index === 0 ? "2px solid #f0f2f5" : "1px solid #dddddd", 
                position: "sticky",
                top: 0,
                backgroundColor: darkMode ? "#202940" : "#ffffff",
                zIndex: 100,
              }}
            >
              {headerGroup.headers.map((column, columnIndex) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={typeof(column.Header) == 'function' ? 50 : 350}
                  // width={isClient == true ? typeof(column.Header) == 'function'? 20 : column.Header == "Status" ? 100 : 120 : column.width ? column.width : 120}
                  align={showCheckBoxs === false ? "left" : "center"}
                  ml={0}
                  selectHeight={selectTab}
                  ClientHeigth={ClientHeigth}
                  isClient={isClient}
                  column={column.Header}
                  sorted={setSortedValue(column)}
                >
                  {columnIndex === 0 || columnIndex == headerGroup.headers.length - 1 ? (
                    <div style={{ display: "grid" }}>
                      {column.render("Header")}
                      {columnIndex == headerGroup.headers.length - 1 ? (
                        <div style={{ marginBottom: "0px", marginTop: "0px" }} />
                      ) : response == 0 || selectTab.length == 0 || isClient ? (
                        <div style={{marginBottom: "4px", marginTop: "0px", marginLeft: "-30px" }} />
                      ) : (
                        <div style={{marginBottom: "4px", marginTop: "0px", marginLeft: "-30px" }} />
                      )}
                    </div>
                  ) : (
                    <div
                      style={{ display: "inline-grid", marginBottom: response == 0 ? "30px" : "20px" }}
                    >
                      {/* {column.render("Header")} */}
                      {columnIndex === 1 ? (
                        <div  />
                      ) : column.Header === "Upload Date" ? (
                        response == 0 ? (
                          <div style={{ marginTop: "5px" }} />
                        ) : (
                          <div style={{ marginTop: "5px" }} />
                        )
                      ) : column.Header === "Invoice" ? (
                        <div style={{ marginTop: "5px" }} />
                      ) : (
                        <div style={{ marginTop: "5px" }} />
                      )}
                      {column.Header == "Status" ? (
                        (selectTab == "Classify" || selectTab == "") && availableStatuses ? (
                          <div className="custom-select" style={{ textAlignLast: "center" }}>
                            <select className="input-search-field" id="ddlViewBy" style={{ textAlignLast: "center" }}>
                              <option value="">All Status</option>
                              {availableStatuses.map((status, index) => (
                                <option key={index} value={status}>
                                  {toCamelCase(status)}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <input
                            type="text"
                            placeholder=""
                            className="input-search-field-hide"
                            disabled
                            style={isClient ? {
                              backgroundColor: "transparent",
                              marginBottom: '12px',
                              color: "white",
                              "::placeholder": {
                                color: "transparent", // Placeholder text color
                              },
                            }:{
                              backgroundColor: "transparent",
                              color: "white",
                              "::placeholder": {
                                color: "transparent", // Placeholder text color
                              },
                            }}
                            // value={column.filterValue || ""} // Set input value
                            onChange={(e) => {
                              handleInputChange(column.id, e.target.value);
                            }}
                          />
                        )
                      ) : column.Header === "Upload Date" ? (
                        <div style={{display:'flex' ,marginTop:"0px" }} >
                           <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                              setSelectedDate(date);
                              const formattedDate = date
                              ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                                  .toISOString()
                                  .split('T')[0]
                              : '';                         
                            handleInputChange(column.id, formattedDate);
                            }}
                            dateFormat="dd-MM-yyyy" // Set the desired date format
                            customInput={
                              <div style={{ position: "relative", display: "flex", alignItems: "center", width: "100%" }}>
                                <input
                                  type="text"
                                  className="input-search-field"
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    backgroundColor : darkMode ? "#3b485c" : "",
                                    color: darkMode ? '#ffffff' : "",
                                    padding: "5px 40px 5px 10px", // Padding to adjust for the icon
                                    // border: "1px solid #ccc",
                                    cursor:'pointer',
                                    borderRadius: "4px",
                                    boxSizing: "border-box", // Ensures padding and border are included in the width
                                  }}
                                  value={selectedDate ? new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000)
                                  .toISOString()
                                  .split('T')[0] : ''} 
                                  placeholder={column.Header} // Add placeholder directly
                                  readOnly // Prevent editing directly
                                />
                                <CalendarTodayIcon
                                  style={{
                                    color: darkMode ? "white" :"",
                                    position: "absolute",
                                    right: "10px", // Position the icon inside the input field
                                    top: "50%", // Center the icon vertically
                                    transform: "translateY(-50%)", // Adjust for vertical centering
                                    pointerEvents: "none", // Make the icon non-clickable
                                    cursor: 'pointer' // Make the icon
                                  }}
                                />
                              </div>
                            }
                          />
                        {inputValues.uplaoddate && 
                          <Tooltip title="Clear">
                          <span id="clearIcon" onClick={clearDateInput} style={{ cursor: 'pointer', marginLeft: '8px' ,marginTop:'8px' }}>
                          🇽
                          </span>
                          </Tooltip>}
                          </div>
                      ) : columnIndex !== 0 && columnIndex !== headerGroup.headers.length - 1 ? ( // Check if not the first or last column
                        <input
                          type="text"
                          placeholder={`${column.Header}`} // Set placeholder text
                          className="input-search-field-date"
                          style={isClient ? {
                            backgroundColor : darkMode ? "#3b485c" : "",
                            color : darkMode ? "#ffffff" : "",
                            textAlign: "center", // Center the text
                            marginBottom: '12px',
                          }:{
                            backgroundColor : darkMode ? "#3b485c" : "",
                            color : darkMode ? "#ffffff" : "",
                            textAlign: "center", // Center the text
                          }}
                          // value={column.filterValue || ""} // Set input value
                          onChange={(e) => {
                            handleInputChange(column.id, e.target.value);
                          }}
                        />
                      ) : (
                        <input
                          type="text"
                          className="input-search-field-hide"
                          disabled
                          // value={column.filterValue || ""} // Set input value
                          onChange={(e) => {
                            handleInputChange(column.id, e.target.value);
                          }}
                        />
                      )}
                    </div>
                  )}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
    </Table>
  
    {/* Scrollable table body */}
    <Box sx={{ height: 'calc(65vh - 100px)', overflowY: 'auto' }}>
      <Table {...getTableProps()}>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    showCheckBoxs={showCheckBoxs}
                    ClientHeigth={ClientHeigth}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align="-webkit-center"
                    ml={-60}
                    isClient ={isClient}
                    currentHeader={cell?.column?.Header}
                    selectHeight={selectTab}
                    width={typeof(cell.column.Header) == 'function' ? 50 : 350}
                    // display={cell.column.Header === 'Vendor' ? "block" : 'inline-block'}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>  
    {response &&
      <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: !showTotalEntries && pageOptions1.length === 1 ? 0 : 4,
        right: 25,
        position: "fixed",
        bottom: 20,
        zIndex: 1000,
        bgcolor: darkMode ? "#202940" : "background.paper",
        width: "91.2%",
        borderTop:  darkMode  ? "1px solid #1a2035" : "1px solid #ddd",
        height: "48px",
        "@media (max-width: 1440px)": {
          p: 3,
          bottom: 0,
          width: "87.5%",
        },
      }}
    >
        {response > 1 && (
        <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={1.2}>
        <Select
          value={pageSize.toString()} // Current value
          onChange={(event) => {
            changePerPageEntries(event.target.value); // Handle selection change
          }}
          size="small"
          sx={{
            width: "3.5rem", // Input width
            height: "2.1rem", // Input height
            // backgroundColor: darkMode ? '#344767' : '#ffffff', // Dark mode background
            color: darkMode ? '#ffffff' : '#000000', // Set text color to white in dark mode
            textAlign: 'center', // Center the selected value
            borderRadius: "8px",
            '&:hover': {
              backgroundColor: darkMode ? '#1a73e8' : '#e0f2ff', // Blue hover for dark mode, lighter for light mode
            },
            '& .MuiSvgIcon-root': {
              color: darkMode ? '#ffffff !important' : '#000000 !important', // Custom arrow icon color for dark mode
            },
            '& .MuiSelect-select': {
              color: darkMode ? '#ffffff !important' : '#000000'
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                width: "3.5rem", // Match the width of the input
                // backgroundColor: darkMode ? '#344767' : 'white', // Dark mode dropdown background
                // color: darkMode ? '#ffffff' : '#000000', // Set text color to white in dark mode
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: '#1a73e8', // Blue hover for menu items in dark mode
                },
              },
            },
            anchorOrigin: {
              vertical: 'top', // Anchor the dropdown at the top of the select
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'bottom', // Transform the dropdown to appear from the bottom
              horizontal: 'center',
            },
          }}
          disableUnderline
        >
          {entries.map((entry) => (
            <MenuItem 
              key={entry} 
              value={entry}  
              sx={{ 
                textAlign: 'center', 
                marginLeft:'-8px',
                // backgroundColor: darkMode ? '#344767' : '#ffffff', // Dark mode for menu items
                // color: darkMode ? '#ffffff' : '#000000', // Set text color to white in dark mode
                '&:hover': {
                  backgroundColor: '#1a73e8', // Blue hover color
                }
              }}
            >
              {entry}
            </MenuItem>
          ))}
        </Select>
        <MDTypography variant="caption" color={darkMode ? "#ffffff" : "secondary"}>
        </MDTypography>
        </MDBox>
      )}
    <MDBox display="flex" alignItems="center" justifyContent="center" mt={2} mr={1.2}  mb={4}>
      {response >= 1 && (
          <MDBox sx={{ mr: 2.2, mb: -1.5 }}>
            <MDTypography
              variant="button"
              color={darkMode ? "light" : "secondary"}
              fontWeight="regular"
            >
              Showing results {" "}
                  {newpageIndex == 0 ? "1" : finalPage * Number(selectTotalShow) - Number(selectTotalShow)}{" "} -{" "}
                  {response < finalPage * Number(selectTotalShow) ? response : finalPage * Number(selectTotalShow)}{" "}
                  of {response} 
            </MDTypography>
          </MDBox>
        )}
      {pageOptions1.length > 1 && (
        <MDPagination
          variant={pagination.variant ? pagination.variant : "gradient"}
          color={pagination.color ? pagination.color : "info"}
        >
          {
           <Pagination
           page={finalPage}
           color="primary"
           sx={{ justifyContent: "center", marginTop: 2, mr: 1.2 }}
           onChange={handleChange}
           count={pageOptionsFirst.length}
           renderItem={(item) => (
             <>
               {item.type === 'previous' && isFirstPage ? null : (
                 <PaginationItem
                   {...item}
                   sx={{
                    color: item.type === 'page' ? darkMode ? '#ffffff' : '#237dea' : darkMode ? '#ffffff' : '#237dea', // White for previous/next in dark mode, blue otherwise
                    border: item.type === 'page' ? "1px solid #d9d9d9" : 'none', // Add border only to page numbers
                    margin: "0 5px", // Adds spacing between items
                    borderRadius: "4px", // Optional for rounded borders
                      "&.Mui-selected": {
                        backgroundColor: "#237dea",
                        color: "white",
                      },
                      "&&.Mui-selected:hover": {
                        backgroundColor: "#237dea", // Keep the background color unchanged for selected pages on hover
                        color: "#ffffff", // Keep the text color unchanged for selected pages on hover
                      },
                      "&.MuiPaginationItem-root:hover": {
                        backgroundColor: item.type === 'page' ? "#e0f2ff" : 'transparent', // Light blue hover for unselected pages
                        color: item.type === 'page' ? "#237dea" : darkMode ? "#1a73e8" : '#237dea', // Hover text color blue for dark mode
                        border: item.type === 'page' ? "1px solid #237dea" : "none", // Optional border color change on hover
                      }
                   }}
                   components={{
                    next: () => (
                        <>
                          {item.type === 'next' && finalPage !== pageOptionsFirst.length && 
                          <Tooltip title="Next">
                            <Icon>chevron_right</Icon>
                          </Tooltip>}
                        </>
                      ),
                    previous: () => 
                        <>
                          {item.type === 'previous' && !isFirstPage && 
                            <Tooltip title="Previous">
                              <Icon>chevron_left</Icon>
                            </Tooltip>}
                        </>,
                   }}
                 />
               )}
             </>
           )}
         />
          }
        </MDPagination>
      )}
    </MDBox>
  </Box>
    }
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: false,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
