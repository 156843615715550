import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DashboardLayout from "layouts/admin/components/LayoutContainers/DashboardLayout/index.js";
import DashboardNavbar from "layouts/admin/components/Navbars/DashboardNavbar/index.js";
import EditAdminClients from "../client/edit-clients";
import MDTypography from "components/MDTypography";
// import image from './output-onlinegiftools.gif'
import { GetuserClientDetails } from "services/userservices";
import { useAPICall } from "utils/useapicall";
import { eventBus } from "utils/eventbus";
import Hoc from "hoc";
import Billing from "./clientBilling.js";
import { useMaterialUIController } from "context";
import { makeStyles } from "@material-ui/core/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTab-root": {
      backgroundColor: "#202940 !important",
      color: "#ffffff !important",
      marginLeft: "3px",
      marginRight: "2px",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#202940 !important",
      backgroundColor: "#ffffff !important",
    },
    "& .css-1t3gkzj-MuiButtonBase-root-MuiTab-root.Mui-disabled": {
      backgroundColor: "transparent !important",
      color: "#7b809a !important",
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function AdminClientInfo() {
  const [value, setValue] = useState(localStorage.getItem("Admin-val"));
  const urlParam = window.location.pathname.split("/");
  const [businessId, setBusinessId] = useState("");
  const [syncPercent, setSyncPercent] = useState(0);
  const [documentSource, setDocumentSource] = useState("");
  const [showSyncing, setShowSyncing] = useState(false);
  const [reloadApi, setReloadApi] = useState(0);
  const [loaderClass, setLoaderClass] = useState({});
  const [showClasses, SetShowClasses] = useState(true);
  const [tabName, setTabName] = useState(urlParam.slice(-1));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const classes = useStyles();
  const subNav = ["Account", "Billing"];
  const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(
    GetuserClientDetails,
    { onLoad: false }
  );
  useEffect(async () => {
    handleTab();
    await getClientDetailsAPICall({
      payload: [{ bussinessId: `${businessId}` }],
    });
  }, []);

  eventBus.$on("OnSetBlurEdit", (data) =>
    data === true ? setLoaderClass({ opacity: "0.4", borderRadius: "13px" }) : setLoaderClass({})
  );

  useEffect(() => {
    if (value === 1) {
      setLoaderClass({ backgroundColor: "#f0f2f5 !important" });
    } else {
      setLoaderClass({});
    }
  }, [value]);

  useEffect(() => {
    if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
      SetShowClasses(
        clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs
          ?.ClassTrackingPerTxnLine
      );
      if (clientDetailsDataResponse?.data?.data?.client?.syncPercent !== 100) {
        setReloadApi(reloadApi + 1);
      }
      setSyncPercent(clientDetailsDataResponse?.data?.data?.client?.syncPercent);
      setDocumentSource(clientDetailsDataResponse?.data?.data?.client?.documentSource);
      setShowSyncing(true);
    }
  }, [clientDetailsDataResponse]);

  //stop reload api
  // useEffect(() => {
  //     const fetchData = async () => {
  //         await getClientDetailsAPICall({
  //             payload: [{ "bussinessId": `${businessId}` }]
  //         });
  //     };

  //     const timer = setTimeout(() => {
  //         fetchData();
  //     }, 30000);

  //     return () => clearTimeout(timer);
  // }, [reloadApi]);

  const handleTab = () => {
    if (tabName[0] === "edit-client") {
      setValue(0);
      localStorage.setItem("Admin-val", value);
    }
    if (tabName[0] === "billing") {
      setValue(1);
      localStorage.setItem("Admin-val", value);
    }
  };
  const pathnames = window.location.pathname;
  const pathname = pathnames.substr(0, pathnames.lastIndexOf("/"));
  const handleChange = async (event, newValue) => {
    if (tabName[0] === "edit-client") {
      setValue(0);
      localStorage.setItem("Admin-val", newValue);
    }
    if (tabName[0] === "billing") {
      setLoaderClass({ backgroundColor: "#f0f2f5 !important" });
      setValue(1);
      localStorage.setItem("Admin-val", newValue);
    }
    setValue(newValue);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox my={2}>
          <Card sx={{ ...loaderClass, backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={
                    darkMode
                      ? {
                          backgroundColor: "#1a2035 !important",
                          "& .MuiButtonBase-root": {
                            flex: "none",
                            //   width:'88px',
                          },
                        }
                      : {
                          "& .MuiButtonBase-root": {
                            flex: "none",
                            //   width:'88px',
                          },
                        }
                  }
                  className={darkMode ? classes.tabs : ""}
                >
                  {subNav.map((item, i) => {
                    let uri = i ? pathname + "/" + item.toLowerCase() : pathname + "/edit-client";
                    return (
                      <Tab
                        key={i}
                        className={darkMode ? classes.tabs : ""}
                        sx={{ paddingLeft: 5, paddingRight: 5 }}
                        // icon={i > 0 && <Divider orientation="vertical" variant="middle" flexItem />}
                        label={`${item.replaceAll("-", " ")}`}
                        component={Link}
                        to={uri}
                        {...a11yProps(i)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <EditAdminClients businessId={businessId} syncPercent={syncPercent} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Billing
                  businessId={businessId}
                  syncPercent={syncPercent}
                  documentSource={documentSource}
                  IsSynced={true}
                />
              </TabPanel>
            </Box>
          </Card>
          <MDBox></MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default Hoc(AdminClientInfo);
