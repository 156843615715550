import Toolbar from "@material-ui/core/Toolbar";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import logo from "assets/images/logos/paynthr-logo-colored.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import DataTable2 from "components/NewTable2/NewTable/table";
import { useMaterialUIController } from "context";
import { SITE_URL } from "helpers/config";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import image from "layouts/accountants/user/invoice/output-onlinegiftools.gif";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GetUserProfile } from "services/userservices";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { canAccess } from "../../helpers";
import {AccountantClientDelete,GetuserClientList,GetusersList} from "../../services/userservices";
import { useAPICall } from "../../utils/useapicall";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import AddOwnerDetailsDialog from "./add-owner";
import ConnectToQuickBooksOnlineButton from "./components/QBOButton";
import Vendor from "./setting/vendor";

function Clients() {
  const [reCallApi, setReCallApi] = useState(1);
  const qboData1 = localStorage.getItem("queryParameters");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    const timeOutApi = setTimeout(() => {
      if (qboData1 !== null && qboData1 !== "null") {
        var nameArr = qboData1.split(",");
        window.location.replace(
          `${SITE_URL}/accountant/client/list?clientId=${nameArr[0]}&status=${nameArr[1]}&message=${nameArr[2]}`
        );
        localStorage.setItem("queryParameters", "null");
        setReCallApi(reCallApi + 1);
      } else {
        setReCallApi(reCallApi + 1);
      }
    }, 1000);
    return () => clearTimeout(timeOutApi);
  }, [reCallApi]);

  const cookies = new Cookies();
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 1);
  const navigate = useNavigate();
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete", addowner: "Add Owner" };
  const [adminList, setAdminList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [tableEmpty, setTableEmpty] = useState("");
  const [search, setSearch] = useState("");
  const [accountantId, setAccountantId] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [ownerName, setOwnerName] = useState("");
  const [openVendorList, setOpenVendorList] = useState(false);
  const [documentSource, setDocumentSource] = useState("");
  const [invoiceId, setInvoiceId] = useState(0);
  const [onTrialCheck , setOnTrialCheck] = useState(false);
  const [showAddNewCradModel , setShowAddNewCradModel] = useState(false);
  const [cardAdded , setCardAdded] = useState();
  const [invoiceUserId, setInvoiceUserId] = useState(0);
  const [openEmploye, setOpenEmploye] = useState(false);
  const [qboState, setQboState] = useState("");
  const [clientAssigned, setClientAssigned] = useState([]);
  const [qboClientId, setQboClientId] = useState();
  const [totalEmployes, setTotalEmployes] = useState();
  const [showQboErrorSnackbar, setShowQboErrorSnackbar] = useState(false);
  const [showQboSuccessSnackbar, setShowQboSuccessSnackbar] = useState(false);
  const [openAddOwner, setOpenAddOwner] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddSnackbar, setShowAddSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [openNewclientPopup, setOpenNewclientPopup] = useState(false);
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [qboErrorMessage, setQboErrorMessage] = useState("");
  const [qboSuccessMessage, setQboSuccessMessage] = useState("");
  const [openNoClient, setOpenNoClient] = useState(false);
  const [openNoClients, setOpenNoClients] = useState(false);
  const [employessList, setemployessList] = useState([]);
  const [reloadApiClientApi, setReloadApiClientApi] = useState(0);
  const [tableHeight, setTableHeight] = useState({ marginTop: "8px" });
  const [loaderClass, setLoaderClass] = useState({});
  const [hideNoData ,setHideNoData ] = useState(true);
  const [show, setShow] = useState(false);
  const [dltButton, setDltButton] = useState(false);
  const [showBilling, setshowbilling] = useState(false);
  const [profileInfo, setProfileInfo] = useState(cookies.get("user_profile_info"));
  const [PerPagelimit, setPerPagelimit] = useState(localStorage.getItem("PerPagelimit"));
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetuserClientList, {
    onLoad: false,
  });
  const [
    { isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse },
    removeClientAPICall,
  ] = useAPICall(AccountantClientDelete, { onLoad: false });
  const [
    { isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse },
    getUserListAPICall,
  ] = useAPICall(GetusersList, { onLoad: false });
  const [
    { isLoading: isGetLoading, isError: isGetError, response: UserprofileResponse },
    getUserProfileAPICall,
  ] = useAPICall(GetUserProfile, { onLoad: false });

  var resource = "";
  var actions = "";

  const { pathname } = useLocation();

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const tt =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", tt);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: tt, limit: PerPagelimit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: 0, limit: data }],
    });
  }


  const ShowAddNewCradModel = () => {
    setShowAddNewCradModel(true)
  };


  eventBus.$on("onclickchangePagesDilaog", (data) => onclickchangePagesDilaog(data));
  function onclickchangePagesDilaog(data) {
    setLoadingCircul(true);
    const dataStr = data.toString() + 0;
    localStorage.setItem("offset1", Number(dataStr));
    getUserListAPICall({
      payload: [{ clientId: `${invoiceUserId}`, offset: Number(dataStr) }],
    });
  }

  useEffect(async () => {
    localStorage.removeItem("clientSelect");
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setHideNoData(false);
      setLoading(true);
      await setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: 10 }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  // stop reload api
  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
    const fetchData = async () => {
      await setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 30000);
    return () => clearTimeout(timer);
  }, [reloadApiClientApi]);

  useEffect(() => {
    getUserProfileAPICall({ payload: [] });
  }, []);

  useEffect(async () => {
    if (isGetLoading) {
      return () => null;
    }

    if (isGetError) {
      return () => null;
    }

    if (UserprofileResponse && UserprofileResponse.data.isSuccess === true) {
      setShow(UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false);
      setOnTrialCheck(UserprofileResponse?.data?.data?.user?.subscriptions[0]?.ontrial);
      setCardAdded(UserprofileResponse?.data?.data?.user?.card);
      localStorage.setItem(
        "ShowBilling",
        UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false
      );
    }
  }, [UserprofileResponse]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    // loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' ,backgroundColor: darkMode ? '#202940' : '#ffffff'   }) : setLoaderClass({backgroundColor: darkMode ? '#202940' : '#ffffff'  })
  }, [loading]);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      setTotalEmployes(userDetailsDataResponse?.data?.data?.totalRecords);
      const data = userDetailsDataResponse?.data?.data?.records.map((prop, key) => {
        return {
          firstName: prop?.fname,
          lastName: prop?.lname,
          email: prop?.email,
        };
      });
      setemployessList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [userDetailsDataResponse]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
  };

  const Modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  const ModalDrakstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#202940",
    border: "0",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
    outline: "none",
  };

  useEffect(() => {
    setProfileInfo(cookies.get("user_profile_info"));
  }, []);

  eventBus.$on("closeQboWindowApiCall", (data) => closeQboWindowApi(data));
  function closeQboWindowApi(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setAPICall({
      payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  }

  eventBus.$on("errorInAddOwner", (data) => errorInAddOwner(data));
  function errorInAddOwner(data) {
    setShowErrorSnackbar(true);
    setAddErrorMessage(data);
  }

  eventBus.$on("successInAddOwner", (data) => successInAddOwner(data));
  function successInAddOwner(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setShowAddSnackbar(true);
    setTimeout(() => {
      setShowAddSnackbar(false);
    }, 10000);
    setOpenAddOwner(false);
    setLoading(true);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
    });
  }

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const status = queryParameters.get("status");
    const message = queryParameters.get("message");
    const clientId = queryParameters.get("clientId");
    setQboErrorMessage(message);
    setQboSuccessMessage(message);
    setQboState(status);
    setQboClientId(clientId);
    if (status === "Failed") {
      const param = searchParams.get("status");
      if (param) {
        searchParams.delete("status");
        searchParams.delete("message");
        searchParams.delete("clientId");
        setSearchParams(searchParams);
      }
      setShowQboErrorSnackbar(true);
      // history.pushState(null, ``, `${SITE_URL}/accountant/client/list`);
      setTimeout(() => {
        setShowQboErrorSnackbar(false);
      }, 10000);
    }
  }, []);

  useEffect(() => {
    if (isRemoveLoading) {
      return () => null;
    }

    if (isRemoveError) {
      setLoading(false);
      setDltButton(false);
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      return () => null;
    }

    if (removeResponse && removeResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setLoadingCircul(false);
      setDltButton(false);
      setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
      // navigate('/accountants/list');
    }
  }, [removeResponse]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };

  const openAddNewButton = () => {
    setOpenNewclientPopup(true);
    // setLoading(true);
    // setTimeout(() => {
    //     setLoading(false);
    // }, 1000)
  };

  const successDelete = async () => {
    setDltButton(true);
    setLoading(true);
    await removeClientAPICall({
      payload: [
        {
          bussinessId: `${accountantId}`,
        },
      ],
    });
    setAlert(false);
  };

  const hideAlert = () => {
    setAlert(false);
  };

  const AssignClient = () => {
    eventBus.$emit("clientAssignedDetail", clientAssigned);
  };

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    
  }, [search]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoading(false);
      setLoadingCircul(false);
      setHideNoData(true);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      const ThisOffsetuseD = localStorage.getItem("offset1");
      // if (response?.data?.data?.records[0]?.syncPercent !== 100) {
      //     setReloadApiClientApi(reloadApiClientApi + 1);
      // }
      search.length === 0 && response?.data?.data?.totalRecords === 0
        ? setOpenNoClients(true)
        : setOpenNoClients(false);
      setTableEmpty(response?.data?.data?.totalRecords);
      const totalHeight = `${18 - response?.data?.data?.totalRecords * 2}%`;
      setTableHeight(
        response?.data?.data?.totalRecords > 4
          ? { marginTop: "8px" }
          : { marginTop: "8px", marginBottom: totalHeight }
      );
      const data = response.data.data.records.map((prop, key) => {
        if (prop?.syncPercent !== 100) {
          setReloadApiClientApi(reloadApiClientApi + 1);
        }
        setDocumentSource(prop.documentSource);
        setClientAssigned(prop?.ownerAssigned);
        qboClientId === prop._id ? setOwnerName(prop.name) : "";
        return {
          snum: key + 1,
          client:
            prop?.syncPercent !== 100 ? (
              <MDBox>
                <Link to={`/accountant/document/${prop._id}`}>
                  <MDBox
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "1.20rem",
                    }}
                  >
                    <div>
                      <img
                        width={13}
                        height={13}
                        style={{ marginTop: "50%", marginBottom: "-3px", marginRight: "5px" }}
                        src={image}
                      />
                    </div>
                    <MDTypography
                      variant="caption"
                      fontWeight="regular"
                      fontSize="0.875rem"
                      color="info"
                      sx={{"&:hover": {
                            color: "#007bff", // Change to blue on hover
                          }}}
                      onClick={() => setClientAssigned(prop?.ownerAssigned)}
                    >
                      {prop.name}
                    </MDTypography>
                    <MDTypography
                      sx={{ ml: 1, mt: 0.8, mb: -0.3 }}
                      fontSize="0.80rem"
                      color={"warning"}
                    >
                      ({prop?.syncPercent}%)
                    </MDTypography>
                  </MDBox>
                </Link>
              </MDBox>
            ) : (
              <MDBox>
                <Link to={`/accountant/document/${prop._id}`}>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="info"
                    sx={{"&:hover": {
                      color: "#007bff", // Change to blue on hover
                    }}}
                    onClick={() => setClientAssigned(prop?.ownerAssigned)}
                  >
                    {prop.name}
                  </MDTypography>
                </Link>
              </MDBox>
            ),
          doctoreview: prop.toReview == "-" ? '-' : 
          <MDBox sx={{ cursor: "pointer" ,'&:hover': {
                color: '#1a73e8', // Change to blue on hover
              }}}>
          <MDTypography
            variant="caption"
            fontWeight="regular"
            fontSize="0.875rem"
            sx={{
              color: darkMode ? '#ffffff' : '#1a73e8',
              '&:hover': {
                color: '#1a73e8', // Change to blue on hover
              },
            }}
            onClick={async (value) => {
              setLoading(true);
              localStorage.setItem('clientSelect',prop._id);
              navigate(`/accountant/document/${prop._id}?k=&t=${encodeURIComponent('ToReview')}`)
              localStorage.setItem("Tabs", 'ToReview');
            }}
          >
            {prop.toReview}
          </MDTypography>
          </MDBox>,
          unmappedvendors: 
            prop.unmappedVendor !== "-" ? (
              <MDBox sx={{ cursor: "pointer" ,'&:hover': { color: '#1a73e8' }}}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              fontSize="0.875rem"
              sx={{
                color: darkMode ? '#ffffff' : '#1a73e8',
                '&:hover': {
                  color: '#1a73e8', // Change to blue on hover
                },
              }}
              onClick={async (value) => {
                setLoading(true);
                localStorage.setItem('clientSelect',prop._id);
                navigate(`/accountant/document/${prop._id}?k=&t=${encodeURIComponent('Classify')}`)
                localStorage.setItem("Tabs", 'Classify');
              }}
            >
              {prop?.unmappedVendor}
            </MDTypography>
          </MDBox>
              // <MDBox sx={{ cursor: "pointer" }}>
              //   <MDTypography
              //     variant="caption"
              //     fontWeight="regular"
              //     fontSize="0.875rem"
              //     color="info"
              //     onClick={async (value) => {
              //       setLoading(true);
              //       setInvoiceId(prop._id);
              //       setOpenVendorList(true);
              //     }}
              //   >
              //     {prop.unmappedVendor}
              //   </MDTypography>
              // </MDBox>
            ) : '-' ,
          clientapproval: prop?.askClient == "-" || !prop?.askClient  ? '-'  : 
          <MDBox sx={{ cursor: "pointer" ,'&:hover': { color: '#1a73e8' }}}>
          <MDTypography
            variant="caption"
            fontWeight="regular"
            fontSize="0.875rem"
            sx={{
              color: darkMode ? '#ffffff' : '#1a73e8',
              '&:hover': {
                color: '#1a73e8', // Change to blue on hover
              },
            }}
            onClick={async (value) => {
              setLoading(true);
              localStorage.setItem('clientSelect',prop._id);
              navigate(`/accountant/document/${prop._id}?k=&t=${encodeURIComponent('AskClient')}`)
              localStorage.setItem("Tabs", 'AskClient');
            }}
          >
            {prop?.askClient}
          </MDTypography>
        </MDBox>,
          yourapproval: prop?.approved  == "-"  ? '-' : 
          <MDBox sx={{ cursor: "pointer" ,'&:hover': { color: '#1a73e8' }}}>
          <MDTypography
            variant="caption"
            fontWeight="regular"
            fontSize="0.875rem"
            sx={{
              color: darkMode ? '#ffffff' : '#1a73e8',
              '&:hover': {
                color: '#1a73e8', // Change to blue on hover
              },
            }}
            onClick={async (value) => {
              setLoading(true);
              localStorage.setItem('clientSelect',prop._id);
              navigate(`/accountant/document/${prop._id}?k=&t=${encodeURIComponent('Approved')}`)
              localStorage.setItem("Tabs", 'Approved');
            }}
          >
            {prop?.approved}
          </MDTypography>
        </MDBox>,
          pendingapproval: prop?.pendingApproval == "-" ? '-' : 
          (<MDBox sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              fontSize="0.875rem"
              sx={{
                color: darkMode ? '#ffffff' : '#1a73e8',
                '&:hover': {
                  color: '#1a73e8', // Change to blue on hover
                },
              }}
                onClick={async (value) => {
                setLoading(true);
                localStorage.setItem('clientSelect',prop._id);
                navigate(`/accountant/document/${prop._id}?k=&t=${encodeURIComponent('PendingApproval')}`)
                localStorage.setItem("Tabs", 'PendingApproval');
              }}
            >
              {prop.pendingApproval}
            </MDTypography>
          </MDBox>),
          unreconciled: prop?.unreconciled == "-" ? "-" : 
          <MDBox sx={{ cursor: "pointer" ,'&:hover': { color: '#1a73e8' }}}>
            <MDTypography
              variant="caption"
              fontWeight="regular"
              fontSize="0.875rem"
              sx={{
                color: darkMode ? '#ffffff' : '#1a73e8',
                '&:hover': {
                  color: '#1a73e8', // Change to blue on hover
                },
              }}
              onClick={async (value) => {
                setLoading(true);
                localStorage.setItem('clientSelect',prop._id);
                navigate(`/accountant/document/${prop._id}?k=&t=${encodeURIComponent('Unreconciled')}`)
                localStorage.setItem("Tabs", 'Unreconciled');
              }}
            >
              {prop?.unreconciled}
            </MDTypography>
          </MDBox>,
          numberOfEmployees:
            prop?.numberOfEmployees !== "-" ? (
              <MDBox>
                <MDTypography
                  sx={{ cursor: "pointer" ,"&:hover": {
                        color: "#007bff", // Change to blue on hover
                      }}}
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  onClick={async (value) => {
                    setLoadingCircul(true);
                    setOpenEmploye(true);
                    setInvoiceUserId(prop._id);
                    await getUserListAPICall({
                      payload: [{ clientId: `${prop._id}`, offset: ThisOffsetuseD }],
                    });
                  }}
                >
                  {prop.numberOfEmployees}
                </MDTypography>
              </MDBox>
            ) : (
              prop.numberOfEmployees
            ),
          switch:
            prop.ownerAssigned === true ? (
              prop.isBlocked ? (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="error"
                  >
                    {" "}
                    Inactive
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="success"
                  >
                    Active
                  </MDTypography>
                </MDBox>
              )
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="warning"
                >
                  {" "}
                  Owner Not Assigned
                </MDTypography>
              </MDBox>
            ),
          action:
            prop.ownerAssigned == true ? (
              <MDBox display="flex">
                {/* {canAccess(actions = 'update', resource = 'clients') && */}
                <Link to={`/accountant/client/${prop._id}/edit-client`}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                  >
                    <Tooltip title={action.edittooltip} placement="top">
                      <Icon sx={darkMode ? { color: "#ffffff" } : {}}>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                </Link>
                {/* }  */}
                {prop.documentSource === "Paynthr"
                  ? canAccess((actions = "delete"), (resource = "clients")) && (
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                      >
                        <Tooltip
                          title={action.deletetooltip}
                          placement="top"
                          onClick={(value) => {
                            // setAccountantId(prop._id);
                            warningWithConfirmMessage();
                            setAccountantId(prop._id);
                          }}
                        >
                          <Icon sx={darkMode ? { color: "#ffffff" } : {}}>delete</Icon>
                        </Tooltip>
                      </MDTypography>
                    )
                  : prop.syncPercent === 100
                  ? canAccess((actions = "delete"), (resource = "clients")) && (
                      <MDTypography
                        variant="body2"
                        color="secondary"
                        sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                      >
                        <Tooltip
                          title={action.deletetooltip}
                          placement="top"
                          onClick={(value) => {
                            // setAccountantId(prop._id);
                            warningWithConfirmMessage();
                            setAccountantId(prop._id);
                          }}
                        >
                          <Icon sx={darkMode ? { color: "#ffffff" } : {}}>delete</Icon>
                        </Tooltip>
                      </MDTypography>
                    )
                  : ""}
              </MDBox>
            ) : (
              <MDBox display="flex">
                {setAccountantId(qboClientId)}
                <Link to={`/accountant/client/${prop._id}/edit-client`}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                  >
                    <Tooltip title={action.edittooltip} placement="top">
                      <Icon sx={darkMode ? { color: "#ffffff" } : {}}>edit</Icon>
                    </Tooltip>
                  </MDTypography>
                </Link>
                {canAccess((actions = "delete"), (resource = "clients")) && (
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.addowner}
                      placement="top"
                      onClick={(value) => {
                        setOwnerName(prop.name);
                        setAccountantId(prop._id);
                        setOpenAddOwner(true);
                      }}
                    >
                      <Icon sx={darkMode ? { color: "#ffffff" } : {}}>person</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
                {prop.syncPercent === 100 &&
                  canAccess((actions = "delete"), (resource = "clients")) && (
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                    >
                      <Tooltip
                        title={action.deletetooltip}
                        placement="top"
                        onClick={(value) => {
                          // setAccountantId(prop._id);
                          warningWithConfirmMessage();
                          setAccountantId(prop._id);
                        }}
                      >
                        <Icon sx={darkMode ? { color: "#ffffff" } : {}}>delete</Icon>
                      </Tooltip>
                    </MDTypography>
                  )}
              </MDBox>
            ),
        };
      });
      setHideNoData(true);
      setAdminList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  const dataTableData = {
    columns: [
      {
        Header: "CLIENT NAME",
        accessor: "client",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "To Review",
        accessor: "doctoreview",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unmapped Vendors",
        accessor: "unmappedvendors",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Client Approval",
        accessor: "clientapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Your Approval",
        accessor: "yourapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Pending Approval",
        accessor: "pendingapproval",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Unreconciled Statements",
        accessor: "unreconciled",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Users",
        accessor: "numberOfEmployees",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: adminList,
  };

  const dataTableDataEmploye = {
    columns: [
      {
        Header: "First NAME",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],

    rows: employessList,
  };

  useEffect(() => {
    if (qboState === "Success") {
      setShowQboSuccessSnackbar(true);
      const param = searchParams.get("status");
      if (param) {
        searchParams.delete("status");
        searchParams.delete("message");
        searchParams.delete("clientId");
        setSearchParams(searchParams);
      }
      setTimeout(() => {
        setOpenAddOwner(true);
      }, 1000);
      setTimeout(() => {
        setShowQboSuccessSnackbar(false);
      }, 10000);
    }
  }, [qboState]);

  const RemindMeFun = async () => {
    // cookies.set('remind_add_client', true, { path: '/', expires: expirationDate });
    cookies.set("remind_add_client", true, { path: "/", maxAge: 3600 });
    cookies.set("backup_remind_add_client", true, { path: "/", maxAge: 3600 });
    setOpenNoClient(false);
  };

  useEffect(() => {
    if (openNoClients === true) {
      const profileInfo11 = cookies.get("remind_add_client");
      const profileInfo12 = cookies.get("backup_remind_add_client");
      // setOpenNoClient(!profileInfo11);
      if (profileInfo12 === undefined) {
        cookies.set("remind_add_client", false, { path: "/", maxAge: 3600 });
      }
      
      if(onTrialCheck == false && cardAdded == null){
        setShowAddNewCradModel(true);
      }else if (profileInfo12 === undefined) {
        setOpenNoClient(true);
      } else if (profileInfo11 === false) {
        setOpenNoClient(true);
      }
    }
  }, [openNoClients]);

  const closeBox = async (event) => {
    setOpenVendorList(false);
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const closeUserDailog = async (event) => {
    setOpenEmploye(false);
    setemployessList([]);
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  const RedirectToBillingPage = () => {
    navigate('/accountants/billing')
  };

  const ReturnUrl = encodeURI(`${SITE_URL}/qbo-cb`);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar showBilling={show} />
        <MDBox my={3}>
          <Card
            sx={{
              opacity: loading ? "0.4" : "",
              borderRadius: loading ? "13px" : "",
              backgroundColor: darkMode ? "#202940" : "#ffffff",
            }}
          >
            <MDBox
              pt={3}
              px={2}
              sx={{ pr: 3, mb: 2 }}
              component="form"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                Clients
              </MDTypography>
              <MDBox px={2} display="flex">
                <MDBox sx={{ pr: 3, mb: 2 }}>
                  <MDInput
                    type="search"
                    label="Search Client"
                    onKeyDown={handleKeyDown}
                    variant="standard"
                    onChange={handleSearch}
                    InputLabelProps={{
                      style: darkMode
                        ? {
                            color: "#ffffff",
                          }
                        : {},
                    }}
                    sx={{
                      input: { color: darkMode ? "#ffffff" : "#000000" },
                      "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                    }} 
                  />
                </MDBox>
                <MDBox>
                  {canAccess((actions = "create"), (resource = "clients")) && 
                  (onTrialCheck == false && cardAdded == null ? (
                    <MDButton
                    variant="gradient"
                    color="info"
                    onClick={ShowAddNewCradModel}
                  >
                    Add New Client
                  </MDButton>
                  ):(
                      <ConnectToQuickBooksOnlineButton
                      buttonType={"custom"}
                      accountantId={encodeURI(profileInfo?.user?._id)}
                      accountantDomain={encodeURI(profileInfo?.user?.domainKey)}
                      accountantReturnUrl={ReturnUrl}
                      loading={loading}
                    />
                  )
                  )}
                </MDBox>
                   {/* {canAccess(actions = 'create', resource = 'clients') &&
                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                            onclick={intuit.ipp.anywhere.controller.onConnectToIntuitClicked()}
                                        >  Add New Client
                                        </MDButton>
                                    } */}
              </MDBox>
            </MDBox>
            <MDBox>{alert}</MDBox>
            <Box my={5} style={tableHeight}>
              <DataTable1
                table={dataTableData}
                entriesPerPage={true}
                pagination={true}
                showTotalEntries={true}
                hideNoData={hideNoData}
                response={tableEmpty}
                showCheck={false}
                ClientHeigth={true}
              />
            </Box>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="delete"
                title="Success"
                // autoHideDuration={3000}
                content="Client Deleted Successfully."
                open={showSnackbar}
                close={() => setShowSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="Owner Add Successfully."
                open={showAddSnackbar}
                close={() => setShowAddSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content={qboSuccessMessage}
                open={showQboSuccessSnackbar}
                close={() => setShowQboSuccessSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={addErrorMessage}
                open={showErrorSnackbar}
                close={() => setShowErrorSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed"
                content={qboErrorMessage}
                open={showQboErrorSnackbar}
                close={() => setShowQboErrorSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <Dialog
                open={alert}
                onClose={hideAlert}
                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "450px",
                      backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                      color: darkMode ? "#ffffff" : "#7b809a",
                    },
                  },
                }}
              >
                <DialogContent sx={{ overflowY: "hidden" }}>
                  <DialogContentText sx={darkMode ? {color:'#ffffff', display: "flex", justifyContent: "center", mt: 4 }:{ display: "flex", justifyContent: "center", mt: 4 }}>
                    <h3>Are you sure ?</h3>
                  </DialogContentText>
                  <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={darkMode ? {color:'#ffffff', fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 } :{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                    >
                      Once you delete this Client there is no way of getting any of the documents or
                      data back.
                    </MDTypography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                  <MDButton
                    variant="gradient"
                    disabled={dltButton}
                    color="success"
                    onClick={successDelete}
                  >
                    Yes, delete it!
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="error"
                    disabled={dltButton}
                    onClick={hideAlert}
                  >
                    Cancel
                  </MDButton>
                </DialogActions>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openAddOwner}
                onClose={() => setOpenAddOwner(false)}
                aria-labelledby="alert-dialog-title"
                sx={
                  darkMode
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "575px",
                            backgroundColor: "#202940 !important",
                            color: "white !important",
                          },
                        },
                      }
                    : {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",
                          },
                        },
                      }
                }
              >
                {" "}
                <DialogTitle
                  sx={
                    darkMode
                      ? {
                          justifyContent: "center",
                          display: "flex",
                          color: "#ffffff",
                        }
                      : {
                          justifyContent: "center",
                          display: "flex",
                        }
                  }
                >
                  Add Owner For - {ownerName}
                </DialogTitle>
                <DialogContent>
                  <AddOwnerDetailsDialog businessId={accountantId} />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox>
              <Dialog
                open={openVendorList}
                onClose={closeBox}
                aria-labelledby="alert-dialog-title"

                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      backgroundColor:darkMode ? '#1a2035 !important':'#ffffff',
                      color: darkMode ? '#ffffff':'#202940',
                      width: "100%",
                      maxWidth: "1370px",
                      minHeight: "40%",
                    },
                  },
                }}
              >
                <Toolbar
                  style={{
                    minHeight: "45px",
                    backgroundColor:darkMode ? '#1a2035':'#ffffff',
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Icon sx={{ cursor: "pointer" }} onClick={closeBox}>
                    close
                  </Icon>
                </Toolbar>
                <DialogContent>
                  <Vendor
                    businessId={invoiceId}
                    syncPercent={100}
                    documentSource={documentSource}
                    IsSynced={false}
                  />
                </DialogContent>
              </Dialog>
            </MDBox>
            <MDBox display="flex" justifyContent="center">
              {loading && <Loader />}
            </MDBox>
          </Card>
        </MDBox>
        <MDBox>
          <Modal
            open={openNoClient}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={darkMode ? ModalDrakstyle : Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0 }}
                  onClick={() => setOpenNoClient(false)}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={darkMode ? { mt: 2 , color: '#ffffff' } : { mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Let’s add your first client to get the most use out of Paynthr
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={darkMode  ?  {color: '#ffffff', mt: 4, ml: 1 } : { mt: 4, ml: 1 }}>
                To initiate the synchronization with QuickBooks, we'll need to obtain a set of
                default accounts, vendors, and items for your QuickBooks account. Our system will
                seamlessly map your QuickBooks data to the corresponding Paynthr account. The
                mapping process can start after establishing the
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
                sx={darkMode ? {color:'#ffffff'} : {}}
              >
                connection.
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 4 }}>
                <ConnectToQuickBooksOnlineButton
                  buttonType={"Icon"}
                  accountantId={encodeURI(profileInfo?.user?._id)}
                  accountantDomain={encodeURI(profileInfo?.user?.domainKey)}
                  accountantReturnUrl={ReturnUrl}
                />
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                <MDButton onClick={RemindMeFun}>Remind me later</MDButton>
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
          <Modal
            open={showAddNewCradModel}
            // onClose={() => setOpenNoClient(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Modalstyle}>
              <MDBox display="flex" justifyContent="end" style={{ cursor: "pointer" }}>
                <MDTypography
                  component="span"
                  variant="body2"
                  color="dark"
                  opacity={1}
                  sx={{ lineHeight: 0 }}
                  onClick={() => setShowAddNewCradModel(false)}
                >
                  <Icon>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="center" mb={3}>
                <img className="small" src={logo} alt="no image" height="80px" width="80px" />
              </MDBox>
              <Typography
                sx={{ mt: 2 }}
                display={"flex"}
                justifyContent={"center"}
                id="modal-modal-title"
                variant="h5"
                component="h2"
              >
                Trial Period Ended: Add Card Details
              </Typography>
              <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1 }}>
                 Your trial period has ended. To continue our service without interruption or to add a new client, please add your card details. Thank you for being a valued user, and we hope to keep serving you.
              </Typography>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant="body2"
                id="modal-modal-description"
              >
              </Typography>
              <MDBox display="flex" justifyContent="center" sx={{ mt: 4,mb:2 }}>
              <MDButton
                    variant="gradient"
                    color="info"
                    onClick={RedirectToBillingPage}
                  >
                    Add Card Details
                  </MDButton>
              </MDBox>
              <MDBox display="flex" justifyContent="center">
                {/* <MDButton onClick={() => setOnTrialCheck(false)}>Remind me later</MDButton> */}
              </MDBox>
            </Box>
          </Modal>
        </MDBox>
        <MDBox>
          <Dialog
            open={openEmploye}
            onClose={() => setOpenEmploye(false)}
            aria-labelledby="responsive-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  minHeight: "500px",
                  maxWidth: "1000px",
                  backgroundColor:darkMode ? '#202940 !important':'#ffffff',
                  color: darkMode ? '#ffffff':'#7b809a',
                },
              },
            }}
          >
            <DialogContent>
              <MDBox my={5}>
                <MDBox display="flex" justifyContent="center">
                  {loadingCircul && <Loader />}
                </MDBox>
                <MDBox my={5} sx={{ mt: 1 }}>
                  <DataTable2
                    table={dataTableDataEmploye}
                    entriesPerPage={false}
                    pagination={true}
                    showTotalEntries={true}
                    response={totalEmployes}
                    showCheck={false}
                    ClientHeigth={true}
                  />
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeUserDailog}>Close</Button>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </MDBox>
        <Modal
          open={openNewclientPopup}
          onClose={() => setOpenNewclientPopup(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MDTypography display="flex" justifyContent="center">
              Add New Client
            </MDTypography>
            <MDBox sx={{ pl: 0, pt: 5 }} display="flex" justifyContent="space-around">
              <MDBox>
                {canAccess((actions = "create"), (resource = "clients")) && (
                  <MDButton
                    component={NavLink}
                    to="/accountant/client/new-client"
                    variant="gradient"
                    color="info"
                  >
                    {" "}
                    Through Paynthr
                  </MDButton>
                )}
              </MDBox>
              <MDBox>
                {canAccess((actions = "create"), (resource = "clients")) && (
                  <ConnectToQuickBooksOnlineButton
                    buttonType={"custom"}
                    accountantId={encodeURI(profileInfo?.user?._id)}
                    accountantDomain={encodeURI(profileInfo?.user?.domainKey)}
                    accountantReturnUrl={ReturnUrl}
                  />
                )}
              </MDBox>
            </MDBox>
          </Box>
        </Modal>
      </DashboardLayout>
    </>
  );
}

export default UserHoc(Clients);
