import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Field, Form, Formik } from "formik";
import FormField from "layouts/admin/accountants/components/FormField";
import { useCallback, useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import { AddNewItem, GetCategoryAllList, GetCategoryListForParent, GetClassesAllList, GetClassListForParent, GetCustomerAllList, GetCustomerListForParent, GetProductsAllList, } from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import CurrencyFieldText from "../components/CurrencyField-Text";
import NumberFieldText from "../components/NumberField";
import AddNewCategoryDialog from "./addnewcategory";
import AddClassDialog from "./addnewclass";
import AddCustomerDialog from "./addnewcustomer";

const useStyles = makeStyles({
  option: {
    "&:first-child": {
      color: "#0000ff",
    },
    "&:hover:first-child": {
      color: "#0000ff",
      backgroundColor: "none !important",
    },
  },
});

function AddCategoryItemDialog({
  invoiceId,
  onClose,
  currencySymbol,
  businessId,
  itemList,
  toggleShow,
  showClasses,
}) {
  const styles = useStyles();
  const [invoiceIds, setInvoiceIds] = useState(invoiceId);
  const [isDisable, setBtnDisable] = useState(false);
  const [value, setValue] = useState("AccountBasedExpenseLineDetail");
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [TotalPrice, setTotalPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [unitPrice, setUnitPrice] = useState("");
  const [selectProduct, setSelectProduct] = useState(false);
  const [classesList, setClassesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [classIdIds, setClassIdId] = useState("");
  const [customerIds, setCustomerIdId] = useState("");
  const [categoryIds, setCategoryIds] = useState("");
  const [adjustInvoice, setAdjustInvoice] = useState(false);
  const [categoryerrormsg, setCategoryerrormsg] = useState("");
  const [categoryerror, setCategoryerror] = useState(false);
  const [priceerror, setPriceerror] = useState(false);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [className, setClassName] = useState("");
  const [showerror, setShowerror] = useState(false);
  const [showerrorqty, setShowerrorqty] = useState(false);
  const [errorButtonDis, setErrorButtonDis] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [quantityerror, setQuantityrror] = useState(false);
  const [quantityErrorMsg, setQuantityErrorMsg] = useState("");
  const [priceErrorMsg, setPriceErrorMsg] = useState("");
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [filterloading, setFilterLoading] = useState(false);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerOffset, setCustomerOffset] = useState(0);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [classLoading, setClassLoading] = useState(false);
  const [hasMoreClasses, setHasMoreClasses] = useState(true);
  const [classOffset, setClassOffset] = useState(0);
  const [classSearch, setClassSearch] = useState('');
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
  const [
    { isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },
    CustomerListAPICall,
  ] = useAPICall(GetCustomerAllList, { onLoad: false });
  const [
    { isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },
    ClassesListAPICall,
  ] = useAPICall(GetClassesAllList, { onLoad: false });
  const [
    { isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },
    CategoryListAPICall,
  ] = useAPICall(GetCategoryAllList, { onLoad: false });
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsAllList, { onLoad: false });

  const AddCategory = { name: "+ Add Category" };
  const NewCategoryList = [AddCategory, ...categoryList];
  const AddCustomer = { name: "+ Add Customer" };
  const NewCustomerList = [AddCustomer, ...customerList];
  const AddClass = { name: "+ Add Class" };
  const NewClassesList = [AddClass, ...classesList];

  useEffect(async () => {
    setValue("AccountBasedExpenseLineDetail");
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  const initialValues = {
    product: "",
    checked: "",
    item: "",
    sku: "",
    price: "",
    total: "",
    qty: "",
    services: "",
    class: "",
    customer: "",
    unit: "",
    category: "",
    TotalPrice: "",
  };

  useEffect(() => {
    let prices = typeof price == "string" ? price.replaceAll(",", "") : price;
    let quantitys = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    if (showerror === true) {
      // if (!prices) {
      //   setPriceErrorMsg("Price is required");
      //   setPriceerror(true);
      // } else if (prices.length === 0 || Number(prices) === 0) {
      //   setPriceErrorMsg("Price Must be more than 0");
      //   setPriceerror(true);
      // } else {
      //   setPriceErrorMsg("");
      //   setPriceerror(false);
      // }
    }

    if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
      setErrorButtonDis(true);
    } else {
      setErrorButtonDis(false);
    }

    // if (showerrorqty === true) {
    //   if (!quantitys) {
    //     setQuantityErrorMsg("Quantity is required");
    //     setQuantityrror(true);
    //   } else if (quantitys.length === 0 || Number(quantitys) === 0) {
    //     setQuantityErrorMsg("Quantity Must be more than 0");
    //     setQuantityrror(true);
    //   } else {
    //     setQuantityErrorMsg("");
    //     setQuantityrror(false);
    //   }
    // }

    const timeOutId = setTimeout(
      async () => setTotalPrice(Number(prices) * Number(quantitys)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [price, quantity]);

  useEffect(() => {
    if (
      price.length === 0 ||
      (Number(price) === 0.0 && quantity.length === 0) ||
      Number(quantity) === 0
    ) {
      setErrorButtonDis(true);
    } else {
      setErrorButtonDis(false);
    }
  }, []);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      eventBus.$emit("closeErrorAddNewItemPopup", response);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeAddNewItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          customerId: prop._id,
        };
      });
      // setCustomerList(data);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      // setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      // setCategoryList(data);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
        };
      });
      setProductsList(data);
    }
  }, [ProductsResponse]);

  const handleSubmit = async (values) => {
  
    if (categoryIds) {
      setCategoryerrormsg("");
      setCategoryerror(false);
      setBtnDisable(true);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
      return;
    }
    const finalAmount = typeof values.price == "string" ? values.price.replaceAll(",", "") : values.price;
    const finalprice = typeof price == "string" ? price.replaceAll(",", "") : price;
    const finalTotalPrice = typeof TotalPrice == "string" ? TotalPrice.replaceAll(",", "") : TotalPrice;
    const finalquantity = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    await setAPICall({
      payload: [
        {
          itemType: "AccountBasedExpenseLineDetail",
          // "amount": Number(finalAmount),
          unitOfMeasure: values.unit,
          discount: 0,
          description: values.item,
          InvoiceId: `${invoiceIds}`,
          classId: classIdIds,
          customerId: customerIds,
          categoryId: categoryIds,
          adjustDocument: adjustInvoice,
          unitPrice: Number(finalprice),
          amount: Number(finalTotalPrice),
          quantity: Number(finalquantity),
        },
      ],
    });
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  useEffect(() => {
    if (categoryIds) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else if (isDisable && !categoryIds) {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }
  }, [categoryIds]);

  const handleChangeCategory = (event, newValue) => {
    if (newValue) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }
    if (newValue == "+ Add Category") {
      setOpenAddNewCategory(true);
    } else {
      setCategoryName(newValue);
      const found = categoryList.find((obj) => {
        return obj.name === newValue;
      });
      if (found) {
        setCategoryIds(found.categoryId);
        setSelectProduct(true);
        setDescription(found.description);
        setUnitPrice(found.unitPrice);
      }
    }
  };

  const handleChangeClass = (event, newValue) => {
    if (newValue == "+ Add Class") {
      setOpenAddNewClass(true);
    } else {
      setClassName(newValue);
      const found1 = classesList.find((obj) => {
        return obj.name === newValue;
      });
      if (found1) {
        setClassIdId(found1.classId);
      }
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    if (newValue == "+ Add Customer") {
      setOpenAddNewCustomer(true);
    } else {
      setCustomerName(newValue);
      const found2 = customerList.find((obj) => {
        return obj.name === newValue;
      });
      if (found2) {
        setCustomerIdId(found2.customerId);
      }
    }
  };

  eventBus.$on("AddnewCategoryComplete", (data) => AddnewCategoryComplete(data));
  async function AddnewCategoryComplete(data) {
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    setCategoryName(data?.data?.account?.name);
    setCategoryIds(data?.account?._id);
  }

  eventBus.$on("AddnewCustomerComplete", (data) => AddnewCustomerComplete(data));
  async function AddnewCustomerComplete(data) {
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    setCustomerName(data?.customer?.name);
    setCustomerIdId(data?.customer?._id);
  }

  eventBus.$on("AddnewClassComplete", (data) => AddnewClassComplete(data));
  function AddnewClassComplete(data) {
    setClassName(data?.class?.name);
    setClassIdId(data?.class?._id);
    ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }


  const fetchCategories = useCallback(async (newSearch = '', newOffset = 0) => {
    setFilterLoading(true);
    try {
      const response = await GetCategoryListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop.name,
        categoryId: prop._id,
      }));

      // Append new data instead of resetting the list
      if (newOffset === 0) {
        setCategoryList(data);
      } else {
        setCategoryList((prev) => [...prev, ...data]);
      }

      // Stop loading more if fewer than 50 items are returned
      if (data.length < 50) {
        setHasMore(false);
      }

      setOffset(newOffset);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
    setFilterLoading(false);
  }, [businessId]);

  const fetchCustomers = useCallback(async (newSearch = '', newOffset = 0) => {
    setCustomerLoading(true);
    try {
      const response = await GetCustomerListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 customers at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop.name,
        customerId: prop._id,
      }));

      // Append new data instead of resetting the list
      if (newOffset === 0) {
        setCustomerList(data);
      } else {
        setCustomerList((prev) => [...prev, ...data]);
      }

      // Stop loading more if fewer than 50 items are returned
      if (data.length < 50) {
        setHasMoreCustomers(false);
      }

      setCustomerOffset(newOffset);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
    setCustomerLoading(false);
  }, [businessId]);

  const fetchClasses = useCallback(async (newSearch = '', newOffset = 0) => {
    setClassLoading(true);
    try {
      const response = await GetClassListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop.name,
        classId: prop._id,
      }));

      if (newOffset === 0) {
        setClassesList(data); // Reset the list when new search
      } else {
        setClassesList((prev) => [...prev, ...data]); // Append new data for infinite scroll
      }

      if (data.length < 50) {
        setHasMoreClasses(false); // Stop loading more if fewer than 50 items
      }

      setClassOffset(newOffset);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
    setClassLoading(false);
  }, [businessId]);

  const handleClassScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5;
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreClasses) {
      setClassOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchClasses(classSearch, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5; // Adjust if necessary to control sensitivity
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMore) {
      setOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchCategories(search, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };

  const handleScrollCustomer = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5; // Adjust if necessary to control sensitivity
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;
    if (bottom && hasMoreCustomers) {
      setCustomerOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchCustomers(customerSearch, newOffset); // Fetch next set of customers
        return newOffset;
      });
    }
  };

  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={addCategoryschema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox p={2} pt={2}>
                  <MDBox>
                    <MDBox>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          sx={darkMode
                              ? {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                    color: "white !important",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                    color: "white !important",
                                  },
                                }
                              : {
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                }}
                          value={values.category} // Bind to Formik value
                          options={NewCategoryList}
                          getOptionLabel={(option) => option.name || ""}
                          onFocus={() => {
                            // Always trigger data fetching on focus
                            setSearch(''); // Clear any existing search term
                            setOffset(0); // Reset the offset
                            setHasMore(true); // Reset hasMore for loading more items
                            fetchCategories('', 0); // Fetch initial data when input is focused
                          }}
                          onInputChange={(event, newInputValue) => {
                            setSearch(newInputValue);
                            setOffset(0); // Reset the offset
                            setHasMore(true); // Reset hasMore for loading more items
                            fetchCategories(newInputValue, 0); // Fetch new data
                            handleChangeCategory(event, newInputValue);
                          }}
                          onChange={(event, newValue) => {
                            setFieldValue("category", newValue); // Update Formik field on selection
                          }}
                          ListboxProps={{
                            onScroll: handleScroll,
                            sx: {
                              maxHeight: '200px', // Set a max-height to enable scrolling
                              overflowY: 'auto',  // Enable vertical scrolling
                              fontSize: '0.8125rem',
                              '@media (max-width: 1440px)': {
                                fontSize: '0.775rem',
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Category *"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? "#ffffff" : "#000000" },
                              }}
                              classes={{
                                option: styles.option,
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {filterloading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              // helperText={touched.category && errors.category}
                              // error={touched.category && Boolean(errors.category)}
                            />
                          )}
                        />
                        {categoryerror && (
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              {categoryerrormsg}
                            </MDTypography>
                          </MDBox>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          sx={
                            darkMode
                              ? {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                    color: "white !important",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                    color: "white !important",
                                  },
                                }
                              : {
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                }
                          }
                          value={values.customer} // Bind to Formik value
                          options={NewCustomerList}
                          getOptionLabel={(option) => option.name || ''}
                          onFocus={() => {
                            // Always trigger data fetching on focus
                            setCustomerSearch(''); // Clear any existing search term
                            setCustomerOffset(0); // Reset the offset
                            setHasMoreCustomers(true); // Reset hasMore for loading more items
                            fetchCustomers('', 0); // Fetch initial data when input is focused
                          }}
                          onInputChange={(event, newInputValue) => {
                            setCustomerSearch(newInputValue);
                            handleChangeCustomer(event, newInputValue);
                            setCustomerOffset(0); // Reset the offset
                            setHasMoreCustomers(true); // Reset hasMore for loading more items
                            fetchCustomers(newInputValue, 0); // Fetch new data
                          }}
                          onChange={(event, newValue) => {
                            setFieldValue('customer', newValue); // Update Formik field on selection
                            setCustomerName(newValue ? newValue.name : ''); // Update customerName state
                          }}
                          ListboxProps={{
                            onScroll: handleScrollCustomer,
                            sx: {
                              maxHeight: '200px', // Set a max-height to enable scrolling
                              overflowY: 'auto', // Enable vertical scrolling
                              fontSize: '0.8125rem',
                              '@media (max-width: 1440px)': {
                                fontSize: '0.775rem',
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? '#ffffff' : '#000000' },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {customerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              // helperText={touched.customer && errors.customer}
                              // error={touched.customer && Boolean(errors.customer)}
                            />
                          )}
                          />
                      </Grid>
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {showClasses ? (
                          <>
                            <Grid item xs={12} sm={6} mt={0.5}>
                            <Autocomplete
                                sx={
                                  darkMode
                                    ? {
                                        "& .MuiInput-underline": {
                                          borderBottom: "2px solid #ffffff",
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-root .MuiInput-input": {
                                          WebkitTextFillColor: "white",
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                          color: "white !important",
                                        },
                                        "& .MuiInput-input": {
                                          padding: `6px 4px 4px 0px !important`,
                                        },
                                        "& .MuiInputBase-input": {
                                          height: `1.2em !important`,
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                          color: "white !important",
                                        },
                                      }
                                    : {
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-input": {
                                          padding: `6px 4px 4px 0px !important`,
                                        },
                                        "& .MuiInputBase-input": {
                                          height: `1.2em !important`,
                                        },
                                      }
                                }
                                value={{ name: values.className }} // Bind to Formik value
                                options={NewClassesList}
                                getOptionLabel={(option) => option.name || ""}
                                onFocus={() => {
                                  // Always trigger data fetching on focus
                                  setClassSearch(''); // Clear any existing search term
                                  setClassOffset(0); // Reset the offset
                                  setHasMoreClasses(true); // Reset hasMore for loading more items
                                  fetchClasses('', 0); // Fetch initial data when input is focused
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setClassSearch(newInputValue);
                                  setClassOffset(0); // Reset the offset
                                  setHasMoreClasses(true); // Reset for loading more items
                                  fetchClasses(newInputValue, 0); // Fetch new data
                                  handleChangeClass(event, newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                  setFieldValue("className", newValue); // Update Formik field on selection
                                }}
                                ListboxProps={{
                                  onScroll: handleClassScroll,
                                  sx: {
                                    maxHeight: '200px', // Set a max-height to enable scrolling
                                    overflowY: 'auto',  // Enable vertical scrolling
                                    fontSize: '0.8125rem',
                                    '@media (max-width: 1440px)': {
                                      fontSize: '0.775rem',
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Class"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: darkMode ? "#ffffff" : "#000000" },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {classLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    helperText={touched.className && errors.className}
                                    error={touched.className && Boolean(errors.className)}
                                  />
                                )}
                            />
                            </Grid>
                            <Grid item xs={12} sm={6} mt={-0.5}>
                              <FormField type="text" label="Description" name="item" />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Description" name="item" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Unit" name="unit" fullWidth />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {showClasses && (
                          <>
                            <Grid item xs={12} sm={6} mt={-0.7}>
                              <FormField type="text" label="Unit" name="unit" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <CurrencyFieldText
                                label="Price"
                                name="price"
                                value={price}
                                fullWidth
                                // error={priceerror}
                                onValueChange={(event) => {
                                  setPrice(event.value);
                                  setShowerror(true);
                                }}
                              />
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  {priceErrorMsg}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {!showClasses && (
                          <Grid item xs={12} sm={6} mt={1}>
                            <CurrencyFieldText
                              label="Price"
                              name="price"
                              value={price}
                              fullWidth
                              // error={priceerror}
                              onValueChange={(event) => {
                                setPrice(event.value);
                                setShowerror(true);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {priceErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        )}
                        {!showClasses && (
                          <Grid item xs={12} sm={6} mt={1}>
                            <NumberFieldText
                              label="Quantity"
                              name="qty"
                              fullWidth
                              // error={quantityerror}
                              value={quantity}
                              onValueChange={(event) => {
                                setQuantity(event.value);
                                setShowerrorqty(true);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {quantityErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        )}
                        {showClasses && (
                          <Grid item xs={12} sm={6}>
                            <NumberFieldText
                              label="Quantity"
                              name="qty"
                              fullWidth
                              // error={quantityerror}
                              value={quantity}
                              onValueChange={(event) => {
                                setQuantity(event.value);
                                setShowerrorqty(true);
                              }}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {quantityErrorMsg}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        )}
                        {showClasses && (
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Total Price"
                              name="TotalPrice"
                              disabled
                              fullWidth
                              value={TotalPrice}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3} sx={{ mb: 1 }}>
                        {!showClasses && (
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Total Price"
                              name="TotalPrice"
                              disabled
                              fullWidth
                              value={TotalPrice}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDBox>
                          <Field
                            as={FormControlLabel}
                            type="checkbox"
                            name="checkbox"
                            control={<Checkbox />}
                            label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Adjust Total Price</span>}
                            checked={adjustInvoice}
                            onChange={handleChange}
                            sx={{ mt: 2, color: darkMode ? "#ffffff" : "#000000" }}
                          />
                        </MDBox>
                      </Grid>
                    </MDBox>
                    <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="light"
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          // disabled={isDisable || errorButtonDis}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          {isDisable ? "Submitting..." : "Submit"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
        <MDBox>
          <Dialog
            open={openAddNewCustomer}
            onClose={() => setOpenAddNewCustomer(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  color: darkMode ? "#ffffff" : "#7b809a",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                color: darkMode ? "#ffffff" : "#7b809a",
                justifyContent: "center",
                display: "flex",
              }}
            >
              Add Customer
            </DialogTitle>
            <DialogContent>
              <AddCustomerDialog
                invoiceId={invoiceId}
                businessId={businessId}
                onClose={() => setOpenAddNewCustomer(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <Dialog
            open={openAddNewClass}
            onClose={() => setOpenAddNewClass(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                },
              },
            }}
          >
            {" "}
            <DialogTitle
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              Add Class
            </DialogTitle>
            <DialogContent>
              <AddClassDialog
                invoiceId={invoiceId}
                businessId={businessId}
                onClose={() => setOpenAddNewClass(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        {/* <MDBox>
                <DraggableNewCategoryDialog 
                      open={openAddNewCategory}
                      showClasses={showClasses}
                      temList={itemList}
                      businessId={businessId}
                      currencySymbol={currencySymbol}
                      invoiceId={invoiceId}
                      toggleShow={toggleShow}
                       onClose={() => setOpenAddNewCategory(false)}/>
        </MDBox> */}
        <MDBox>
        <Dialog
                open={openAddNewCategory}
                onClose={() => setOpenAddNewCategory(false)}
                aria-labelledby="alert-dialog-title"
                sx={
                  darkMode
                    ? {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            // height: "40%",
                            maxWidth: "570px",
                            backgroundColor: "#202940 !important",
                            color: "white !important",
                          },
                        },
                      }
                    : {
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            // height: "40%",
                            maxWidth: "570px",
                          },
                        },
                      }
                }
              >
                {" "}
                <DialogTitle
                  sx={
                    darkMode
                      ? {
                          justifyContent: "center",
                          display: "flex",
                          color: "#ffffff",
                        }
                      : {
                          justifyContent: "center",
                          display: "flex",
                        }
                  }
                >
                  Add Category
                </DialogTitle>
                <DialogContent>
                  <AddNewCategoryDialog
                    Edit={false}
                    invoiceId={invoiceId}
                    businessId={businessId}
                    onClose={() => setOpenAddNewCategory(false)}
                  />
                </DialogContent>
              </Dialog>
        </MDBox>
      </MDBox>
    </>
  );
}

export default AddCategoryItemDialog;
