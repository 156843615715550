import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import AddExpenseSh from "./components/schemas/addexpense";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { PostExpenseAccountList } from "../../../../services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { eventBus } from "utils/eventbus";
import { useMaterialUIController } from "context";

function AddNewExpense({ onClose, invoiceId, businessId }) {
  const [business_id, setBusiness] = useState(businessId);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let initialValues = {
    expense: "",
  };

  const [
    {
      isLoading: isAddexpenseAccountLoading,
      isError: isAddexpenseAccountError,
      response: AddexpenseAccountResponse,
    },
    AddexpenseAccountListAPICall,
  ] = useAPICall(PostExpenseAccountList, { onLoad: false });

  useEffect(() => {
    if (isAddexpenseAccountLoading) {
      return () => null;
    }
    if (isAddexpenseAccountError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (AddexpenseAccountResponse && AddexpenseAccountResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("AddnewExpenseAccountComplete", AddexpenseAccountResponse?.data?.data);
      onClose();
    }
  }, [AddexpenseAccountResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await AddexpenseAccountListAPICall({
      payload: [
        {
          name: values?.expense,
          classification: "Expense",
          accountType: "Cost of Goods Sold",
          accountSubType: "SuppliesMaterialsCogs",
          clientId: `${business_id}`,
        },
      ],
    });
  };

  return (
    <>
      {
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={AddExpenseSh}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form autoComplete="off">
                      <MDBox p={3} pl={"30%"}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <FormField type="text" label="Name *" name="expense" />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      }
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default AddNewExpense;
