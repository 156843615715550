import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

function DefaultCell({ value, suffix ,color}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDTypography variant="caption" fontWeight="regular" fontSize="0.875rem" color= {darkMode ? "light":"text"}>
      {value}
      {suffix && (
        <MDTypography variant="caption" fontWeight="regular" fontSize="0.875rem" color="secondary">
          &nbsp;&nbsp;{suffix}
        </MDTypography>
      )}
    </MDTypography>
  );
}

DefaultCell.defaultProps = {
  suffix: "",
};

DefaultCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCell;
