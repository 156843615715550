import Request from "./adminapicallservice";
const PageLimit = process.env.REACT_APP_PAGE_LIMIT;

export const CreateAccountant = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/profiles/`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetEmployeesList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/${params.employessId}/employees`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/${params.clientsId}/clients`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetuserDetailsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditUserInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/employees/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/admins/accountants/?keyword=${params.keyword}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    } else {
        var apiUrl = `/admins/accountants/?limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetDeleteList = async (params) => {
    if (params.isArchived && params.keyword) {
        var apiUrl = `/admins/accountants/?isArchived=${params.isArchived}&keyword=${params.keyword}`;
    } else if (params.isArchived) {
        var apiUrl = `/admins/accountants/?isArchived=${params.isArchived}`;
    } else {
        var apiUrl = `/admins/accountants/`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const EditAccountantInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/profiles/`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const checkEmail = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/check`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetAdminBilling = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/${params.accountantId}/invoices`;
        delete params.accountantId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientLogsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/${params.accuntantId}/invoices/${params.invoiceId}/breakups`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAdminClientsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        if (params.keyword) {
            var apiUrl = `/admins/clients/?keyword=${params.keyword}&limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
        } else {
            var apiUrl = `/admins/clients/?limit=${params.limit ? params.limit : 10}&offset=${params.offset}`;
        }
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAdminRestaurantList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params?.clientId}/restaurants/?offset=0&limit=100&SortDir=ASC`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetTotalEmplyessList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params?.clientId}/employees/?offset=0&limit=10&SortField=name&SortDir=ASC`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const sendReinviteEmailList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/accountants/reinvite`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const sendClientReinviteEmailList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/reinvite`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const getreAdminSendTFA = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/users/tfa-resend`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const getAdminVerifyTFAAPI = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/users/tfa`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}
