import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import AddAssetSh from "./components/schemas/AddAsset";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { PostassetAccountList } from "../../../../services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { eventBus } from "utils/eventbus";

function AddNewAsset({ onClose, invoiceId, businessId }) {
  const [business_id, setBusiness] = useState(businessId);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  let initialValues = {
    asset: "",
  };

  const [
    {
      isLoading: isAddassetAccountLoading,
      isError: isAddassetAccountError,
      response: AddassetAccountResponse,
    },
    AddassetAccountListAPICall,
  ] = useAPICall(PostassetAccountList, { onLoad: false });

  useEffect(() => {
    if (isAddassetAccountLoading) {
      return () => null;
    }
    if (isAddassetAccountError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (AddassetAccountResponse && AddassetAccountResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("AddnewassetAccountComplete", AddassetAccountResponse?.data?.data);
      onClose();
    }
  }, [AddassetAccountResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await AddassetAccountListAPICall({
      payload: [
        {
          name: values?.asset,
          classification: "Asset",
          accountType: "Other Current Asset",
          accountSubType: "Inventory",
          clientId: `${business_id}`,
        },
      ],
    });
  };

  return (
    <>
      {
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={AddAssetSh}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form autoComplete="off">
                      <MDBox p={3} pl={"30%"}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <FormField type="text" label="Name *" name="asset" />
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      }
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default AddNewAsset;
