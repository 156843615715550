export default {
  control: {
    backgroundColor: 'transparent', // Default background color
    fontSize: 16,
    "& textarea": {
      color: "#ffffff", // Text color
      backgroundColor: "#605a5a", // Background color
      "::placeholder": {
        color: "#8e8e8e", // Placeholder color
      },
    },
  },
  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 70,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      color: "#ffffff",
      padding: 9,
      border: "1px solid silver",
    },
  },
  "&singleLine": {
    display: "inline-block",
    width: 180,
    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      color:"#ffffff",
      padding: 1,
      border: "2px inset",
    },
  },
  suggestions: {
    list: {
      backgroundColor: "black",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "black",
      },
    },
  },
};
