import { eventBus } from "utils/eventbus";
import { SITE_URL } from "helpers/config";
import { setQboData } from "actions/rolePermission";
import { useDispatch } from "react-redux";

function CloseQboWindow() {
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const clientId = queryParameters.get("clientId");
  const status = queryParameters.get("status");
  const message = queryParameters.get("message");
  setTimeout(() => {
    localStorage.setItem("queryParameters", [clientId, status, message]);
    dispatch(setQboData({ url: clientId }));
  }, 1000);
  setTimeout(() => {
    window.close();
  }, 2000);
  eventBus.$emit("closeQboWindowApiCall", "Close");
  return null;
  // window.close();
  // const Close = true;
  // window.opener.location.reload();
  // var parent1 = window.opener;
  // parent1.location = `${SITE_URL}/accountant/client/list?clientId=${clientId}&status=${status}&message=${message}`;
  // window.opener.location.reload();
}

export default CloseQboWindow;
