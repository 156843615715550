import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { setPermission, setProfileInfo, setRole } from "actions/rolePermission";
import bgImage from "assets/images/backgroundimage.jpg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { setDarkMode, useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import FormField from "layouts/auth/components/Admin/signIn/FormField";
import BasicLayout from "layouts/auth/components/BasicLayout";
import initialValues from "layouts/auth/components/schemas/initialValues";
import { SignInSchema } from "layouts/auth/components/schemas/validations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { GetProfile } from "../../../services/accountantservices";
import { Login } from "../../../services/authservices";
import { useAPICall } from "../../../utils/useapicall";

const cookies = new Cookies();
function SignIn() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disButton, setDisButton] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const reduxDispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword(true);
  const handleClickClosePassword = () => setShowPassword(false);

  const [{ isError, isLoading, response }, setAPICall] = useAPICall(Login, { onLoad: false });
  const [
    { isLoading: isGetLoading, isError: isGetError, response: profileResponse },
    getProfileAPICall,
  ] = useAPICall(GetProfile, { onLoad: false });

  useEffect(() => {
    const Token = cookies.get("admin_access_token");
    if (Token) {
      navigate("/admin/client/list");
    }else{
      navigate("/sign-in");
    }
  }, []);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setDisButton(false);
      setLoading(false);
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      cookies.set("admin_access_token", response.data.data.token.access_token, { path: "/" });
      await getProfileAPICall({ payload: [] });
    }
  }, [response]);

  useEffect(() => {
    if (isGetLoading) {
      return () => null;
    }

    if (isGetError) {
      return () => null;
    }

    if (profileResponse && profileResponse.data.isSuccess === true) {
      setDarkMode(dispatch, profileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem(
        "PaynthrAdminUiThemeMode",
        profileResponse?.data?.data?.user?.themeColor
      );
      reduxDispatch(setProfileInfo(profileResponse.data.data));
      cookies.set("admin_profile_info", profileResponse.data.data, { path: "/" });

      if (
        profileResponse.data.data &&
        profileResponse.data.data.user &&
        profileResponse.data.data.user.userType &&
        profileResponse.data.data.user.userType != ""
      ) {
        reduxDispatch(setRole(profileResponse.data.data.user.userType));
        if (
          profileResponse.data.data.user &&
          profileResponse.data.data.user.permissions &&
          profileResponse.data.data.user.permissions[0] &&
          profileResponse.data.data.user.permissions[0].role &&
          profileResponse.data.data.user.permissions[0].role.grants
        ) {
          const adminPermission = profileResponse.data.data.user.permissions[0].role.grants;
          reduxDispatch(setPermission(adminPermission));
          cookies.set("permissions", adminPermission, { path: "/" });
        }
      }
      navigate("/admin/client/list");
    }
  }, [profileResponse]);

  const onSubmit = async (values) => {
    setLoading(true);
    setDisButton(true);
    await setAPICall({
      payload: [
        {
          email: `${values.email}`,
          password: `${values.password}`,
        },
      ],
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          sx={{
            backgroundColor: "#80D6C3",
          }}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Welcome to Paynthr
          </MDTypography>
        </MDBox>
        <Formik initialValues={initialValues} validationSchema={SignInSchema} onSubmit={onSubmit}>
          {({ values, errors, touched, isSubmitting }) => (
            <Form autoComplete="off">
              <div>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox mb={2}>
                    <MDBox mb={2}>
                      <FormField type="email" name="email" label="Email" autoComplete='off' />
                    </MDBox>
                    <MDBox mb={2}>
                      <FormField
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        name="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton sx={{ fontSize: "0.9rem" }}>
                                {!showPassword ? (
                                  <VisibilityOff onClick={handleClickShowPassword} />
                                ) : (
                                  <Visibility onClick={handleClickClosePassword} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        component={Link}
                        to="/admin/reset-password"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{
                          cursor: "pointer",
                          float: "right",
                          padding: " 0px 0px 40px ",
                        }}
                      >
                        Forgot Password
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        disabled={disButton}
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        sign in
                      </MDButton>
                      {loading && (
                        <CircularProgress
                          size={35}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
      <MDBox>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Login Failed."
          content="Your Email or Password is Incorrect."
          open={showSnackbar}
          // autoHideDuration={3000}
          close={() => setShowSnackbar(false)}
        />
      </MDBox>
    </BasicLayout>
  );
}

export default SignIn;
