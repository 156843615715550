import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useMaterialUIController } from 'context';
import EditProductItemDialog from '../editproductdialog';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DraggableEditItemDialog = ({
  open,
  showClasses,
  businessId,
  noProduct,
  itemListResponse,
  currencySymbol,
  invoiceId,
  editItemId,
  itemList,
  documentsId,
  onClose,
  noPrice,
  itemPriceMismatch,
  noItem,
  noQuantity,
  noUnitProduct,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "570px",
              backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
              color: darkMode ? "#ffffff" : "#7b809a",
            },
          },
        }}
      >
        <DialogTitle
          style={{
            cursor: 'move',
            justifyContent: 'center',
            display: 'flex',
            color: darkMode ? '#ffffff' : '#7b809a',
          }}
          id="draggable-dialog-title"
        >
         Edit Item
        </DialogTitle>
        <DialogContent>
        <EditProductItemDialog
          showClasses={showClasses}
          businessId={businessId}
          noProduct={noProduct}
          itemListResponse={itemListResponse}
          currencySymbol={currencySymbol}
          invoiceId={invoiceId}
          editItemId={editItemId}
          itemList={itemList}
          documentsId={invoiceId}
          onClose={()=>onClose()}
          noPrice={noPrice}
          itemPriceMismatch={itemPriceMismatch}
          noItem={noItem}
          noQuantity={noQuantity}
          noUnitProduct={noUnitProduct}
        />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DraggableEditItemDialog;
