import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { useMaterialUIController } from "context";
import Hoc from "hoc";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { DeleteErrorDocument, GetErrorList, GetStuckList, RetryDocumentUpload, RetryMultipuleDocumentUpload,} from "../../../services/adminservices";
import { useAPICall } from "../../../utils/useapicall";
import Loader from "../accountants/components/imgLoader/loader";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DataTableCheck from "./Table/table";
import InvoiceDetails from "./invoiceDetails";

const cookies = new Cookies();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OcrErrorsList() {
  const action = {
    route: "",
    edittooltip: "Edit",
    deletetooltip: "Delete",
    billingtooltip: "Billing",
    details: "Details",
    download: "Download",
    error: "Error",
    retry: "Retry",
  };
  const [profileInfo, setProfileInfo] = useState(cookies.get("accountant_profile_info"));
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [search, setSearch] = useState("");
  const [showStuckList, setShowStuckList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [tabValue, setTabValue] = useState("isStuck");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [reportComment, setReportComment] = useState("");
  const [showdeleteDialog, setShowDeleteDialog] = useState(false);
  const [showError, setShowError] = useState(false);
  const { pathname } = useLocation();
  const [loaderClass, setLoaderClass] = useState({});
  const [showErrorList, setShowErrorList] = useState(false);
  const [totalerror, setTotalError] = useState("");
  const [totalStuck, setTotalStuck] = useState("");
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [invoiceId, setInvoiceId] = useState("");
  const SelectedTab = localStorage.getItem("TabReportFilter");
  const [issueList, setIssueList] = useState({});
  const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
  const [openErrorDeleteInvoice, setErrorOpenDeleteInvoice] = useState(false);
  const [showRetry, setShowRetry] = useState(false);
  const [selectUseEffect, setSelectUseEffect] = useState(0);
  const [SelectedRows, setSelectedRows] = useState([]);
  const [{ isError: isErrorsError, isLoading: isErrorsLoading, response: Errorsresponse }, CheckErrorListAPICall,
  ] = useAPICall(GetErrorList, { onLoad: false });
  const [{ isError: isStucksError, isLoading: isStucksLoading, response: Stuckresponse },
    CheckStuckListAPICall] = useAPICall(GetStuckList, { onLoad: false });
  const [{ isError: isRetryError, isLoading: isRetryLoading, response: Retryresponse },
    RetryAPICall] = useAPICall(RetryDocumentUpload, { onLoad: false });
  const [{isError: isRetryMultipuleError,isLoading: isRetryMultipuleLoading,response: RetryMultipuleresponse},RetryMultipulAPICall,
  ] = useAPICall(RetryMultipuleDocumentUpload, { onLoad: false });
  const [{ isError: isDeleteError, isLoading: isDeleteLoading, response: DeleteErrorresponse },deleteErrorAPICall,
  ] = useAPICall(DeleteErrorDocument, { onLoad: false });

  const useStyles = makeStyles((theme) => ({
    "@media(max-width: 440px)": {
      tabs: {
        "& button": darkMode
          ? {
              padding: 8,
              paddingLeft: "5px !important",
              paddingRight: "6px !important",
              fontSize: `12px !important`,
              fontWeight: 400,
              textTransform: "none",
              color: "black",
              border: "1px solid gray",
              borderRadius: "1px !important",
            }
          : {
              padding: 8,
              paddingLeft: "5px !important",
              paddingRight: "6px !important",
              fontSize: `12px !important`,
              fontWeight: 400,
              textTransform: "none",
              color: theme.palette.text.primary,
              border: "1px solid gray",
              borderRadius: "1px !important",
            },

        "& button[aria-selected='true']": darkMode
          ? {
              position: "relative",
              color: "#000000 !important",
              border: "0px solid gray",
              background: "#ffffff !important",

              "&:before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                border: "1px solid black",
                zIndex: 0,
              },

              "& > *": { zIndex: 0 },
              "& > .MuiTab-wrapper": {
                background: "#fff",
                height: "100%",
              },
            }
          : {
              position: "relative",
              color: "white !important",
              border: "0px solid gray",
              background: "green",

              "&:before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                border: "1px solid black",
                zIndex: 0,
              },

              "& > *": { zIndex: 0 },
              "& > .MuiTab-wrapper": {
                background: "#fff",
                height: "100%",
              },
            },
      },
    },
    tabs: {
      "& button": darkMode
        ? {
            padding: 8,
            paddingLeft: "40px",
            paddingRight: "40px",
            fontSize: 14,
            fontWeight: 500,
            textTransform: "none",
            color: "white !important",
            border: "1px solid white",
            borderRadius: "1px !important",
          }
        : {
            padding: 8,
            paddingLeft: "40px",
            paddingRight: "40px",
            fontSize: 14,
            fontWeight: 500,
            textTransform: "none",
            color: theme.palette.text.primary,
            border: "1px solid gray",
            borderRadius: "1px !important",
          },

      "& button[aria-selected='true']": darkMode
        ? {
            position: "relative",
            color: "balck !important",
            border: "0px solid gray",
            background: "#237cea",

            "&:before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              border: "1px solid black",
              zIndex: 0,
            },

            "& > *": { zIndex: 0 },
            "& > .MuiTab-wrapper": {
              background: "#202940",
              height: "100%",
            },
          }
        : {
            position: "relative",
            color: "white !important",
            border: "0px solid gray",
            background: "green",

            "&:before": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              border: "1px solid black",
              zIndex: 0,
            },

            "& > *": { zIndex: 0 },
            "& > .MuiTab-wrapper": {
              background: "#fff",
              height: "100%",
            },
          },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (isDeleteLoading) {
      return () => null;
    }
    if (isDeleteError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (DeleteErrorresponse && DeleteErrorresponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setErrorOpenDeleteInvoice(false);
      setShowRetry(false);
      CheckErrorListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });

      // setShowStuckList(data);
      // setLoading(false);
      // setLoadingCircul(false);
    }
  }, [DeleteErrorresponse]);

  useEffect(() => {
    if (isRetryMultipuleLoading) {
      return () => null;
    }
    if (isRetryMultipuleError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (RetryMultipuleresponse && RetryMultipuleresponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setShowRetry(false);
      eventBus.$emit("RetrySuccessFullyComplete", RetryMultipuleresponse);
      CheckStuckListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });

      // setShowStuckList(data);
      // setLoading(false);
      // setLoadingCircul(false);
    }
  }, [RetryMultipuleresponse]);

  useEffect(() => {
    if (isRetryLoading) {
      return () => null;
    }
    if (isRetryError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (Retryresponse && Retryresponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      CheckStuckListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
      // setShowStuckList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [Retryresponse]);

  useEffect(() => {
    if (isStucksLoading) {
      return () => null;
    }
    if (isStucksError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (Stuckresponse && Stuckresponse.data.isSuccess === true) {
      setTotalStuck(Stuckresponse.data.data.totalRecords);
      const data = Stuckresponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop?.createdAt);
        return {
          accountantName:
            prop?.creator?.fname + "" + prop?.creator?.lname
              ? prop?.creator?.fname + " " + prop?.creator?.lname
              : "-",
          clientName: prop?.client?.name ? prop?.client?.name : "-",
          // business: prop?.businesses[0]?.name,
          uploadat: Createdate.toLocaleDateString() ? Createdate.toLocaleDateString() : "-",
          durationhrs: prop?.duration ? prop?.duration : "-",
          action: (
            <MDBox display="flex" justifyContent="center">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.download}
                  placement="top"
                  onClick={(value) => {
                    download(`${prop?.uploadedFileUrl}`, `${getLastPart(prop?.uploadedFileUrl)}`);
                  }}
                >
                  <Icon sx={darkMode ? { color: "#ffffff" } : { color: "#7b809a" }}>download</Icon>
                </Tooltip>
              </MDTypography>
              {/* {prop?.canRetry ?
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "4px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.retry} placement="top" onClick={(value) => {
                                            setDocumentName(getLastPart(prop?.uploadedFileUrl))
                                            setInvoiceId(prop._id);
                                            setRetryId(prop._id);
                                            setShowRetry(true);
                                            // setOpenInvoiceDetails(true);
                                        }}>
                                            <Icon>replay</Icon>
                                        </Tooltip>
                                    </MDTypography> : <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "4px", fontSize: "1.20rem" }}
                                    ><Icon style={{ color: '#ffffff', cursor: 'default' }}>check_box_outline_blank</Icon>
                                    </MDTypography>} */}
            </MDBox>
          ),
          id: prop._id,
        };
      });

      setShowStuckList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [Stuckresponse]);

  useEffect(() => {
    eventBus.$on("invoiceRowSelected", (data) => {
      setSelectUseEffect(data.selectedRowIds.length);
      if (data?.selectedRowIds.length) {
        setSelectedRows(data?.selectedRowIds);
      }
    });
    return () => {
      eventBus.$off("invoiceRowSelected");
    };
  });

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const tt =
      (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10
        ? 0
        : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", Number(tt));
    if (SelectedTab == "isStuck") {
      CheckStuckListAPICall({
        payload: [{ keyword: `${search}`, offset: Number(tt), limit: PerPagelimit }],
      });
    } else {
      CheckErrorListAPICall({
        payload: [{ keyword: `${search}`, offset: Number(tt), limit: PerPagelimit }],
      });
    }
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    if (SelectedTab == "isStuck") {
      CheckStuckListAPICall({
        payload: [{ keyword: `${search}`, offset: 0, limit: data }],
      });
    } else {
      CheckErrorListAPICall({
        payload: [{ keyword: `${search}`, offset: 0, limit: data }],
      });
    }
  }

  useEffect(async () => {
    setLoading(true);
    setLoading1(true);
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await CheckStuckListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
      await CheckErrorListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  function download(url, filename) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  }

  function getLastPart(url) {
    const parts = url.split("/");
    return parts.at(-1);
  }

  useEffect(() => {
    if (isErrorsLoading) {
      return () => null;
    }
    if (isErrorsError) {
      setLoadingCircul(false);
      setLoading(false);
      setLoading1(false);
      return () => null;
    }
    if (Errorsresponse && Errorsresponse.data.isSuccess === true) {
      setTotalError(Errorsresponse.data.data.totalRecords);
      const data = Errorsresponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop?.createdAt);

        return {
          accountantName: prop?.creator?.fname
            ? prop?.creator?.fname + " " + prop?.creator?.lname
            : "-",
          clientName: prop?.client?.name ? prop?.client?.name : "-",
          uploadat: Createdate.toLocaleDateString() ? Createdate.toLocaleDateString() : "-",
          durationhrs: prop?.duration ? prop?.duration : "-",
          erroricon: (
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
            >
              <Tooltip
                title={action.error}
                placement="top"
                onClick={(value) => {
                  setIssueList(prop?.error);
                  setShowErrorList(true);
                }}
              >
                <Icon sx={darkMode ? { color: "#ffffff" } : { color: "#7b809a" }}>error</Icon>
              </Tooltip>
            </MDTypography>
          ),
          action: (
            <MDBox display="flex" justifyContent="center">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.download}
                  placement="top"
                  onClick={(value) => {
                    download(`${prop?.uploadedFileUrl}`, `${getLastPart(prop?.uploadedFileUrl)}`);
                  }}
                >
                  <Icon sx={darkMode ? { color: "#ffffff" } : { color: "#7b809a" }}>download</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
              >
                <Tooltip
                  title={action.details}
                  placement="top"
                  onClick={(value) => {
                    // setBusinessName(prop?.businesses[0].name);
                    setInvoiceId(prop._id);
                    setShowDeleteDialog(true);
                  }}
                >
                  <Icon sx={darkMode ? { color: "#ffffff" } : { color: "#7b809a" }}>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox>
          ),
        };
      });
      setErrorList(data);
      setLoading(false);
      setLoading1(false);
      setLoadingCircul(false);
    }
  }, [Errorsresponse]);

  useEffect(() => {
    setProfileInfo(cookies.get("accountant_profile_info"));
  }, []);

  const dataTableStuckData = {
    columns: [
      {
        Header: "Accountant name",
        accessor: "accountantName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Client name",
        accessor: "clientName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Upload Date",
        accessor: "uploadat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "durationhrs",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      { Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} /> },
    ],

    rows: showStuckList,
  };

  const dataTableErrorData = {
    columns: [
      {
        Header: "Accountant name",
        accessor: "accountantName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Client name",
        accessor: "clientName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Upload Date",
        accessor: "uploadat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Error",
        accessor: "erroricon",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      { Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} /> },
    ],

    rows: errorList,
  };

  const handleSetTabValue = (event, newValue) => {
    localStorage.setItem("offset", 0);
    localStorage.setItem("TabReportFilter", newValue);
    const ThisOffsetuse = localStorage.getItem("offset");
    setTabValue(newValue);
    setLoadingCircul(true);
    if (newValue == "isStuck") {
      CheckStuckListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    } else {
      CheckErrorListAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
    }
  };

  const closeIssuePopup = () => {
    setOpenInvoiceDetails(false);
  };

  const reloadApiAfterClose = () => {
    setOpenInvoiceDetails(false);
  };

  const onChangeReport = (values) => {
    setShowError(false);
    setReportComment(values?.target?.value);
  };

  const handleRetryFun = () => {
    setLoadingCircul(true);
    setShowRetry(false);
    RetryMultipulAPICall({
      payload: [{ documentIds: SelectedRows }],
    });
  };

  const onReportApi = (move) => {
    if (reportComment.length > 0) {
      setShowError(false);
      setErrorOpenDeleteInvoice(false);
      setLoading(true);
      deleteErrorAPICall({
        payload: [{ mesageToUploader: reportComment, documentIds: invoiceId }],
      });
    } else {
      setShowError(true);
    }
  };

  const handleDeleteError = () => {
    setShowDeleteDialog(false);
    setErrorOpenDeleteInvoice(true);
  };

  function generate(element) {
    const jsonString = JSON.stringify(issueList, null, 2);
    return (
      <ListItemText
        style={{ color: darkMode ? "#ffffff" : "#000000" }}
        primary={<pre>{jsonString}</pre>}
      />
    );
  }

  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card sx={loaderClass} style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
          <MDBox display="flex" justifyContent="center">
            {loading1 ? <Loader /> : loading && <Loader />}
          </MDBox>
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium"></MDTypography>
            <MDBox>
              <AppBar position="static">
                <Tabs
                  sx={darkMode && { backgroundColor: "#1a2035 !important" }}
                  className={classes.tabs}
                  value={tabValue}
                  onChange={handleSetTabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label={`Stuck (${totalStuck})`} value="isStuck" />
                  <Tab label={`Error  (${totalerror})`} value="isError" />
                </Tabs>
              </AppBar>
            </MDBox>
            <MDBox px={2} display="flex">
              {selectUseEffect !== 0 ? (
                <MDButton variant="contained" color="info" onClick={() => setShowRetry(true)}>
                  {" "}
                  Retry
                </MDButton>
              ) : (
                <MDButton
                  variant="contained"
                  sx={{
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff !important",
                    color: darkMode ? "#202940 !important" : "#ffffff !important",
                    cursor: "inherit",
                  }}
                >
                  {" "}
                  Retry
                </MDButton>
              )}
            </MDBox>
          </MDBox>
          <MDBox>
            <Dialog
              open={showErrorList}
              onClose={() => setShowErrorList(false)}
              fullWidth={true}
              maxWidth={"lg"}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                  },
                },
              }}
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#7b809a",
                }}
              >
                Error
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Demo style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}>
                      <List
                        sx={{
                          mt: 4,
                          mb: 2,
                          ml: 2,
                          mr: 2,
                          flexBasis: "95%!important",
                          backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                        }}
                      >
                        {generate()}
                      </List>
                    </Demo>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <MDButton
                  sx={
                    darkMode
                      ? {
                          background: "transparent",
                          color: "#ffffff",
                          "&:hover": {
                            background: "transparent",
                            color: "#ffffff",
                          },
                        }
                      : {}
                  }
                  onClick={() => setShowErrorList(false)}
                >
                  Close
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <Dialog
              fullScreen
              open={openInvoiceDetails}
              onClose={() => setOpenInvoiceDetails(false)}
              TransitionComponent={Transition}
            >
              <DialogContent sx={{ padding: "10px 0px 0px 0px" }}>
                <InvoiceDetails
                  invoiceIds={invoiceId}
                  onClickClose={() => closeIssuePopup()}
                  reloadApiAfterClose={reloadApiAfterClose}
                  onClose={() => closeIssuePopup()}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={showRetry}
              onClose={() => setShowRetry(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    // height: "40%",
                    maxWidth: "500px",
                  },
                },
              }}
            >
              <DialogContent>
                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 6, mb: 4 }}>
                  <h3>Are you sure you want to retry?</h3>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "end" }}>
                <MDButton variant="gradient" color="light" onClick={() => setShowRetry(false)}>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" onClick={handleRetryFun}>
                  Yes, retry it!
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={showdeleteDialog}
              onClose={() => setShowDeleteDialog(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "450px",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: "hidden" }}>
                <DialogContentText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  <h3>Are you sure ?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 300,
                      fontSize: "0.90rem",
                      textAlign: "center",
                      mt: 4,
                      color: darkMode ? "#ffffff" : "#7b809a",
                    }}
                  >
                    Once you delete this Document there is no way of getting any of the documents or
                    data back.
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                <MDButton variant="gradient" color="success" onClick={handleDeleteError}>
                  Yes, delete it!
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => setShowDeleteDialog(false)}
                >
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openErrorDeleteInvoice}
              onClose={() => setErrorOpenDeleteInvoice(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    // height: "45%",
                    maxWidth: "450px",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Leave Your Massage
              </DialogTitle>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <textarea
                  style={{
                    width: "100%",
                    height: "110px",
                    padding: "12px 20px",
                    boxSizing: "border-box",
                    border: "1px solid #ccc",
                    borderRadius: "2px",
                    // backgroundColor: "#f8f8f8",
                    fontSize: "16px",
                    resize: "none",
                  }}
                  id="name"
                  required
                  label="Comment"
                  multiline
                  rows={4}
                  placeholder="Leave Your Massage Here..."
                  fullWidth
                  onChange={onChangeReport}
                  type="textarea"
                  name="name"
                />
                {showError && (
                  <MDTypography
                    sx={{ mt: 1 }}
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {" "}
                    Issue is required.
                  </MDTypography>
                )}
              </DialogContent>
              <DialogActions sx={{ justifyContent: "end", mt: 2 }}>
                <MDButton
                  variant="gradient"
                  color="light"
                  onClick={() => setErrorOpenDeleteInvoice(false)}
                >
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" onClick={onReportApi}>
                  Submit
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success"
              // autoHideDuration={3000}
              content="Client Deleted Successfully."
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
          </MDBox>
          <MDBox my={5} sx={{ mt: 1 }}>
            {tabValue == "isStuck" ? (
              <DataTableCheck
                table={dataTableStuckData}
                entriesPerPage={true}
                pagination={true}
                showTotalEntries={true}
                response={totalStuck}
                showCheck={true}
                ClientHeigth={true}
              />
            ) : (
              <DataTable1
                table={dataTableErrorData}
                entriesPerPage={true}
                pagination={true}
                showTotalEntries={true}
                response={totalerror}
                showCheck={false}
                ClientHeigth={true}
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Hoc(OcrErrorsList);
