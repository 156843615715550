import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Modal, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { setPermission, setProfileInfo, setRole, setUserType } from "actions/rolePermission";
import bgImage from "assets/images/backgroundimage.jpg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { setDarkMode, useMaterialUIController } from "context";
import { Form, Formik } from "formik";
import { getUserRole } from "helpers/getUserRole";
import FormField from "layouts/auth/components/Admin/FormField";
import BasicLayout from "layouts/auth/components/BasicLayout";
import initialValues from "layouts/auth/components/schemas/initialValues";
import { SignInSchema } from "layouts/auth/components/schemas/validations";
import { useEffect, useRef, useState } from "react";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetProfile, getAdminVerifyTFAAPI, getreAdminSendTFA } from "services/accountantservices";
import { GetClientUserProfile } from "services/clientservices";
import Cookies from "universal-cookie";
import { GetUserProfile, GetVerifyTFA, UserLogin, getreSendTFA } from "../../../services/userservices";
import { useAPICall } from "../../../utils/useapicall";
const cookies = new Cookies();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height:300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
};

function UserSignIn() {
  const navigate = useNavigate();
  const [accountAccess ,setCanAccessAccount ]= useState(true);
  const [accountClientAccess ,setCanAccountClientAccess ]= useState(true);
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [disButton, setDisButton] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMassge, setShowErrorMassge] = useState("");
  const [alert, setAlert] = useState(false);
  const [showTFA ,setShowTFA] = useState(false);
  const [btnDisable , setBtnDisable] = useState(true);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [controller, dispatch] = useMaterialUIController();
  const reduxDispatch = useDispatch();
  const { darkMode } = controller;
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRefs = useRef([]);
  const [googleToken, setGoogleToken] = useState("");
  const handleClickShowPassword = () => setShowPassword(true);
  const handleClickClosePassword = () => setShowPassword(false);
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(UserLogin, { onLoad: false });
  const [{ isLoading: isGetLoading, isError: isGetError, response: UserprofileResponse },getUserProfileAPICall] = useAPICall(GetUserProfile, { onLoad: false });
  const [{ isLoading: isAdminGetLoading, isError: isAdminGetError, response: profileResponse },getProfileAPICall] = useAPICall(GetProfile, { onLoad: false });
  const [{ isLoading: isClientGetLoading, isError: isClientGetError, response: ClientprofileResponse },getclientProfileAPICall] = useAPICall(GetClientUserProfile, { onLoad: false });
  const [{ isLoading: isVerifyTFALoading, isError: isVeriftTFAError, response: VerifyTFAResponse },getVerifyTFAAPICall] = useAPICall(GetVerifyTFA, { onLoad: false });
  const [{ isLoading: isResendTFALoading, isError: isResendTFAError, response: reSendTFAResponse },getreSendTFAAPICall] = useAPICall(getreSendTFA, { onLoad: false });
  const [{ isLoading: isAdminResendTFALoading, isError: isAdminResendTFAError, response: reSendAdminTFAResponse },getAdminreSendTFAAPICall] = useAPICall(getreAdminSendTFA, { onLoad: false });
  const [{ isLoading: isAdminVerifyTFALoading, isError: isAdminVeriftTFAError, response: VerifyAdminTFAResponse },getAdminVerifyTFAAPICall] = useAPICall(getAdminVerifyTFAAPI, { onLoad: false });

  useEffect(async () => {
    if (isAdminVerifyTFALoading) {
      return () => null;
    }

    if (isAdminVeriftTFAError) {
      setShowErrorMassge('Invalid Verification Code');
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setBtnLoading(false);
      setDisButton(false);
      return () => null;
    }

    if (VerifyAdminTFAResponse && VerifyAdminTFAResponse.data.isSuccess === true) {
      setDisButton(false);
      setShowTFA(false);
      setBtnLoading(false);
      if(VerifyAdminTFAResponse?.data?.data?.tokenType == 'Auth'){
        switch (VerifyAdminTFAResponse?.data?.data?.userType) {
          case "admin":
            localStorage.setItem("AdminUserType",response?.data?.data?.userType)
            cookies.set("admin_access_token", VerifyAdminTFAResponse?.data?.data?.token?.access_token, { path: "/" });
            await getProfileAPICall({ payload: [] });
            break;

          case "accountant":
            cookies.set("user_access_token", VerifyAdminTFAResponse?.data?.data?.token?.access_token, { path: "/" });
            await getclientProfileAPICall({ payload: [] });
            break;

          case "client":
            cookies.set("user_access_token", VerifyAdminTFAResponse?.data?.data?.token?.access_token, { path: "/" });
            await getclientProfileAPICall({ payload: [] });
            break;
          
          default:
            break;
        }
      }
    }
  }, [VerifyAdminTFAResponse]);

  useEffect(async () => {
    if (isAdminResendTFALoading) {
      return () => null;
    }

    if (isAdminResendTFAError) {
      setLoading(false);
      return () => null;
    }

    if (reSendAdminTFAResponse && reSendAdminTFAResponse.data.isSuccess === true) {
      setLoading(false);
    }
  }, [reSendAdminTFAResponse]);

  useEffect(() => {
    if (isAdminGetLoading) {
      return () => null;
    }

    if (isAdminGetError) {
      return () => null;
    }

    if (profileResponse && profileResponse.data.isSuccess === true) {
      setDarkMode(dispatch, profileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem("PaynthrAdminUiThemeMode",profileResponse?.data?.data?.user?.themeColor);
      reduxDispatch(setProfileInfo(profileResponse.data.data));
      cookies.set("admin_profile_info", profileResponse.data.data, { path: "/" });
      if (
        profileResponse.data.data &&
        profileResponse.data.data.user &&
        profileResponse.data.data.user.userType &&
        profileResponse.data.data.user.userType != ""
      ) {
        reduxDispatch(setRole(profileResponse.data.data.user.userType));
        if (
          profileResponse.data.data.user &&
          profileResponse.data.data.user.permissions &&
          profileResponse.data.data.user.permissions[0] &&
          profileResponse.data.data.user.permissions[0].role &&
          profileResponse.data.data.user.permissions[0].role.grants
        ) {
          const adminPermission = profileResponse.data.data.user.permissions[0].role.grants;
          reduxDispatch(setPermission(adminPermission));
          cookies.set("permissions", adminPermission, { path: "/" });
        }
      }
      navigate("/admin/client/list");
    }
  }, [profileResponse]);

  useEffect(async () => {
    if (isResendTFALoading) {
      return () => null;
    }

    if (isResendTFAError) {
      setLoading(false);
      return () => null;
    }

    if (reSendTFAResponse && reSendTFAResponse.data.isSuccess === true) {
      setLoading(false);
    }
  }, [reSendTFAResponse]);

  useEffect(async () => {
    if (isVerifyTFALoading) {
      return () => null;
    }

    if (isVeriftTFAError) {
      setShowErrorMassge('Invalid Verification Code');
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setBtnLoading(false);
      setDisButton(false);
      return () => null;
    }

    if (VerifyTFAResponse && VerifyTFAResponse.data.isSuccess === true) {
      setDisButton(false);
      setShowTFA(false);
      setBtnLoading(false);
      if(VerifyTFAResponse?.data?.data?.tokenType == 'Auth'){
        switch (VerifyTFAResponse?.data?.data?.userType) {
          case "admin":
            cookies.set("admin_access_token", VerifyTFAResponse?.data?.data?.token?.access_token, { path: "/" });
            await getProfileAPICall({ payload: [] });
            break;

          case "accountant":
            cookies.set("user_access_token", VerifyTFAResponse?.data?.data?.token?.access_token, { path: "/" });
            await getclientProfileAPICall({ payload: [] });
            break;

          case "client":
            cookies.set("user_access_token", VerifyTFAResponse?.data?.data?.token?.access_token, { path: "/" });
            await getclientProfileAPICall({ payload: [] });
            break;
          
          default:
            break;
        }
      }
    }
  }, [VerifyTFAResponse]);

  const onRecaptchaLoad = () => {
    setBtnDisable(false); // Enable the button when recaptcha is ready
    setTimeout(() => {
      setBtnDisable(false);
    }, 10000);
  };

  useEffect(() => {
    setBtnDisable(true);
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_KEY).then(() => {
      onRecaptchaLoad();
    });
  }, []);

  const checkUserLogin = async (apiAccess) => {
    const existAccess = localStorage.getItem('accountAccess');
    const clientExistAccess = localStorage.getItem('accountClientAccess');
    const userType = response?.data?.data?.userType;
    const isClient = userType == "client";
    const CheckAccess = (apiAccess === true || apiAccess === false) 
      ? apiAccess 
      : isClient 
        ? clientExistAccess 
        : existAccess 
          ? isClient 
            ? clientExistAccess 
            : existAccess 
          : accountAccess; 

    var token = cookies.get("user_access_token");
    if (token) {
      var userRole = await getUserRole();
      if (userRole == "accountant") {      
        setDarkMode(
          dispatch,
          UserprofileResponse?.data?.data?.user?.themeColor == "dark" ? true : false
        );
        localStorage.setItem(
          "PaynthrUiThemeMode",
          UserprofileResponse?.data?.data?.user?.themeColor
        );
        if(CheckAccess == false){
          localStorage.setItem("accountAccess",CheckAccess);
          navigate("/accountants/billing");
        }else{
          localStorage.setItem("accountAccess",true);
          navigate("/accountant/client/list");
        }
      } else if (userRole == "client") {
        const finalPermission = ClientprofileResponse?.data?.data?.user?.permissions;
        if (finalPermission) {
          reduxDispatch(setPermission(finalPermission));
          cookies.set("user_permissions", JSON.stringify(finalPermission), { path: "/" });
          const inventoryPermission = finalPermission?.find((x) => x.category == "inventory");
          const accountantPermission = finalPermission?.find((x) => x.category == "accountant");
          if(CheckAccess == false){
            localStorage.setItem("accountClientAccess",CheckAccess);
            navigate("/client/billing");
          }else if (inventoryPermission) {
            localStorage.setItem("accountClientAccess",true);
            navigate("/client/restaurant/list");
          } else if (accountantPermission) {
            localStorage.setItem("accountClientAccess",true);
            navigate("/client/document/:id");
          } else {
            localStorage.setItem("accountClientAccess",true);
            navigate("/client/user/list");
          }
        }
      }
    } else {
      console.log("no token");
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleCloseModal = () => {
    cookies.remove("user_access_token", { path: "/" });
    cookies.remove("user_permissions", { path: "/" });
    cookies.remove("user_profile_info", { path: "/" });
    cookies.remove("backup_remind_add_client", { path: "/" });
    cookies.remove("remind_add_client", { path: "/" });
    setShowTFA(false);
    setLoading(false);
    setDisButton(false);
  };

  const handleVerify = () => {
    const otpString = otp.join('');
    const otpNumber = parseInt(otp.join(''), 10);
    setBtnLoading(true);
    ReactRecaptcha3.getToken().then(
      (resp) => {
        if(response?.data?.data?.userType == 'admin') {
        getAdminVerifyTFAAPICall({
            payload: [
              {
                tfaCode: otpString,
                siteToken: `${resp}`,
              },
            ],
          });
          setGoogleToken(resp);
        }else{
        getVerifyTFAAPICall({
          payload: [
            {
              tfaCode: otpString,
              siteToken: `${resp}`,
            },
          ],
        });
        setGoogleToken(resp);
      }
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setDisButton(false);
        setShowErrorMassge("Google reCAPTCHA verification failed.");
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
      }
    );
  };

  useEffect(()=>{
  const otpString = otp.join('');
  const length = otpString.length;
    if (length == 6) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  },[otp])

  useEffect(async () => {
    await checkUserLogin(accountAccess);
  }, []);

  useEffect(() => {
    reduxDispatch(setProfileInfo(ClientprofileResponse?.data?.data));
  }, [ClientprofileResponse]);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setDisButton(false);
      setLoading(false);
      if (response?.data?.message) {
        if (response?.code === "BLOCKED") {
          setAlert(true);
        } else {
          setShowErrorMassge(response?.data?.message);
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
          }, 3000);
        }
      }
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      if(response?.data?.data?.tokenType == 'Auth'){
        if(response?.data?.data?.userType == 'admin'){
          localStorage.setItem("AdminUserType",response?.data?.data?.userType)
        }else{
          localStorage.setItem("AdminUserType",null)
        }
        switch (response?.data?.data?.userType) {
          case "admin":
            cookies.set("admin_access_token", response?.data?.data?.token?.access_token, { path: "/" });
            await getProfileAPICall({ payload: [] });
            break;

          case "accountant":
            cookies.set("user_access_token", response?.data?.data?.token?.access_token, { path: "/" });
            await getUserProfileAPICall({ payload: [] });
            break;

          case "client":
            cookies.set("user_access_token", response?.data?.data?.token?.access_token, { path: "/" });
            await getclientProfileAPICall({ payload: [] });
            break;
          
          default:
            break;
        }
      }else{
        if(response.data.data.userType == 'admin'){
          cookies.set("admin_access_token", response?.data?.data?.token?.access_token, { path: "/" });
          setShowTFA(true);
        }else{
          cookies.set("user_access_token", response?.data?.data?.token?.access_token, { path: "/" });
          setShowTFA(true);
        }
      }
    }
  }, [response]);

  useEffect(()=>{
    setCanAccountClientAccess(ClientprofileResponse?.data?.data?.user?.accountAccess);
  },[ClientprofileResponse ]);

  useEffect(()=>{
    setCanAccessAccount(UserprofileResponse?.data?.data?.user?.accountAccess);
  },[UserprofileResponse ])

  useEffect(async () => {
    if (isGetLoading) {
      return () => null;
    }

    if (isGetError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      return () => null;
    }

    if (UserprofileResponse && UserprofileResponse.data.isSuccess === true) {
      setCanAccessAccount(UserprofileResponse?.data?.data?.user?.accountAccess);
      setDarkMode(dispatch,UserprofileResponse?.data?.data?.user?.themeColor == "dark" ? true : false);
      localStorage.setItem("PaynthrUiThemeMode", UserprofileResponse?.data?.data?.user?.themeColor);
      localStorage.setItem("ShowBilling",UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false);
      if (
        UserprofileResponse?.data?.data?.user &&
        UserprofileResponse?.data?.data?.user?.permissions &&
        UserprofileResponse?.data?.data?.user?.permissions
      ) {
        const adminPermission = UserprofileResponse?.data?.data?.user?.permissions;
        await reduxDispatch(setPermission(adminPermission));
        cookies.set("user_permissions", adminPermission, { path: "/" });
        delete UserprofileResponse.data.data.user.permissions;
      }

      if (
        UserprofileResponse?.data?.data &&
        UserprofileResponse?.data?.data?.user &&
        UserprofileResponse?.data?.data?.user?.role &&
        UserprofileResponse?.data?.data?.user?.role?.clientIds
      ) {
        delete UserprofileResponse?.data?.data?.user?.role?.clientIds;
      }

      cookies.set("user_profile_info", UserprofileResponse?.data?.data, { path: "/" });
      await reduxDispatch(setProfileInfo(UserprofileResponse?.data?.data));
      if (
        UserprofileResponse?.data?.data &&
        UserprofileResponse?.data?.data.user &&
        UserprofileResponse?.data?.data.user.userType &&
        UserprofileResponse?.data?.data.user.userType != ""
      ) {
        await reduxDispatch(setRole(UserprofileResponse?.data?.data.user.userType));
        cookies.set("user_role", UserprofileResponse?.data?.data.user.userType, { path: "/" });
        if (UserprofileResponse?.data?.data.user && UserprofileResponse?.data?.data.user.userType) {
          await reduxDispatch(setUserType(UserprofileResponse?.data?.data?.user?.userType));
          cookies.set("user_type", UserprofileResponse?.data?.data?.user?.userType, { path: "/" });
        }
      }

      setTimeout(() => {
        checkUserLogin(UserprofileResponse?.data?.data?.user?.accountAccess);
      }, 200);
    }
  }, [UserprofileResponse]);

  useEffect(() => {
    if (isClientGetLoading) {
      return () => null;
    }

    if (isClientGetError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      return () => null;
    }
    if (ClientprofileResponse && ClientprofileResponse.data.isSuccess === true) {
      setCanAccountClientAccess(ClientprofileResponse?.data?.data?.user?.accountAccess);
      setDarkMode(
        dispatch,
        ClientprofileResponse?.data?.data?.user?.themeColor == "dark" ? true : false
      );
      localStorage.setItem(
        "PaynthrClientUiThemeMode",
        ClientprofileResponse?.data?.data?.user?.themeColor
      );
      cookies.set("user_profile_info", ClientprofileResponse.data.data, { path: "/" });
      reduxDispatch(setProfileInfo(ClientprofileResponse?.data?.data));
      if (
        ClientprofileResponse?.data?.data &&
        ClientprofileResponse?.data?.data?.user &&
        ClientprofileResponse?.data?.data?.user?.userType
      ) {
        reduxDispatch(setRole(ClientprofileResponse?.data?.data?.user?.userType));
        cookies.set("user_role", ClientprofileResponse?.data?.data?.user?.userType, { path: "/" });

        if (
          ClientprofileResponse?.data?.data &&
          ClientprofileResponse?.data?.data?.user &&
          ClientprofileResponse?.data?.data?.user?.userType
        ) {
          reduxDispatch(setUserType(ClientprofileResponse?.data?.data?.user?.userType));
          cookies.set("user_type", ClientprofileResponse?.data?.data?.user?.userType, {
            path: "/",
          });
        }

        if (
          ClientprofileResponse?.data?.data?.user &&
          ClientprofileResponse?.data?.data?.user?.permissions.length !== 0
        ) {
          const ClientPermission = ClientprofileResponse?.data?.data?.user?.permissions;
          reduxDispatch(setPermission(ClientPermission));
          cookies.set("user_permissions", ClientPermission, { path: "/" });
        }
      }

      setTimeout(() => {
        checkUserLogin(ClientprofileResponse?.data?.data?.user?.accountAccess);
      }, 200);
    }
  }, [ClientprofileResponse]);

  const onSubmit = async (values) => {
    setLoading(true);
    setDisButton(true);
    ReactRecaptcha3.getToken().then(
      (resp) => {
        setAPICall({
          payload: [
            {
              email: `${values.email}`,
              password: `${values.password}`,
              siteToken: `${resp}`,
            },
          ],
        });
        setGoogleToken(resp);
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setDisButton(false);
        setShowErrorMassge("Google reCAPTCHA verification failed.");
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
      }
    );
  };

  const hideAlert = () => {
    setAlert(false);
  };

  const handleClick = () => {
    if (!isDisabled) {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 30000);
    }
    setLoading(true);
    ReactRecaptcha3.getToken().then(
      (resp) => {
        if(response?.data?.data?.userType == 'admin') {
          getAdminreSendTFAAPICall({
            payload: [{siteToken: `${resp}`}],
          });
        }else{
          getreSendTFAAPICall({
            payload: [{siteToken: `${resp}`}],
          });
        }
        setGoogleToken(resp);
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setDisButton(false);
        setShowErrorMassge("Google reCAPTCHA verification failed.");
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
      }
    );
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          sx={{
            backgroundColor: "#80D6C3",
          }}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Welcome to Paynthr
          </MDTypography>
        </MDBox>
        <Formik initialValues={initialValues} validationSchema={SignInSchema} onSubmit={onSubmit}>
          {({ values, errors, touched, isSubmitting }) => (
            <Form autoComplete="on">
              <div>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox mb={2}>
                    <MDBox mb={2}>
                      <FormField type="email" label="Email" name="email" />
                    </MDBox>
                    <MDBox mb={2}>
                      <FormField
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        name="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton sx={{ fontSize: "0.9rem" , 
                                '& .MuiSvgIcon-root': {
                                    color: '#757575 !important', // Custom arrow icon color for dark mode
                                }}}>
                                {!showPassword ? (
                                  <VisibilityOff sx={{color:'#757575'}} onClick={handleClickShowPassword} />
                                ) : (
                                  <Visibility sx={{color:'#757575'}} onClick={handleClickClosePassword} />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        component={Link}
                        to="/reset-password"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{
                          cursor: "pointer",
                          float: "right",
                          padding: " 0px 0px 40px ",
                        }}
                      >
                        Forgot Password
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        disabled={disButton}
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth
                      >
                        sign in
                      </MDButton>
                      {loading && (
                        <CircularProgress
                          size={35}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
      <MDBox>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Login Failed."
          content={showErrorMassge ? showErrorMassge : "Your Email or Password is Incorrect"}
          open={showSnackbar}
          onClose={() => setShowSnackbar(false)}
        />
      </MDBox>
      <Modal open={showTFA} onClose={handleCloseModal}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom align="center">
            Verify
          </Typography>
          <Typography variant="body2" align="center" gutterBottom>
            Your code was sent to you via email
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            mt={2}
            mb={2}
          >
            {otp.map((data, index) => {
              return (
                <TextField
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e, index)}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  sx={{
                    mx: 1,
                    width: '40px',
                    '& input': {
                      textAlign: 'center',
                    },
                  }}
                  inputProps={{
                    maxLength: 1,
                  }}
                />
              );
            })}
          </Box>
          <MDBox sx={{display:'flex' , justifyContent:'center' }}>
          <Button onClick={handleVerify} disabled={btnDisable} variant="contained"  style={{ backgroundColor: '#1A73E8', color: 'white' }}>
            {btnloading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Verify'}
          </Button>
          </MDBox>
          <Typography variant="body2" align="center" mt={2}>
            Didn't receive code?{' '}
            <Box
            component="span"
            onClick={handleClick}
            sx={{
              color: isDisabled ? 'gray' : 'blue',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              textDecoration: isDisabled ? 'none' : 'underline',
            }}
          >
            Request again
          </Box>
        </Typography>
        </Box>
      </Modal>
      <MDBox>
        <Dialog
          open={alert}
          onClose={hideAlert}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move", color: "error" }} id="draggable-dialog-title">
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your account has been deactivated. If this is a mistake contact Paynthr support team
              (support@paynthr.com)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={hideAlert} variant="gradient" color="info">
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </BasicLayout>
  );
}

export default UserSignIn;
