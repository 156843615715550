import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif"
import MDBox from "components/MDBox"

export default function Loader() {
    return (
        <MDBox sx={{
            position: 'absolute',
            '@media (max-width: 1920px)': {
                top: '25%',
                zIndex: 99999,
                transform: `translateX(-8%)`,
            },
            '@media (max-width: 1320px)': {
                top: '15%',
                zIndex: 99999,
                transform: `translateX(-8%)`,
            },
            '@media (max-width: 1140px)': {
                top: '15%',
                zIndex: 99999,
                transform: `translateX(0%)`,
            },
            '@media (max-width: 960px)': {
                top: '15%',
                zIndex: 99999,
                transform: `translateX(0%)`,
            },
            '@media (max-width: 720px)': {
                top: '15%',
                zIndex: 99999,
                transform: `translateX(0%)`,
            },
            '@media (max-width: 540px)': {
                top: '15%',
                zIndex: 99999,
                transform: `translateX(0%)`,
            },
        }}>
            <img src={LoaderPng} width="600" height='auto' />
        </MDBox>
    )
}