import * as Yup from "yup";

export const EditAddParentsCategorySchema = Yup.object().shape({
    category: Yup.string()
        .trim()
        .required('Name is required.')
        .min(3, 'Name should be more than 3 characters.'),
    // .min(3, 'Category  name should be more than 3 characters.'),
    accountType: Yup.string()
        .trim()
        .required('Account Type is required.'),
    accountSubType: Yup.string()
        .trim()
        .required('Account SubType is required.'),
    ParentAccount: Yup.string()
        .trim()
        .required('Please Select Parent Account'),
      
    // .min(3, 'Category  name should be more than 3 characters.')
});