import { Autocomplete, FormControl, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import UserHoc from "hoc/userHoc";
import FormField from "layouts/admin/accountants/components/FormField";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import * as Yup from "yup";
import { AddNewRestaurantApi, GetuserClientList } from "../../../services/clientservices";

function AddNewRestaurant({ onClose }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarErrorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState(cookies.get("user_profile_info"));
  const [clientList, setClientList] = useState([]);
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [clientNewValue, setclientNewValue] = useState("");
  const [showadress, setShowadress] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [
    { isLoading: isCreateLoading, isError: isCreateError, response: CreateResponse },
    CreateAPICall,
  ] = useAPICall(AddNewRestaurantApi, { onLoad: false });
  const [
    { isLoading: isGetLoading, isError: isGetError, response: clientUserDataResponse },
    getClientAPICall,
  ] = useAPICall(GetuserClientList, { onLoad: false });

  useEffect(() => {
    getClientAPICall({
      payload: [{ keyword: ``, businessView: "Client" }],
    });
  }, []);

  useEffect(() => {
    if (isGetLoading) {
      return () => null;
    }

    if (isGetError) {
      setBtnDisable1(false);
      return () => null;
    }

    if (clientUserDataResponse && clientUserDataResponse.data.isSuccess === true) {
      setClientList(clientUserDataResponse?.data?.data?.records);
      if (clientUserDataResponse?.data?.data?.records.length === 1) {
        setclientNewValue(clientUserDataResponse?.data?.data?.records[0]?.name);
        setClientId(clientUserDataResponse?.data?.data?.records[0]?._id);
      }
    }
  }, [clientUserDataResponse]);

  const initialValues = {
    clientname: "",
    restaurantname: "",
    address: "",
    address2: "",
    state: "",
    city: "",
    zip_code: "",
  };

  useEffect(() => {
    if (isCreateLoading) {
      return () => null;
    }

    if (isCreateError) {
      eventBus.$emit("FailedToRestaurants", isCreateError);
      setBtnDisable1(false);
      setLoadingCircul(false);
      return () => null;
    }

    if (CreateResponse && CreateResponse.data.isSuccess === true) {
      eventBus.$emit("IsSuccessToRestaurants", CreateResponse);
      setBtnDisable1(false);
      setLoadingCircul(false);
      // navigate('/client/restaurant/list');
      onClose();
    }
  }, [CreateResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable1(true);
    setLoadingCircul(true);
    await CreateAPICall({
      payload: [
        {
          name: values?.restaurantname,
          address: {
            line1: values?.address,
            line2: values?.address2,
            city: values?.city,
            state: values?.state,
            zip: values?.zip_code,
          },
          businessId: clientId,
          // "allClient": true,
        },
      ],
    });
    setBtnDisable1(true);
  };

  const handleClientValueChange = (event, newValue) => {
    setclientNewValue(newValue);
    const foundId = clientList.find((obj) => {
      return obj.name === newValue;
    });
    if (foundId) {
      setClientId(foundId?._id);
    }
  };

  const handleChange = () => {
    setShowadress(!showadress);
  };

  return (
    <MDBox mt={0}>
      <Grid spacing={3}>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={
              showadress
                ? Yup.object().shape({
                    clientname: !clientNewValue
                      ? Yup.string()
                          .trim()
                          .required("Client name is required.")
                          .min(3, "Client name should be more than 3 characters.")
                      : "",
                    restaurantname: Yup.string()
                      .trim()
                      .required("Restaurant name is required.")
                      .min(3, "Restaurant name should be more than 3 characters."),
                    address: Yup.string()
                      .trim()
                      .required("Address 1 is required.")
                      .min(3, "Address 1 should be more than 3 characters."),
                    zip_code: Yup.string()
                      .matches(
                        /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                        "Zip code must be a number type"
                      )
                      .nullable()
                      .min(5, "Zip code must be 5 characters.")
                      .max(5, "Zip code must be 5 characters."),
                  })
                : Yup.object().shape({
                    clientname: !clientNewValue
                      ? Yup.string()
                          .trim()
                          .required("Client name is required.")
                          .min(3, "Client name should be more than 3 characters.")
                      : "",
                    restaurantname: Yup.string()
                      .trim()
                      .required("Restaurant name is required.")
                      .min(3, "Restaurant name should be more than 3 characters."),
                    zip_code: Yup.string()
                      .matches(
                        /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
                        "Zip code must be a number type"
                      )
                      .nullable()
                      .min(5, "Zip code must be 5 characters.")
                      .max(5, "Zip code must be 5 characters."),
                  })
            }
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox p={3}>
                  <MDBox>
                    <MDBox>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <FormField type="text" label="Restaurant Name *" name="restaurantname" />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <FormControl variant="standard" fullWidth sx={{ mb: 1 }}>
                            <Autocomplete
                              sx={
                                darkMode
                                  ? {
                                    "& .MuiAutocomplete-clearIndicator": {
                                        color: "#FFFFFF", // Change color of clear (X) icon in dark mode
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#FFFFFF", // Change color of dropdown arrow in dark mode
                                      },
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                      },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                        {
                                          color: "#ffffff !important",
                                        },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                        {
                                          color: "#ffffff !important",
                                        },
                                    }
                                  : {
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                    }
                              }
                              disablePortal
                              name="location"
                              id="combo-box-demo"
                              ListboxProps={{
                                style: { maxHeight: "250px" },
                                position: "bottom-start",
                              }}
                              value={{ name: clientNewValue }}
                              options={clientList}
                              getOptionLabel={(option) => option.name}
                              onInputChange={(event, newInputValue) => {
                                handleClientValueChange(event, newInputValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Business Name *"
                                  name="clientname"
                                  variant="standard"
                                  sx={
                                    darkMode
                                      ? {
                                          "& .MuiFormLabel-root": {
                                            marginTop: "5px",
                                            color: "#ffffff",
                                          },
                                          "& .MuiInput-root .MuiInput-input": {
                                            color: "#FFFFFF",
                                          },
                                        }
                                      : {
                                          "& .MuiFormLabel-root": {
                                            marginTop: "5px",
                                            color: "#7b809a !important",
                                          },
                                        }
                                  }
                                />
                              )}
                            />
                            {!clientNewValue && (
                              <MDBox mt={0.75}>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  <ErrorMessage name="clientname" />
                                </MDTypography>
                              </MDBox>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDBox>
                            <Field
                              as={FormControlLabel}
                              type="checkbox"
                              name="address"
                              control={<Checkbox />}
                              label="Restaurant Address"
                              checked={showadress}
                              onChange={handleChange}
                              sx={{
                                mt: 1.5,
                                "& .MuiTypography-root": {
                                  color: darkMode ? "#ffffff" : "#000000",
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                      {showadress && (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                              <FormField type="text" label="Address 1 *" name="address" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Address 2" name="address2" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="State" name="state" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="City" name="city" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField type="text" label="Zip Code" name="zip_code" />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </MDBox>
                    <MDBox mt={3} mb={-3} width="100%" display="flex" justifyContent="end">
                      {!isMobile ? (
                        <MDButton
                          variant="gradient"
                          color="light"
                          onClick={onClose}
                          // component={Link}
                          // to="/client/restaurant/list"
                        >
                          Cancel
                        </MDButton>
                      ) : (
                        <MDButton
                          sx={{
                            marginTop: "0px!important",
                            "@media(max-width:575px)": {
                              marginTop: "8px!important",
                            },
                          }}
                          variant="gradient"
                          color="light"
                          onClick={onClose}
                          // component={Link}
                          // to="/client/restaurant/list"
                        >
                          Cancel
                        </MDButton>
                      )}

                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          sx={{ marginLeft: "4px!important" }}
                          type="submit"
                          variant="gradient"
                          color="info"
                          disabled={btnDisable1}
                        >
                          {btnDisable1 ? "creating..." : "create"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <MDBox>
        {loadingCircul && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
      <MDBox>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed."
          // autoHideDuration={3000}
          content={SnackbarErrorMessage}
          // content="Somthing Went to Wrong"
          open={showErrorSnackbar}
          close={() => setShowErrorSnackbar(false)}
        />
      </MDBox>
    </MDBox>
  );
}

export default UserHoc(AddNewRestaurant);
