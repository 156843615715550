import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useMaterialUIController, setLayout } from "context";
import theme from "assets/theme";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const {darkMode , miniSidenav } = controller;
  const { pathname } = useLocation();
  const { palette} = theme;
  const {  white, dark } = palette;

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <div style={{minHeight: '100vh' , maxHeight:'200vh', background: darkMode ? '#1a2035' : white.state }}>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          background: darkMode ? '#1a2035' : white.state,
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    </div>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
