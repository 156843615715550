import { TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { User, UserA, UserM, UserU } from "helpers/getUser";
import { getUserPermissions } from "helpers/getUserPermissions";
import UserHoc from "hoc/userHoc";
import FormField from "layouts/admin/accountants/components/FormField";
import { AddNewSchema } from "layouts/clientuser/user/components/schemas/addnewvalidations";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Link, useNavigate } from "react-router-dom";
import {
  EditClientUserInfo,
  GetClientUserRolescope,
  GetClientdetails,
} from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../clientuser/user/components/Navbars/DashboardNavbar";

function EditUsers() {
  const navigate = useNavigate();
  const permission = getUserPermissions();
  const [showFeature, setShowFeature] = useState("");
  const [showRole, setShowRole] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [profileEmail, setprofileEmail] = useState("");
  const [profileFname, setprofileFname] = useState("");
  const [profileLname, setprofileLname] = useState("");
  const [profileRole, setprofileRole] = useState("");
  const [featuresRole, setFeaturesRole] = useState("");
  const [profileBuinessName, setprofileBusinessName] = useState("");
  const [profileNumber, setprofileNumber] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [adminRoleId, setAdminRoleId] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [clientRoleId, setclientRoleId] = useState("");
  const [activeUser, setActiveUser] = useState(false);
  const [ownerRoleId, setOwnerRoleId] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [bussinessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [
    { isLoading: isGetLoading, isError: isGetError, response: clientDataResponse },
    getUserAPICall,
  ] = useAPICall(GetClientdetails, { onLoad: false });
  const [
    { isLoading: isRoleLoading, isError: isRoleError, response: roleResponse },
    getRoleAPICall,
  ] = useAPICall(GetClientUserRolescope, { onLoad: false });
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditClientUserInfo, {
    onLoad: false,
  });
  const [
    { isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse },
    UserStatusAPICall,
  ] = useAPICall(EditClientUserInfo, { onLoad: false });

  useEffect(async () => {
    const getInvCategories = () => {
      const arr = permission;
      return arr.filter(
        (obj, index, self) => index === self.findIndex((o) => o.category === "inventory")
      );
    };
    const getAccCategories = () => {
      const arr = permission;
      return arr.filter(
        (obj, index, self) => index === self.findIndex((o) => o.category === "accounting")
      );
    };

    const getUserMangerRole = () => {
      const arr = permission;
      return arr.filter(
        (obj, index, self) => index === self.findIndex((o) => o.role === "manager")
      );
    };

    const getUserClientRole = () => {
      const arr = permission;
      return arr.filter((obj, index, self) => index === self.findIndex((o) => o.role === "user"));
    };

    const getUserAdminRole = () => {
      const arr = permission;
      return arr.filter((obj, index, self) => index === self.findIndex((o) => o.role === "admin"));
    };

    const getUserOwnerRole = () => {
      const arr = permission;
      return arr.filter((obj, index, self) => index === self.findIndex((o) => o.role === "owner"));
    };
    const isAdmin = getUserAdminRole();
    const isOwner = getUserOwnerRole();
    const isManager = getUserMangerRole();
    const isClient = getUserClientRole();

    if (isOwner.length !== 0) {
      setShowRole("owner");
    }
    if (isAdmin.length !== 0) {
      setShowRole("admin");
    } else if (isManager.length !== 0) {
      setShowRole("manager");
    } else if (isClient.length !== 0) {
      setShowRole("user");
    }
    const finalInventoryPermision = getInvCategories();
    const finalAccounatntPermision = getAccCategories();
    if (finalInventoryPermision.length !== 0 && finalAccounatntPermision.length !== 0) {
      setShowFeature("Both");
    } else if (finalAccounatntPermision.length !== 0) {
      setShowFeature("accounting");
    } else if (finalInventoryPermision.length !== 0) {
      setShowFeature("inventory");
    }
    await getRoleAPICall({
      payload: [],
    });
    setShowForm(false);
    await getUserAPICall({
      payload: [{ bussinessId: `${bussinessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isUserStatusLoading) {
      return () => null;
    }

    if (isUserStatusError) {
      response?.code === "DATA401" ? navigate("/client/logout") : console.log("somting else");
      return () => null;
    }

    if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {
    }
  }, [UserStatusResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      response?.code === "DATA401" ? navigate("/client/logout") : console.log("somting else");
      setIsDisable(false);
      setShowErrorSnackbar(true);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSnackbar(true);
      setIsDisable(false);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    }
  }, [response]);

  useEffect(() => {
    if (isGetLoading) {
      return () => null;
    }

    if (isGetError) {
      return () => null;
    }

    if (clientDataResponse && clientDataResponse.data.isSuccess === true) {
      const adminresult = clientDataResponse?.data?.data?.employee;
      setprofileEmail(adminresult?.email);
      setprofileFname(adminresult?.fname);
      setprofileLname(adminresult?.lname);
      setprofileNumber(adminresult?.phone);
      setPhone(adminresult?.phone);
      setprofileRole(adminresult?.permissions[0].role);
      setShowForm(true);
      setActiveUser(adminresult?.isBlocked);
      const Accountingpermission = adminresult?.permissions.find(
        (p) => p.category === "accounting"
      );
      const Inventorypermission = adminresult?.permissions.find((p) => p.category === "inventory");
      if (Accountingpermission && Inventorypermission) {
        setFeaturesRole("both");
      } else if (Accountingpermission) {
        setFeaturesRole("accounting");
      } else if (Inventorypermission) {
        setFeaturesRole("inventory");
      }
    }
  }, [clientDataResponse]);

  useEffect(() => {
    if (isRoleLoading) {
      return () => null;
    }

    if (isRoleError) {
      return () => null;
    }

    if (roleResponse && roleResponse.data.isSuccess === true) {
      
    }
  }, [roleResponse]);

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    clientname: profileBuinessName,
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: profileRole,
    features: featuresRole,
  };

  const sidenavItems = [
    { icon: "person", label: "profile", href: "profile" },
    { icon: "receipt_long", label: "basic info", href: "basic-info" },
  ];

  const handleSubmit = async (values) => {
    setIsDisable(true);
    if (selectedRole == "admin" && showFeature === "Both") {
      await setAPICall({
        payload: [
          {
            phone: phone,
            fname: `${values.firstname}`,
            lname: `${values.lastname}`,
            bussinessId: `${bussinessId}`,
            isBlocked: activeUser,
            permissions: [
              {
                category: "inventory",
                role: "admin",
                allScopes: true,
              },
              {
                category: "accounting",
                role: "admin",
                allScopes: true,
              },
            ],
          },
        ],
      });
    } else if (selectedRole == "admin" && showFeature === "accounting") {
      await setAPICall({
        payload: [
          {
            phone: phone,
            fname: `${values.firstname}`,
            lname: `${values.lastname}`,
            bussinessId: `${bussinessId}`,
            isBlocked: activeUser,
            permissions: [
              {
                category: "accounting",
                role: "admin",
                allScopes: true,
              },
            ],
          },
        ],
      });
    } else if (selectedRole == "admin" && showFeature === "inventory") {
      await setAPICall({
        payload: [
          {
            phone: phone,
            fname: `${values.firstname}`,
            lname: `${values.lastname}`,
            bussinessId: `${bussinessId}`,
            isBlocked: activeUser,
            permissions: [
              {
                category: "inventory",
                role: "admin",
                allScopes: true,
              },
            ],
          },
        ],
      });
    } else if (showFeature === "accounting") {
      await setAPICall({
        payload: [
          {
            phone: phone,
            fname: `${values.firstname}`,
            lname: `${values.lastname}`,
            bussinessId: `${bussinessId}`,
            isBlocked: activeUser,
            permissions: [
              {
                category: "accounting",
                role: selectedRole,
                allScopes: true,
              },
            ],
          },
        ],
      });
    } else if (showFeature === "inventory") {
      await setAPICall({
        payload: [
          {
            phone: phone,
            fname: `${values.firstname}`,
            lname: `${values.lastname}`,
            bussinessId: `${bussinessId}`,
            isBlocked: activeUser,
            permissions: [
              {
                category: "inventory",
                role: selectedRole,
                allScopes: true,
              },
            ],
          },
        ],
      });
    } else if (showFeature === "Both") {
      await setAPICall({
        payload: [
          {
            phone: phone,
            fname: `${values.firstname}`,
            lname: `${values.lastname}`,
            bussinessId: `${bussinessId}`,
            isBlocked: activeUser,
            permissions: [
              {
                category: selectedFeatures,
                role: selectedRole,
                allScopes: true,
              },
            ],
          },
        ],
      });
    }
    // if (values.role == 'admin' || selectedFeatures == 'both') {
    //     await setAPICall({
    //         payload: [
    //             {
    //                 "phone": phone,
    //                 "fname": `${values.firstname}`,
    //                 "lname": `${values.lastname}`,
    //                 "bussinessId": `${bussinessId}`,
    //                 "isBlocked": activeUser,
    //                 "permissions": [
    //                     {
    //                         "category": "inventory",
    //                         "role": values.role,
    //                         "allScopes": true
    //                     },
    //                     {
    //                         "category": "accounting",
    //                         "role": values.role,
    //                         "allScopes": true
    //                     }
    //                 ]
    //             }
    //         ]
    //     })
    // } else {
    //     await setAPICall({
    //         payload: [
    //             {
    //                 "phone": phone,
    //                 "fname": `${values.firstname}`,
    //                 "lname": `${values.lastname}`,
    //                 "isBlocked": activeUser,
    //                 "bussinessId": `${bussinessId}`,
    //                 "permissions": [
    //                     {
    //                         "category": selectedFeatures,
    //                         "role": values.role,
    //                         "allScopes": true
    //                     }
    //                 ]
    //             }
    //         ]
    //     })
    // }
  };

  const ToggleChange = async () => {
    setActiveUser(!activeUser);
  };

  return (
    <>
      {showForm ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="basic-info" sx={{ overflow: "visible" }}>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={AddNewSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                              <Card
                                sx={{
                                  height: "100%",
                                  backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                                }}
                              >
                                <MDBox p={3}>
                                  <MDBox>
                                    <MDBox lineHeight={0}>
                                      <MDTypography variant="h5">Edit User</MDTypography>
                                    </MDBox>
                                    <MDBox mt={1.625}>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="First Name *"
                                            name="firstname"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="Last Name *"
                                            name="lastname"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormField
                                          type="email"
                                          label="Email"
                                          name="email"
                                          disabled
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <NumberFormat
                                          customInput={TextField}
                                          variant="standard"
                                          type="tel"
                                          fullWidth
                                          value={phone}
                                          label="Phone Number"
                                          InputLabelProps={{
                                            style: darkMode ? { color: "#ffffff" } : {},
                                          }}
                                          InputProps={{
                                            style: darkMode ? { color: "#ffffff",borderBottom:"2px solid" } : {},
                                          }}
                                          sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                              WebkitTextFillColor: "black",
                                            },
                                            mb: 1,
                                            fontSize: `0.823rem !important`,
                                          }}
                                          format="(###) ###-####"
                                          mask=""
                                          onValueChange={(value) => setPhone(value.formattedValue)}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
                                          <InputLabel
                                            sx={
                                              darkMode ? { mt: -3, color: "#ffffff" } : { mt: -3 }
                                            }
                                          >
                                            Role *
                                          </InputLabel>
                                          <Field
                                            as="select"
                                            name="role"
                                            variant="standard"
                                            style={{
                                              width:"100%",
                                              borderRadius : '0px',
                                              marginTop: "18px",
                                              marginBottom: "10px",
                                              borderTop: "0px",
                                              borderLeft: "0px",
                                              borderRight: "0px",
                                              borderBottom: darkMode ? "2px solid" : "",
                                              color: darkMode ? "white" : "slategray",
                                              backgroundColor: darkMode ? "#202940" : "transparent",
                                              "&:focus": {
                                                outline: "none",
                                              },
                                            }}
                                          >
                                            <option value="">Select Role</option>
                                            {showRole == "owner" &&
                                              Object.keys(User).map((key) => (
                                                <option value={key}>{User[key]}</option>
                                              ))}

                                            {showRole == "admin" &&
                                              Object.keys(UserA).map((key) => (
                                                <option value={key}>{UserA[key]}</option>
                                              ))}

                                            {showRole == "manager" &&
                                              Object.keys(UserM).map((key) => (
                                                <option value={key}>{UserM[key]}</option>
                                              ))}

                                            {showRole == "user" &&
                                              Object.keys(UserU).map((key) => (
                                                <option value={key}>{UserU[key]}</option>
                                              ))}
                                          </Field>
                                          {setSelectedRole(values.role)}
                                          <>
                                            {values.role == "admin" ? (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="info"
                                                fontWeight="regular"
                                              >
                                                This user will have access to the entire Paynthr
                                                account and be able to add/edit users.
                                              </MDTypography>
                                            ) : (
                                              ""
                                            )}
                                            {values.role == "manager" ? (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="info"
                                                fontWeight="regular"
                                              >
                                                This user will have access to the entire account
                                                except the billing page and be able to add/edit
                                                users
                                              </MDTypography>
                                            ) : (
                                              ""
                                            )}
                                            {values.role == "user" ? (
                                              <MDTypography
                                                component="div"
                                                variant="caption"
                                                color="info"
                                                fontWeight="regular"
                                              >
                                                This user will have only have access to the
                                                Documents & Inventory page
                                              </MDTypography>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                          <MDBox mt={0.75}>
                                            <MDTypography
                                              component="div"
                                              variant="caption"
                                              color="error"
                                              fontWeight="regular"
                                            >
                                              <ErrorMessage name="role" />
                                            </MDTypography>
                                          </MDBox>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {selectedRole !== "" &&
                                          selectedRole !== "admin" &&
                                          showFeature == "Both" && (
                                            <FormControl
                                              fullWidth
                                              variant="standard"
                                              sx={{ mt: 1.5 }}
                                            >
                                              <InputLabel sx={{ mt: -3 }}>Features *</InputLabel>
                                              <Field
                                                as="select"
                                                name="features"
                                                variant="standard"
                                                style={{
                                                  marginTop: "18px",
                                                  borderTop: "0px",
                                                  borderLeft: "0px",
                                                  borderRight: "0px",
                                                  color: "slategray",
                                                }}
                                              >
                                                <option value="">Select Features</option>
                                                <option value="inventory">Inventory</option>
                                                <option value="accounting">Accounting</option>
                                                <option value="both">Both</option>
                                              </Field>
                                              <MDBox mt={0.75}>
                                                <MDTypography
                                                  component="div"
                                                  variant="caption"
                                                  color="error"
                                                  fontWeight="regular"
                                                >
                                                  <ErrorMessage name="features" />
                                                </MDTypography>
                                                {setSelectedFeatures(values.features)}
                                              </MDBox>
                                            </FormControl>
                                          )}
                                      </Grid>
                                    </MDBox>
                                    <Grid sx={{ display: "flex" }}>
                                      <Grid item xs={3}>
                                        <MDBox mt={{ xs: 2, sm: 3 }} justifyContent="left">
                                          <Grid sx={{ pr: 11 }}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={!activeUser}
                                                  onChange={ToggleChange}
                                                />
                                              }
                                              label={
                                                <spna style={darkMode ? { color: "#ffffff" } : {}}>
                                                  Is Active
                                                </spna>
                                              }
                                            />
                                          </Grid>
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <MDBox
                                          sx={{ display: "flex" }}
                                          mt={{ xs: 2, sm: 3 }}
                                          justifyContent="end"
                                        >
                                          <MDButton
                                            disabled={isDisable}
                                            variant="gradient"
                                            color="light"
                                            component={Link}
                                            to="/client/user/list"
                                          >
                                            Cancel
                                          </MDButton>
                                          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                            <MDButton
                                              disabled={isDisable}
                                              type="submit"
                                              variant="gradient"
                                              color="info"
                                            >
                                              {isDisable ? "updating..." : "update"}
                                            </MDButton>
                                          </MDBox>
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </Form>
                          )}
                        </Formik>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success."
              content="User Updated Successfully!"
              open={showSnackbar}
              // autoHideDuration={3000}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content="User's Updation failed!"
              open={showErrorSnackbar}
              // autoHideDuration={3000}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </DashboardLayout>
      ) : (
        <DashboardLayout>
          {
            <img
              src={LoaderPng}
              width="600"
              height="auto"
              style={{
                position: "absolute",
                top: "260%",
                bottom: "100%",
                left: "20%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          }
        </DashboardLayout>
      )}
    </>
  );
}

export default UserHoc(EditUsers);
