import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AddNewVendor from "./components/schemas/AddNewVendor";
import AddNewVendorWithoutAddress from "./components/schemas/AddNewVendorWithoutAddress";
import FormField from "layouts/admin/accountants/components/FormField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import { AddVendors, GetVendorList, GetVendorDetails } from "../../../../services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import NumberFormat from "react-number-format";
import { TextField, Typography } from "@mui/material";
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";

function AddVendorDialog({ onClose, invoiceId, businessId, vendorSelect, vendorSelect1 }) {
  const [business_id, setBusiness] = useState(businessId);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trackPayment, setTrackPayment] = useState(false);
  const [billRate, setBillRate] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [address, setAddress] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailId, setEmailId] = useState("");
  const [netTerms, setNetTerms] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [city, setCity] = useState("");
  const [state, setstate] = useState("");
  const [zip, setzip] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [vendor1099, setVendor1099] = useState(false);
  const [
    { isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse },
    VendorListAPICall,
  ] = useAPICall(GetVendorList, { onLoad: false });
  const [
    {
      isLoading: isVendorDetailLoading,
      isError: isVendorDetailError,
      response: VendorDetailResponse,
    },
    VendorDetailsAPICall,
  ] = useAPICall(GetVendorDetails, { onLoad: false });

  useEffect(() => {
    if (isVendorDetailLoading) {
      return () => null;
    }
    if (isVendorDetailError) {
      setLoading(false);
      return () => null;
    }
    if (VendorDetailResponse && VendorDetailResponse.data.isSuccess === true) {
      const finalVendorName = vendorSelect === vendorSelect1;
      setVendorName(
        finalVendorName == false
          ? vendorSelect1
          : VendorDetailResponse?.data?.data?.vendor.name !== "---"
          ? VendorDetailResponse?.data?.data?.vendor.name
          : ""
      );
      setPhone(
        VendorDetailResponse?.data?.data?.vendor.phone !== "---"
          ? VendorDetailResponse?.data?.data?.vendor.phone
          : ""
      );
      setAddress(
        VendorDetailResponse?.data?.data?.vendor?.billingAddress?.line1 !== "---"
          ? VendorDetailResponse?.data?.data?.vendor?.billingAddress?.line1
          : ""
      );
      setCity(
        VendorDetailResponse?.data?.data?.vendor?.billingAddress?.city !== "---"
          ? VendorDetailResponse?.data?.data?.vendor?.billingAddress?.city
          : ""
      );
      setstate(
        VendorDetailResponse?.data?.data?.vendor?.billingAddress?.state !== "---"
          ? VendorDetailResponse?.data?.data?.vendor?.billingAddress?.state
          : ""
      );
      setzip(
        VendorDetailResponse?.data?.data?.vendor?.billingAddress?.zip !== "---"
          ? VendorDetailResponse?.data?.data?.vendor?.billingAddress?.zip
          : ""
      );
      setAccountNo(
        VendorDetailResponse?.data?.data?.vendor.accountNo !== "---"
          ? VendorDetailResponse?.data?.data?.vendor.accountNo
          : ""
      );
      setEmailId(
        VendorDetailResponse?.data?.data?.vendor.email !== "---"
          ? VendorDetailResponse?.data?.data?.vendor.email
          : ""
      );
      setPhone(
        VendorDetailResponse?.data?.data?.vendor.phone !== "---"
          ? VendorDetailResponse?.data?.data?.vendor.phone
          : ""
      );
      setNetTerms(VendorDetailResponse?.data?.data?.vendor.balance);
      setVendor1099(VendorDetailResponse?.data?.data?.vendor?.vendor1099);
      setTrackPayment(
        VendorDetailResponse?.data?.data?.vendor?.billingAddress?.line1 ? true : false
      );
      setBusinessNumber(VendorDetailResponse?.data?.data?.vendor?.businessNumber);
      setBillRate(VendorDetailResponse?.data?.data?.vendor?.billRate);
      setLoading(false);
      setShowDetails(true);
    }
  }, [VendorDetailResponse]);

  useEffect(async () => {
    setLoading(true);
    await VendorListAPICall({
      payload: [{ businessId: `${businessId}`, IsSynced: false }],
    });
  }, []);

  useEffect(() => {
    if (isVendorLoading) {
      return () => null;
    }
    if (isVendorError) {
      setLoading(false);
      return () => null;
    }
    if (VendorResponse && VendorResponse.data.isSuccess === true) {
      const found = VendorResponse?.data?.data?.records.find((obj) => {
        return obj.name === vendorSelect;
      });
      const finalVendorName = vendorSelect === vendorSelect1;
      if (found) {
        VendorDetailsAPICall({
          payload: [{ VendorId: `${found?._id}` }],
        });
      } else {
        setVendorName(finalVendorName == true ? vendorSelect : vendorSelect1);
        setLoading(false);
        setShowDetails(true);
      }
    }
  }, [VendorResponse]);

  let initialValues = {
    vendor: vendorName,
    accountno: accountNo,
    address: address,
    email: emailId,
    phone: phone,
    businessid: "",
    net: netTerms,
    addressfull: trackPayment,
    vendor1099: vendor1099,
    businessNumber: businessNumber,
    billRate: billRate,
    account: "",
    city: city,
    state: state,
    zip: zip,
  };

  const [
    { isLoading: isAddVendorLoading, isError: isAddVendorError, response: AddVendorResponse },
    AddVendorListAPICall,
  ] = useAPICall(AddVendors, { onLoad: false });

  useEffect(() => {
    if (isAddVendorLoading) {
      return () => null;
    }
    if (isAddVendorError) {
      setErrorMessage(AddVendorResponse?.data?.message);
      setLoading(false);
      setBtnDisable(false);
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 3000);
      return () => null;
    }

    if (AddVendorResponse && AddVendorResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      eventBus.$emit("AddnewVendorComplete", AddVendorResponse?.data?.data);
      onClose();
    }
  }, [AddVendorResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    trackPayment
      ? await AddVendorListAPICall({
          payload: [
            {
              name: values?.vendor,
              phone: values?.phone,
              email: values?.email,
              accountNo: values?.accountno,
              businessNumber: values?.businessNumber,
              billRate: Number(values?.billRate),
              vendor1099: vendor1099,
              billingAddress: {
                line1: values?.address,
                city: values?.city,
                state: values?.state,
                zip: values?.zip,
              },
              clientId: `${business_id}`,
            },
          ],
        })
      : await AddVendorListAPICall({
          payload: [
            {
              name: values?.vendor,
              phone: values?.phone,
              email: values?.email,
              businessNumber: values?.businessNumber,
              billRate: Number(values?.billRate),
              vendor1099: vendor1099,
              accountNo: values?.accountno,
              clientId: `${business_id}`,
            },
          ],
        });
  };
  const handleChange = () => {
    setTrackPayment(!trackPayment);
  };

  const handleChangeVendor = () => {
    setVendor1099(!vendor1099);
  };

  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={trackPayment ? AddNewVendor : AddNewVendorWithoutAddress}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form autoComplete="off">
                      <MDBox p={3}>
                        <MDBox>
                          <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Vendor *" name="vendor" />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <FormField type="text" label="Account Number" name="accountno" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Grid item xs={12}>
                                  <NumberFormat
                                    customInput={TextField}
                                    variant="standard"
                                    type="tel"
                                    name="phone"
                                    fullWidth
                                    InputLabelProps={{
                                      style: darkMode ? { color: "#ffffff" } : {},
                                    }}
                                    InputProps={{ style: darkMode ? { color: "#ffffff" ,borderBottom:"2px solid" } : {} }}
                                    value={values?.phone}
                                    label="Phone Number"
                                    sx={{
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                      fontSize: `0.823rem !important`,
                                    }}
                                    format="(###) ###-####"
                                    mask=""
                                    onValueChange={(value) =>
                                      setFieldValue("phone", value.formattedValue)
                                    }
                                  />
                                </Grid>
                                <MDTypography
                                  component="div"
                                  variant="caption"
                                  color="error"
                                  fontWeight="regular"
                                >
                                  <ErrorMessage name="phone" />
                                </MDTypography>
                              </Grid>
                              <Grid item xs={12} sm={6} mt={-0.5}>
                                <FormField type="email" label="Email" name="email" />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <FormField
                                  type="text"
                                  label="Business ID No./Social Security No."
                                  name="businessNumber"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDBox>
                                  <Field
                                    as={FormControlLabel}
                                    type="checkbox"
                                    name="vendor1099"
                                    control={<Checkbox />}
                                    label={
                                      <Typography
                                        variant="secendory"
                                        style={
                                          darkMode ? { color: "#ffffff" } : { color: "#7b809a" }
                                        }
                                      >
                                        Track payments for 1099
                                      </Typography>
                                    }
                                    checked={vendor1099}
                                    onChange={handleChangeVendor}
                                    sx={{ mt: 1.5, ml: "-13px" }}
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <MDBox>
                                  <Field
                                    as={FormControlLabel}
                                    type="checkbox"
                                    name="addressfull"
                                    control={<Checkbox />}
                                    label={
                                      <span style={darkMode ? { color: "white" } : {}}>
                                        Billing Address
                                      </span>
                                    }
                                    checked={trackPayment}
                                    onChange={handleChange}
                                    sx={{ mt: 1.5 }}
                                  />
                                </MDBox>
                              </Grid>
                            </Grid>
                            {trackPayment && (
                              <>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="Address *" name="address" />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="City *" name="city" />
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="State *" name="state" />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <FormField type="text" label="Zip *" name="zip" />
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </MDBox>
                          <MDBox mt={4} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "Submitting..." : "Submit"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={errorMessage}
              // autoHideDuration={3000}
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
          </MDBox>
        </>
      )}
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default AddVendorDialog;
