// react-router-dom components
import { useNavigate } from 'react-router-dom';
import { getAdminToken } from 'helpers/getadmintoken';
import { getAdminPermissions } from 'helpers/getAdminPermissions';
import { setRole, setProfileInfo, setPermission } from "actions/rolePermission";
import configureStore from "store";
import { useDispatch } from "react-redux";

function Hoc(WrappedComponent) {
    return (props) => {
        const navigate = useNavigate();
        const token = getAdminToken();
        const dispatch = useDispatch();

        var permission = configureStore.getState().roleReducer.permission;

        if (!token) {
            window.location.replace('/sign-in');
            navigate('/sign-in');
            return null;
        }

        if (token) {
            dispatch(setRole('admin'));
            if (permission && permission.length == 0) {
                const permission = getAdminPermissions();
                dispatch(setPermission(permission));
            }

            return <WrappedComponent {...props} />;
        } else {
            window.location.replace('/sign-in');
            navigate("/sign-in")
            return null;
        }

    }
}

export default Hoc;