import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

function DataTableBodyCell({ noBorder, align, children,totalColumnIndex,selectTab, display, width, selectHeight, ClientHeigth ,isClient,currentHeader}) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const theme = useTheme();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MDBox
      component="td"
      textAlign={align}
      pt={!ClientHeigth && selectHeight ? 0.7 : 3}
      pb={!ClientHeigth && selectHeight ? 2.3 : 3}
      pl={0}
      pr={0}
      // sx={{ verticalAlign: "middle", display: "flex", width: 200 }}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        lineHeight: "1.25",
        fontSize: " 0.75rem",
        letterSpacing: " 0.03333em",
        color: "rgb(123, 128, 154)",
        fontWeight: 600,
        textTransform: "none",
        verticalAlign: "unset",
        borderBottom: noBorder ? "none" : `${darkMode ? borderWidth[2] : borderWidth[1]} solid ${darkMode ? '#1a2035' :light.main}`,
      })}
    >
      <MDBox
        display={display}
        width = {width}
        // width={screenSize < 1440 ? typeof(currentHeader) == 'function' ? 40 : currentHeader === 'Issue' ? 40 : currentHeader === 'Upload Date' || currentHeader === 'Status' ? 80 : 190 : width}
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;