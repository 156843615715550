import CircleIcon from "@mui/icons-material/Circle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DataTable1 from "components/NewTable/table";
import { useMaterialUIController } from "context";
import { canAccess } from "helpers";
import UserHoc from "hoc/userHoc";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { eventBus } from "utils/eventbus";
import { EditUserInfo, GetuserList, sendUserReinviteEmailList} from "../../../services/userservices";
import { useAPICall } from "../../../utils/useapicall";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import Loader from "./components/imgLoader/loader";
const cookies = new Cookies();

function User() {
  const navigate = useNavigate();
  const action = {
    route: "",
    edittooltip: "Edit",
    resendInvite: "Resend Invite",
    deletetooltip: "Delete",
  };
  const [alert, setAlert] = useState(false);
  const [alertClient, setAlertClient] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaderClass, setLoaderClass] = useState({});
  const [tableEmpty, setTableEmpty] = useState("");
  const [profileInfo, setProfileInfo] = useState(cookies.get("user_profile_info"));
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState();
  const [clientList, setClientList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [successMassageText, setSuccessMassageText] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showReinvoiteEmailDialog, setShowReinvoiteEmailDialog] = useState(false);
  const PerPagelimit = localStorage.getItem("PerPagelimit");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetuserList, { onLoad: false });
  const [
    { isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse },
    removeUserAPICall,
  ] = useAPICall(EditUserInfo, { onLoad: false });
  const [
    {
      isError: isReinviteEmailError,
      isLoading: isReinviteEmailLoading,
      response: ReinviteEmailresponse,
    },
    ReinviteEmailsetAPICall,
  ] = useAPICall(sendUserReinviteEmailList, { onLoad: false });

  useEffect(() => {
    if (isReinviteEmailLoading) {
      return () => null;
    }
    if (isReinviteEmailError) {
      setSuccessMassageText("Faild to send Reinvite email");
      setShowErrorSnackbar(true);
      setTimeout(() => {
        setShowErrorSnackbar(false);
      }, 4000);
      setShowReinvoiteEmailDialog(false);
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (ReinviteEmailresponse && ReinviteEmailresponse.data.isSuccess === true) {
      setShowReinvoiteEmailDialog(false);
      setSuccessMassageText("Reinvite email sent successfully");
      setShowSuccessSnackbar(true);
      setTimeout(() => {
        setShowSuccessSnackbar(false);
      }, 4000);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [ReinviteEmailresponse]);
  var resource = "";
  var actions = "";
  const { pathname } = useLocation();

  eventBus.$on("onclickchangePages", (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const tt = (Number(data) == 0 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - 10) == -10 ? 0 : (Number(data) + 1) * Number(PerPagelimit) - Number(PerPagelimit);
    localStorage.setItem("offset", tt);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: tt, limit: PerPagelimit }],
    });
  }

  eventBus.$on("onclickchangeTotalPerPage", (data) => OnclickchangeTotalPerPage(data));
  function OnclickchangeTotalPerPage(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    localStorage.setItem("PerPagelimit", Number(data));
    localStorage.setItem("offset", 0);
    setAPICall({
      payload: [{ keyword: `${search}`, offset: 0, limit: data }],
    });
  }

  useEffect(async () => {
    localStorage.removeItem("clientSelect");
    localStorage.setItem("PerPagelimit", 10);
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: 10 }],
      });
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState("data", "title", `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    loading === true
      ? setLoaderClass({ opacity: "0.4", borderRadius: "13px" })
      : setLoaderClass({});
  }, [loading]);

  const warningWithConfirmMessage = () => {
    setAlert(true);
  };

  const successDelete = async () => {
    setLoading(true);
    setAlert(false);
    await removeUserAPICall({
      payload: [
        {
          isArchived: true,
          bussinessId: `${userId}`,
        },
      ],
    });
  };
  const hideAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
      const timeOutId = setTimeout(
        async () =>
          await setAPICall({
            payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    
  }, [search]);

  useEffect(() => {
    if (isRemoveLoading) {
      return () => null;
    }

    if (isRemoveError) {
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      return () => null;
    }

    if (removeResponse && removeResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setAPICall({
        payload: [{ keyword: `${search}`, offset: ThisOffsetuse, limit: PerPagelimit }],
      });
      // navigate('/accountants/list');
    }
  }, [removeResponse]);

  const hadleOpenClientList = (ClientId) => {
    {
      const result = response.data.data.records.find(({ _id }) => _id == ClientId);
      const count = result.totalClients;
      var ClientNameList = [];
      for (var j = 0; j < count; j++) {
        clientList.push(result.assignedClients[j].name);
        ClientNameList.push(result.assignedClients[j].name);
      }
      setClientList(ClientNameList);
    }
    setAlertClient(true);
  };

  const handleClose = () => {
    setAlertClient(false);
    setClientList([]);
  };

  function capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
      return ''; // Return an empty string if the input is not valid
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setLoadingCircul(false);
      setLoading(false);
      response?.code === "DATA401" ? navigate("/user/logout") : console.log("somting else");
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setTableEmpty(response.data.data.totalRecords);
      const data = response.data.data.records.map((prop, key) => {
        var clientNames = "";
        const Createdate = new Date(prop.createdAt);
        if (prop.allScopes === true) {
          var clientNames = "All Clients";
        } else if (prop.role) {
          var ClientIds = prop.role.hasOwnProperty("clientIds") ? prop.role : "";
          var ClientName = "";
          if (ClientIds && ClientIds.clientIds && ClientIds.clientIds.length > 0) {
            var totalClient = ClientIds.clientIds.length;
            // totalClient = totalClient >= 2 ? 2 : totalClient;
            for (var j = 0; j < totalClient; j++) {
              let glue = j ? ", " : "";
              ClientName =
                ClientName + glue + (ClientIds.clientIds ? ClientIds.clientIds[j].name : "");
            }
          }
          var clientNames = ClientName ? ClientName : "-";
        }
        return {
          snum: key + 1,
          firstName: prop.fname,
          business: prop.businessName,
          clientIds:
            prop?.allClients == true ? ( "All client") : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="info"
                  sx={{ cursor: "pointer" ,
                      "&:hover": {
                        color: "#007bff", // Change to blue on hover
                      }}}
                  onClick={(value) => {
                    hadleOpenClientList(prop._id);
                  }}
                >
                  {prop?.totalClients}
                </MDTypography>
              </MDBox>
            ),
          email: prop.email,
          phone: prop.phone,
          createdat: Createdate.toLocaleDateString(),
          totalaccountants: "-",
          totalclients: "-",
          monthlycost: "-",
          switch:
            prop.joinedAt !== null ? (
              prop.isBlocked ? (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="error"
                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                  >
                    Inactive
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox>
                  <MDTypography
                    variant="caption"
                    fontWeight="regular"
                    fontSize="0.875rem"
                    color="success"
                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                  >
                    Active
                  </MDTypography>
                </MDBox>
              )
            ) : (
              <MDBox>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  fontSize="0.875rem"
                  color="warning"
                >
                  Pending Confirmation
                </MDTypography>
              </MDBox>
            ),
          role: prop.role === null ? "-" : capitalizeFirstLetter(prop?.permissions[0]?.role),
          action:
            profileInfo?.user?._id == prop._id ? (
              ""
            ) : (
              <MDBox display="flex" justifyContent="center">
                {canAccess((actions = "update"), (resource = "employees")) && (
                  <Link to={`/accountant/users/${prop._id}/edit-user`}>
                    <MDTypography
                      variant="body2"
                      color="secondary"
                      sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: "pointer" }}
                    >
                      <Tooltip title={action.edittooltip} placement="top">
                        <Icon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>edit</Icon>
                      </Tooltip>
                    </MDTypography>
                  </Link>
                )}
                {canAccess((actions = "delete"), (resource = "employees")) && (
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.deletetooltip}
                      placement="top"
                      onClick={(value) => {
                        // setAccountantId(prop._id);
                        warningWithConfirmMessage();
                        setUserId(prop._id);
                      }}
                    >
                      <Icon sx={{ color: darkMode ? "#ffffff" : "#7b809a" }}>delete</Icon>
                    </Tooltip>
                  </MDTypography>
                )}
                {prop.joinedAt == null && (
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ ml: 1, fontSize: "1.20rem", cursor: "pointer" }}
                  >
                    <Tooltip
                      title={action.resendInvite}
                      placement="top"
                      onClick={(value) => {
                        setUserId(prop._id);
                        // setLoading(true);
                        setShowReinvoiteEmailDialog(true);
                      }}
                    >
                      {!darkMode ? (
                        <img
                          height={21}
                          width={21}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD80lEQVR4nO1ZTWgVVxQeW0m1FbGhBbFScCMuFKFVKGjF/m3cdeHClT8LFyVRRKx59yR890UlSnEREJNzRhGEbh74g65UELppGkWjLkxc2EU1gi78pyBiI2fm5eW9eTOZeZN574XyDtzNzP35vnO/c+65M47Tspa1rGUta9n/0YBT8wx4F1keJvBrsjLR0AZdk4cVg2KpCbw5OPAFWR5pOGgb1XgEOLEkoecLbQZyq/mgJYREoS2WAFnpbD5YCW0G3JGAAA83GyhF78JQPAHw5Vm7A1YuJYoDAv84m+LAgO9Snjc7tRiAD3SQAf/dPI/zAwN35+ZC4cPEwIOdvfPAyj6CPG0YeMhTXTOY/xMRidqurr7jnxL4MEH+raPH35Blzh06+XmUrGMJTE3IQ7m8fBt8D8hSXcRYeZsdcHlHkAIdkGXB9XryvJYgVyf71kCgNPmV7l5eFeyXA6/wFp05+CvdvQOrq3YcstybH/xfef+aCRQXeateDzvODeQbY+WP2sFrjeVurN5h/kyl6supelwqAlPBxa+N5QNA/8LKURNzTF5+JiujCcCPal8d44QYcGxxN9xNBLYGPJYtgSkiT/wqsbI2ATBX056xPB4SoOP6TvvEgigzwuCG8urAibMaZXCfrGwJehPgjwliCPK82Iw+q+zjthvwDgKf9zyt5bPuMHjMWDlH1t2umc/vPTHHgH9RWWVMoNRu5PLuT2F61lb+bM/Ro/ONZTJWXsTvtE9ex+jYsKyYFYFSRjHgr6Lm1iRgINdSBPzIfvCXseBnSqBI4h1Z+T2Y073zA/Iw/bz8QC9adSdQtuCbbsu7J2WjMksyLpd31xkMrjFWHoe8vx57vcyKgK/hwQ06p2o+6ZieXvdrHZPDiZWhJMBdjdkByB1fOm57ooC1JQk+VvCRJCDPp7JTfSXU43lfU2Xo7vAFPYn3/nb6k+kcqnIKcc62uhPI5Qd/8OYDnw8hl5tWBjEECHzWqTeBybqJLN8Ler709cPKEYI8qklC/g1trAEE/GxB4JeVBPwCzrtb2BRB7DvhZSQBY+VVPQkAxxb461TXS+SDWz9NGk1AAPJXPSUUS8DGtzgJdWYZxH5hVv6cvys+70tNwPKZSAKd/f0fZfFd1FiB7xB3e2Dxi2VB3JduJ3hrJIHsPu7ybR+o2+5VlZUkaFoAjlae7roI7z8DTi1K+JGXOwjyZ+rALpYS3t2gGshFgnw/GRNB02AOn5N/dRptmpG0EJt5bPFwzf8KsjKVpZbEqWMK/E/ifwT1MgWQ5uu3Ad9MfKFpiJzAXcHApoiAVc1rZnRmm2lJbCDbiufEqCaKYrIY1WJNU2WibNMyp7n2Hl0mzWUZZEXOAAAAAElFTkSuQmCC"
                        ></img>
                      ) : (
                        <img
                          height={21}
                          width={21}
                          style={{ filter: "brightness(0) invert(1)" }}
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/0lEQVR4nO2ZS2sUQRDHfz7QjYqoKEhWBC/iQRE0gpAoPi/ePHjwZJKDB9F4MugGUfAQ/Qj5AF4Ek6AnDQhe1FV8Htx40IMaIR7iahYhiI601MDQdO/0PHeQ+UPB0t1VXf+equqaWShRokSJEiX+U1SAIaAOtAAvZ2nJ3kPiSyRUgZcdcNqziPKl29X5ZcCrAjjtGUgo30JxrgDOehY560KgXgBHPYs8diFwvwCOeha5hyOOFCwP3gIniIjFovShg45/Ak4DS6I4ri9WNfgCMJej43Oyp17/nYjYHtda4DrwM0PHF4AxYEObsA5FMOP3GeY3ySa/UnT8N3AL2GLYbw/wILDWmYAvU8AOw7ptsmlS56eAnQb7W8X+H219ZAKenPaY5TrfCzyM4bi6bw4Y7K2XUF2w6MUiEGywrgGrNZ1FwHGg4eB4Q9YqHRM2AseAq8B02gR8+Spdot6bLJWyN2PQmZE5tSYK9mvdQSoEfHkPnDSc5gqgBjRFajIWxDpgEJiUk26JqN8TwIBUPsT+GQmrVAn48hw4aolnJUF0ASPAdwe7Pnmlg6UqpkIgWFF2tbGtisDTmK30ZhfnkxLwa/pNQ01X98fnhG1FNQ8Cvqh4PS82uyTMXPR6gR5g1jD3zOX10ktRVAVBYt5VZ7fobLeQuJgXgTeBauOSsJ7IrDhvI9EMVKdMCVwWe4OW+TtyE68MOdAeg25/HgQOi71Jw9ylsDAIITBODgT8vumd4eSRW/wG8CViCHly2WVOwK8WP7Rxv4FTDVucJPbEphXzGRNYJeOmfklJX5sy6kTgScYhFEbAc5DpPD5s+Uk8oY0flPHRBLZvtyOwPKXvolfE3oA2fjeQxKMxn8Qpcvi4+zpwkTW1OXUzh6HXYvcbsMZB/98JqW+RjxIktt9K1Axz6kkcCuSEjj6LzWE6gIo0YknDsh7nv4K0UJWWOK7zH6P8R5AVumN+/X4R5YUma1SkJdYT25aww1IZCwfVEvfLPdGQQjEvv8elVDpVmxJ0EH8B1uk46eiPBnAAAAAASUVORK5CYII="
                        ></img>
                      )}
                    </Tooltip>
                  </MDTypography>
                )}
              </MDBox>
            ),
        };
      });
      setAdminList(data);
      setLoading(false);
      setLoadingCircul(false);
    }
  }, [response]);

  const dataTableData = {
    columns: [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Clients",
        accessor: "clientIds",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Status",
        accessor: "switch",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date",
        accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: adminList,
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0);
    setSearch(event.target.value);
    window.history.pushState(
      "data",
      "title",
      `${pathname}?keyword=${encodeURIComponent(event.target.value)}`
    );
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3} mb={3}>
        <Card
          sx={{
            opacity: loading ? "0.4" : "",
            borderRadius: loading ? "13px" : "",
            backgroundColor: darkMode ? "#202940" : "#ffffff",
          }}
        >
          <MDBox
            pt={3}
            px={2}
            sx={{ pr: 3, mb: 2 }}
            component="form"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Users
            </MDTypography>
            <MDBox px={2} display="flex">
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput
                  type="search"
                  label="Search User"
                  onKeyDown={handleKeyDown}
                  variant="standard"
                  onChange={handleSearch}
                  InputLabelProps={{
                    style: darkMode ? { color: "#ffffff" } : {},
                  }}
                  sx={{
                    input: { color: darkMode ? "#ffffff" : "#000000" },
                    "& input[type='search']::-webkit-search-cancel-button": {
                    appearance: "none", // Remove the default clear icon
                  },
                  }}     
                />
              </MDBox>
              {canAccess((actions = "create"), (resource = "employees")) && (
                <MDBox>
                  <MDButton
                    component={NavLink}
                    to="/accountants/users/new-user"
                    variant="gradient"
                    color="info"
                    disabled={loading}
                  >
                    Add New User
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
          <MDBox>{alert}</MDBox>
          {
            <MDBox my={5} sx={{ mt: 1, borderRadius: "13px" }}>
              <DataTable1
                table={dataTableData}
                entriesPerPage={true}
                pagination={true}
                showTotalEntries={true}
                response={tableEmpty}
                showCheck={false}
                ClientHeigth={true}
              />
            </MDBox>
          }
          <MDBox>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content="User Deleted Successfully."
                open={showSnackbar}
                close={() => setShowSnackbar(false)}
              />
            </MDBox>
          </MDBox>
          <MDBox>
            <Dialog
              open={alert}
              onClose={hideAlert}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "450px",
                    backgroundColor: darkMode ? "#202940" : "#ffffff",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: "hidden" }}>
                <DialogContentText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#7b809a",
                  }}
                >
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 300,
                      fontSize: "0.90rem",
                      textAlign: "center",
                      mt: 4,
                      color: darkMode ? "#ffffff" : "#7b809a",
                    }}
                  >
                    Once you delete this User there is no way of getting any of the documents or
                    data back.
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                <MDButton variant="gradient" color="success" onClick={successDelete}>
                  Yes, delete it!
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={hideAlert}>
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={showReinvoiteEmailDialog}
              onClose={() => setShowReinvoiteEmailDialog(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "500px",
                    backgroundColor: darkMode ? "#202940 !important" : "#ffffff",
                    color: darkMode ? "#ffffff" : "#000000",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: "hidden" }}>
                <DialogContentText
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    color: darkMode ? "#ffffff" : "#000000",
                  }}
                >
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{
                      fontWeight: 300,
                      fontSize: "0.90rem",
                      textAlign: "center",
                      mt: 4,
                      color: darkMode ? "#ffffff" : "#000000",
                    }}
                  >
                    Want to send the reinvite email?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={(value) => {
                    setLoading(true);
                    setLoadingCircul(true);
                    ReinviteEmailsetAPICall({
                      payload: [{ userId: userId }],
                    });
                  }}
                >
                  Yes, send it!
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    setLoading(false);
                    setShowReinvoiteEmailDialog(false);
                  }}
                >
                  Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "450px",
                    backgroundColor:darkMode ? '#1a2035 !important':'#ffffff',
                    color: darkMode ? '#ffffff':'#000000',
                  },
                },
              }}
              onClose={handleClose}
              open={alertClient}
            >
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? '#ffffff':'#000000',
                }}
              >
                CLIENTS
              </DialogTitle>
              <DialogContent>
                <List
                  sx={{
                    pt: 0,
                  }}
                >
                  {clientList.map((client) => (
                    <ListItem disableGutters>
                      <CircleIcon sx={{color: darkMode ? '#ffffff':'#000000', mb: "-6px", ml: 1.5, mr: 1.5, fontSize: "small" }} />
                      <ListItemText
                        primaryTypographyProps={{color: darkMode ? '#ffffff':'#000000', fontSize: "0.875rem", fontWeight: 400, mt: 1 }}
                        primary={client}
                      />
                    </ListItem>
                  ))}
                </List>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
            <MDBox>
              <MDSnackbar
                color="success"
                icon="done"
                title="Success"
                content={successMassageText}
                open={showSuccessSnackbar}
                close={() => setShowSuccessSnackbar(false)}
              />
            </MDBox>
            <MDBox>
              <MDSnackbar
                color="error"
                icon="warning"
                title="Failed."
                content={successMassageText}
                open={showErrorSnackbar}
                close={() => setShowErrorSnackbar(false)}
              />
            </MDBox>
            <MDBox></MDBox>
            <MDBox>
              {loadingCircul && (
                <CircularProgress
                  size={50}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="center">
            {loading && <Loader />}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserHoc(User);
