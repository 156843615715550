import Accountants from "layouts/admin/accountants";
import DeleteAccountants from "layouts/admin/accountants/delete-accountant";
import NewAccountant from "layouts/admin/accountants/new-accountant";
import Dashboards from "layouts/admin/accountants/dashboards";
import AdminClients from "layouts/admin/client";
import Newclients from "layouts/admin/client/new-clients";
import ReportList from "layouts/admin/reports";
import OcrErrorsList from "layouts/admin/ocrErrors";
import Icon from "@mui/material/Icon";
import GroupIcon from '@mui/icons-material/Group';
import { useMaterialUIController } from "context";

function CheckAdminDarkMode(){
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    
    let adminRoutes = [];
    if(!darkMode){
        adminRoutes = [
            // {
            //     type: "collapse",
            //     name: "Dashboard",
            //     key: "dashboards",
            //     module: "profiles",
            //     action: "always",
            //     route: "/admin/dashboards",
            //     component: <Dashboards />,
            //     icon: <Icon fontSize="medium">dashboard</Icon>,
            //     noCollapse: true,
            // },
            // 
            {
                type: "collapse",
                name: "Accountants",
                key: "accountants",
                module: "accountants",
                action: "always",
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">groups</Icon>,
                component: <Dashboards />,
                route: "/admin/accountants/list",
                noCollapse: true,
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">groups</Icon>,
                isLast: true,
                collapse: [
                    {
                        name: "All",
                        key: "read",
                        module: "accountants",
                        action: "always",
                        icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">dashboard</Icon>,
                        route: "/admin/accountants/list",
                        component: <Accountants />,
                    },
                    {
                        name: "Archived",
                        key: "read",
                        module: "accountants",
                        action: "always",
                        route: "/admin/accountants/deleted/list",
                        component: <DeleteAccountants />,
                    },
                    {
                        name: "Add New",
                        key: "new-accountant",
                        module: "accountants",
                        action: "always",
                        route: "/admin/accountants/new-accountant",
                        component: <NewAccountant />,
                    },
                ],
            },
            { type: "divider", key: "divider-0" },
            {
                type: "collapse",
                name: "Clients",
                key: "client",
                module: "adminclients",
                action: "always",
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">workspaces</Icon>,
                route: "/admin/client/list",
                component: <AdminClients />,
                noCollapse: true,
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">workspaces</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "Clients",
                        module: "adminclients",
                        action: "always",
                        route: "/admin/client/list",
                        component: <AdminClients />,
                    },
                    {
                        name: "Add New",
                        key: "Clients",
                        module: "adminclients",
                        action: "always",
                        route: "/admin/client/new-clients",
                        component: <Newclients />,
                    },
                ],
            },
            {
                type: "collapse",
                name: "OcrErrors",
                key: "ocrerrors",
                module: "adminocrerrors",
                action: "always",
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">report</Icon>,
                route: "/admin/ocrerrors/list",
                component: <OcrErrorsList />,
                noCollapse: true,
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">report</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "OcrErrors",
                        module: "adminocrerrors",
                        action: "always",
                        route: "/admin/ocrerrors/list",
                        component: <OcrErrorsList />,
                    },
                ],
            },
            {
                type: "collapse",
                name: "Reports",
                key: "report",
                module: "adminreports",
                action: "always",
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">report_problem</Icon>,
                route: "/admin/report/list",
                component: <ReportList />,
                noCollapse: true,
                icon: <Icon sx={{ color: '#344767' }}  fontSize="medium">report_problem</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "Reports",
                        module: "adminreports",
                        action: "always",
                        route: "/admin/report/list",
                        component: <ReportList />,
                    },
                ],
            },
        ];
    }else{
        adminRoutes = [
            // {
            //     type: "collapse",
            //     name: "Dashboard",
            //     key: "dashboards",
            //     module: "profiles",
            //     action: "always",
            //     route: "/admin/dashboards",
            //     component: <Dashboards />,
            //     icon: <Icon fontSize="medium">dashboard</Icon>,
            //     noCollapse: true,
            // },
            // 
            {
                type: "collapse",
                name: "Accountants",
                key: "accountants",
                module: "accountants",
                action: "always",
                icon: <Icon fontSize="medium">groups</Icon>,
                component: <Dashboards />,
                route: "/admin/accountants/list",
                noCollapse: true,
                icon: <Icon fontSize="medium">groups</Icon>,
                isLast: true,
                collapse: [
                    {
                        name: "All",
                        key: "read",
                        module: "accountants",
                        action: "always",
                        icon: <Icon fontSize="medium">dashboard</Icon>,
                        route: "/admin/accountants/list",
                        component: <Accountants />,
                    },
                    {
                        name: "Archived",
                        key: "read",
                        module: "accountants",
                        action: "always",
                        route: "/admin/accountants/deleted/list",
                        component: <DeleteAccountants />,
                    },
                    {
                        name: "Add New",
                        key: "new-accountant",
                        module: "accountants",
                        action: "always",
                        route: "/admin/accountants/new-accountant",
                        component: <NewAccountant />,
                    },
                ],
            },
            { type: "divider", key: "divider-0" },
            {
                type: "collapse",
                name: "Clients",
                key: "client",
                module: "adminclients",
                action: "always",
                icon: <Icon fontSize="medium">workspaces</Icon>,
                route: "/admin/client/list",
                component: <AdminClients />,
                noCollapse: true,
                icon: <Icon fontSize="medium">workspaces</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "Clients",
                        module: "adminclients",
                        action: "always",
                        route: "/admin/client/list",
                        component: <AdminClients />,
                    },
                    {
                        name: "Add New",
                        key: "Clients",
                        module: "adminclients",
                        action: "always",
                        route: "/admin/client/new-clients",
                        component: <Newclients />,
                    },
                ],
            },
            {
                type: "collapse",
                name: "OcrErrors",
                key: "ocrerrors",
                module: "adminocrerrors",
                action: "always",
                icon: <Icon fontSize="medium">report</Icon>,
                route: "/admin/ocrerrors/list",
                component: <OcrErrorsList />,
                noCollapse: true,
                icon: <Icon fontSize="medium">report</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "OcrErrors",
                        module: "adminocrerrors",
                        action: "always",
                        route: "/admin/ocrerrors/list",
                        component: <OcrErrorsList />,
                    },
                ],
            },
            {
                type: "collapse",
                name: "Reports",
                key: "report",
                module: "adminreports",
                action: "always",
                icon: <Icon fontSize="medium">report_problem</Icon>,
                route: "/admin/report/list",
                component: <ReportList />,
                noCollapse: true,
                icon: <Icon fontSize="medium">report_problem</Icon>,
                collapse: [
                    {
                        name: "All",
                        key: "Reports",
                        module: "adminreports",
                        action: "always",
                        route: "/admin/report/list",
                        component: <ReportList />,
                    },
                ],
            },
        ];
    }
        return adminRoutes;
        }

export default CheckAdminDarkMode;