import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Form, Formik } from "formik";
import { green } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import { eventBus } from "utils/eventbus";
import {EditBulkItem,GetCategoryAllList,GetCategoryListForParent,GetClassesAllList,GetClassListForParent,GetCustomerAllList,GetCustomerListForParent,GetProductList,GetProductsAllList} from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import CircularProgress from "@mui/material/CircularProgress";
import { useMaterialUIController } from "context";

const useStyles = makeStyles({
  option: {
    "&:first-child": {
      color: "#0000ff",
    },
    "&:hover:first-child": {
      color: "#0000ff",
      backgroundColor: "none !important",
    },
  },
});

function BulkUpdateDialog({
  onClose,
  currencySymbol,
  businessId,
  itemList,
  toggleShow,
  showClasses,
  selectedItemRow,
  invoiceIds,
}) {
  const styles = useStyles();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [invoiceId, setInvoiceId] = useState(invoiceIds);
  const [isDisable, setBtnDisable] = useState(false);
  const [value, setValue] = useState("ItemBasedExpenseLineDetail");
  const [customerList, setCustomerList] = useState([]);
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [selectProduct, setSelectProduct] = useState(false);
  const [classesList, setClassesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [productIds, setProductId] = useState("");
  const [categoryerrormsg, setCategoryerrormsg] = useState("");
  const [categoryerror, setCategoryerror] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [customerIds, setCustomerIdId] = useState("");
  const [classIdIds, setClassIdId] = useState("");
  const [categoryIds, setCategoryIds] = useState("");
  const [producterrormsg, setProducterrormsg] = useState("");
  const [producterror, setProducterror] = useState(false);
  const [openAddOption, setOpenAddOption] = useState(false);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [productName, SetProductName] = useState("");
  const [customerName, SetCustomerName] = useState("");
  const [className, SetClassName] = useState("");
  const [productLoading, setProductLoading] = useState(false);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [productOffset, setProductOffset] = useState(0);
  const [productSearch, setProductSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [filterloading, setFilterLoading] = useState(false);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customerOffset, setCustomerOffset] = useState(0);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [classLoading, setClassLoading] = useState(false);
  const [hasMoreClasses, setHasMoreClasses] = useState(true);
  const [classOffset, setClassOffset] = useState(0);
  const [classSearch, setClassSearch] = useState('');
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditBulkItem, {
    onLoad: false,
  });
  const [
    { isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },
    CustomerListAPICall,
  ] = useAPICall(GetCustomerAllList, { onLoad: false });
  const [
    { isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },
    ClassesListAPICall,
  ] = useAPICall(GetClassesAllList, { onLoad: false });
  const [
    { isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },
    CategoryListAPICall,
  ] = useAPICall(GetCategoryAllList, { onLoad: false });
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsAllList, { onLoad: false });

  const AddProduct = { name: "+ Add Product" };
  const NewProductList = [AddProduct, ...productsList];
  const AddCustomer = { name: "+ Add Customer" };
  const NewCustomerList = [AddCustomer, ...customerList];
  const AddClass = { name: "+ Add Class" };
  const NewClassesList = [AddClass, ...classesList];

  useEffect(async () => {
    // setLoadingCircul(true);
    setValue("ItemBasedExpenseLineDetail");
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  const initialValues = {
    product: "",
    checked: "",
    item: description,
    sku: "",
    price: unitPrice,
    total: "",
    qty: "",
    services: "",
    class: "",
    customer: "",
    unit: "",
    category: "",
    TotalPrice: "",
  };

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeBulkEditItemPopup", response);
      setBtnDisable(false);
      onClose();
    }
  }, [response]);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          customerId: prop?._id,
        };
      });
      // setCustomerList(data);
      setLoadingCircul(false);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          classId: prop?._id,
        };
      });
      // setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          categoryId: prop?._id,
        };
      });
      // setCategoryList(data);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop?.name,
          productId: prop?._id,
          accountingId: prop?.accountingId,
          description: prop?.description,
          unitPrice: prop?.unitPrice,
        };
      });
      // setProductsList(data);
      // setLoadingCircul(false);
    }
  }, [ProductsResponse]);

  const handleSubmit = async (values) => {
    if (categoryIds && classIdIds && customerIds) {
      setBtnDisable(true);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              customerId: customerIds,
              accountId: categoryIds,
              classId: classIdIds,
            },
          },
        ],
      });
    } else if (categoryIds && classIdIds) {
      setBtnDisable(true);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              accountId: categoryIds,
              classId: classIdIds,
            },
          },
        ],
      });
    } else if (categoryIds && customerIds) {
      setBtnDisable(true);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              customerId: customerIds,
              accountId: categoryIds,
            },
          },
        ],
      });
    } else if (productIds && classIdIds && customerIds) {
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              productId: productIds,
              classId: classIdIds,
              customerId: customerIds,
            },
          },
        ],
      });
    } else if (productIds && classIdIds) {
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              productId: productIds,
              classId: classIdIds,
            },
          },
        ],
      });
    } else if (classIdIds && customerIds) {
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              classId: classIdIds,
              customerId: customerIds,
            },
          },
        ],
      });
    } else if (productIds && customerIds) {
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              productId: productIds,
              customerId: customerIds,
            },
          },
        ],
      });
    } else if (customerIds) {
      setBtnDisable(true);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              customerId: customerIds,
            },
          },
        ],
      });
    } else if (categoryIds) {
      setBtnDisable(true);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              accountId: categoryIds,
            },
          },
        ],
      });
    } else if (classIdIds) {
      setBtnDisable(true);
      setLoadingCircul(true);
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              classId: classIdIds,
            },
          },
        ],
      });
    } else if (productIds) {
      await setAPICall({
        payload: [
          {
            documentId: invoiceId,
            itemIds: selectedItemRow,
            updateObj: {
              productId: productIds,
            },
          },
        ],
      });
    }
  };

  const handleChangeCategory = (event, newValue) => {
    if (newValue) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }
    setCategoryName(newValue);
    const found = categoryList.find((obj) => {
      return obj.name === newValue;
    });
    if (found) {
      setCategoryIds(found.categoryId);
      setSelectProduct(true);
      setDescription(found.description);
      setUnitPrice(found.unitPrice);
    }
  };

  const handleChangeProduct = (event, newValue) => {
    if (newValue == "+ Add Product") {
      setOpenAddOption(true);
    } else {
      if (newValue) {
        setProducterrormsg("");
        setProducterror(false);
      } else {
        setProducterrormsg("Product name required");
        setProducterror(true);
      }
      SetProductName(newValue);
      const found = productsList.find((obj) => {
        return obj.name === newValue;
      });
      if (found) {
        setProductId(found.productId);
        setSelectProduct(true);
        setDescription(found?.description === "---" ? "" : found?.description);
        setUnitPrice(found.unitPrice);
      }
    }
  };

  const handleChangeClass = (event, newValue) => {
    if (newValue == "+ Add Class") {
      setOpenAddNewClass(true);
    } else {
      SetClassName(newValue);
      const found1 = classesList.find((obj) => {
        return obj.name === newValue;
      });
      if (found1) {
        setClassIdId(found1.classId);
      }
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    if (newValue == "+ Add Customer") {
      setOpenAddNewCustomer(true);
    } else {
      SetCustomerName(newValue);
      const found2 = customerList.find((obj) => {
        return obj.name === newValue;
      });
      if (found2) {
        setCustomerIdId(found2.customerId);
      }
    }
  };

  eventBus.$on("AddnewProductComplete", (data) => AddnewProductComplete(data));
  function AddnewProductComplete(data) {
    SetProductName(data?.product?.name);
    setProductId(data?.product?._id);
    ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewCustomerComplete", (data) => AddnewCustomerComplete(data));
  function AddnewCustomerComplete(data) {
    SetCustomerName(data?.customer?.name);
    setCustomerIdId(data?.customer?._id);
    CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  eventBus.$on("AddnewClassComplete", (data) => AddnewClassComplete(data));
  function AddnewClassComplete(data) {
    SetClassName(data?.class?.name);
    setClassIdId(data?.class?._id);
    ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }
  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinations = new Set();

  // Filter out duplicate combinations
  const uniqueCategoryList = categoryList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinations.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinations.add(combination);
      return true; // Unique combination, keep it
    }
  });

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinationsProduct = new Set();

  // Filter out duplicate combinations
  const uniqueProductsList = productsList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinationsProduct.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinationsProduct.add(combination);
      return true; // Unique combination, keep it
    }
  });

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinationsClass = new Set();

  // Filter out duplicate combinations
  const uniqueClasssList = classesList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinationsClass.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinationsClass.add(combination);
      return true; // Unique combination, keep it
    }
  });

  // Use a Set to keep track of unique combinations of name and categoryId
  const uniqueCombinationsCustomer = new Set();

  // Filter out duplicate combinations
  const uniqueCustomersList = customerList.filter((item) => {
    const combination = `${item.name}-${item.categoryId}`;
    if (uniqueCombinationsCustomer.has(combination)) {
      return false; // Duplicate combination, filter it out
    } else {
      uniqueCombinationsCustomer.add(combination);
      return true; // Unique combination, keep it
    }
  });

  const fetchProducts = useCallback(async (newSearch = '', newOffset = 0) => {
    setProductLoading(true);
    try {
      const response = await GetProductList({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop?.name,
        productId: prop?._id,
        accountingId: prop?.accountingId,
        description: prop?.description,
        unitPrice: prop?.unitPrice,
      }));

      if (newOffset === 0) {
        setProductsList(data); // Reset the list when new search
      } else {
        setProductsList((prev) => [...prev, ...data]); // Append new data for infinite scroll
      }

      if (data.length < 50) {
        setHasMoreProducts(false); // Stop loading more if fewer than 50 items
      }

      setProductOffset(newOffset);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setProductLoading(false);
  }, [businessId]);

  const handleProductScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5;
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreProducts) {
      setProductOffset((prevOffset) => {
        const newOffset = prevOffset + limit; // Increment offset
        fetchProducts(productSearch, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };

  const fetchCategories = useCallback(async (newSearch = '', newOffset = 0) => {
    setFilterLoading(true);
    try {
      const response = await GetCategoryListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop.name,
        categoryId: prop._id,
      }));

      // Append new data instead of resetting the list
      if (newOffset === 0) {
        setCategoryList(data);
      } else {
        setCategoryList((prev) => [...prev, ...data]);
      }

      // Stop loading more if fewer than 50 items are returned
      if (data.length < 50) {
        setHasMore(false);
      }

      setOffset(newOffset);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
    setFilterLoading(false);
  }, [businessId]);

  
  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5; // Adjust if necessary to control sensitivity
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMore) {
      setOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchCategories(search, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };

  const fetchCustomers = useCallback(async (newSearch = '', newOffset = 0) => {
    setCustomerLoading(true);
    try {
      const response = await GetCustomerListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 customers at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop?.name,
        customerId: prop?._id,
      }));

      // Append new data instead of resetting the list
      if (newOffset === 0) {
        setCustomerList(data);
      } else {
        setCustomerList((prev) => [...prev, ...data]);
      }

      // Stop loading more if fewer than 50 items are returned
      if (data.length < 50) {
        setHasMoreCustomers(false);
      }

      setCustomerOffset(newOffset);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
    setCustomerLoading(false);
  }, [businessId]);

  const handleScrollCustomer = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5; // Adjust if necessary to control sensitivity
    const bottom =
      Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreCustomers) {
      setCustomerOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchCustomers(customerSearch, newOffset); // Fetch next set of customers
        return newOffset;
      });
    }
  };

  const fetchClasses = useCallback(async (newSearch = '', newOffset = 0) => {
    setClassLoading(true);
    try {
      const response = await GetClassListForParent({
        payload: [
          {
            businessId: `${businessId}`,
            keyword: `${newSearch}`,
            offset: newOffset,
            limit: 50, // Fetch 50 items at a time
          },
        ],
      });

      const data = response.data.data.records.map((prop) => ({
        name: prop.name,
        classId: prop._id,
      }));

      if (newOffset === 0) {
        setClassesList(data); // Reset the list when new search
      } else {
        setClassesList((prev) => [...prev, ...data]); // Append new data for infinite scroll
      }

      if (data.length < 50) {
        setHasMoreClasses(false); // Stop loading more if fewer than 50 items
      }

      setClassOffset(newOffset);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
    setClassLoading(false);
  }, [businessId]);

  const handleClassScroll = (event) => {
    const listboxNode = event.currentTarget;
    const tolerance = 5;
    const bottom = Math.abs(listboxNode.scrollHeight - listboxNode.scrollTop - listboxNode.clientHeight) <= tolerance;

    if (bottom && hasMoreClasses) {
      setClassOffset((prevOffset) => {
        const newOffset = prevOffset + 50; // Increment offset
        fetchClasses(classSearch, newOffset); // Fetch next set of data
        return newOffset;
      });
    }
  };



  return (
    <>
      <MDBox width="auto">
        <Grid item xs={12} lg={12}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            // validationSchema={addproductschema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <MDBox p={2} pt={2}>
                  <MDBox>
                    <MDBox>
                      <Grid
                        container
                        spacing={3}
                        sx={{ mb: 2, display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                      >
                        <Grid item xs={12} sm={8}>
                          {toggleShow ? (
                             <Autocomplete
                                sx={
                                  darkMode
                                    ? {
                                        "& .MuiInput-underline": {
                                          borderBottom: "2px solid #ffffff",
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-root .MuiInput-input": {
                                          WebkitTextFillColor: "white",
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                          color: "white !important",
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                          color: "white !important",
                                        },
                                      }
                                    : {
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                      }
                                }
                                value={{ name: productName }}
                                options={productsList}
                                getOptionLabel={(option) => option.name || ""}
                                onFocus={() => {
                                  // Always trigger data fetching on focus
                                  setProductSearch(''); // Clear any existing search term
                                  setProductOffset(0); // Reset the offset
                                  setHasMoreProducts(true); // Reset for loading more items
                                  fetchProducts('', 0); // Fetch initial data when input is focused
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setProductSearch(newInputValue);
                                  setProductOffset(0); // Reset the offset
                                  setHasMoreProducts(true); // Reset for loading more items
                                  fetchProducts(newInputValue, 0); // Fetch new data
                                  handleChangeProduct(event, newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                  SetProductName(newValue?.name);
                                  setFieldValue("productName", newValue); // Update Formik field on selection
                                }}
                                ListboxProps={{
                                  onScroll: handleProductScroll,
                                  sx: {
                                    maxHeight: '200px', // Set a max-height to enable scrolling
                                    overflowY: 'auto',  // Enable vertical scrolling
                                    fontSize: '0.8125rem',
                                    '@media (max-width: 1440px)': {
                                      fontSize: '0.775rem',
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Product *"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: darkMode ? "#ffffff" : "#000000" },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {productLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    helperText={touched.productName && errors.productName}
                                    error={touched.productName && Boolean(errors.productName)}
                                  />
                                )}
                              />
                          ) : (
                            <Autocomplete
                            sx={
                              darkMode
                                ? {
                                    "& .MuiInput-underline": {
                                      borderBottom: "2px solid #ffffff",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    "& .MuiInput-root .MuiInput-input": {
                                      WebkitTextFillColor: "white",
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                      color: "#ffffff !important",
                                    },
                                    "& .MuiInput-input": {
                                      padding: `6px 4px 4px 0px !important`,
                                    },
                                    "& .MuiInputBase-input": {
                                      height: `1.2em !important`,
                                    },
                                    "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                      color: "#ffffff !important",
                                    },
                                  }
                                : {
                                    "& .MuiInputBase-input.Mui-disabled": {
                                      WebkitTextFillColor: "black",
                                    },
                                    "& .MuiInput-input": {
                                      padding: `6px 4px 4px 0px !important`,
                                    },
                                    "& .MuiInputBase-input": {
                                      height: `1.2em !important`,
                                    },
                                  }
                            }
                            value={{ name: categoryName }}  // Bind to Formik value
                            options={categoryList}
                            getOptionLabel={(option) => option.name || ""}
                            onFocus={() => {
                              // Always trigger data fetching on focus
                              setSearch(''); // Clear any existing search term
                              setOffset(0); // Reset the offset
                              setHasMore(true); // Reset hasMore for loading more items
                              fetchCategories('', 0); // Fetch initial data when input is focused
                            }}
                            onInputChange={(event, newInputValue) => {
                              setSearch(newInputValue);
                              setOffset(0); // Reset the offset
                              setHasMore(true); // Reset hasMore for loading more items
                              fetchCategories(newInputValue, 0); // Fetch new data
                              handleChangeCategory(event, newInputValue);
                            }}
                            onChange={(event, newValue) => {
                              setFieldValue("category", newValue); // Update Formik field on selection
                            }}
                            ListboxProps={{
                              onScroll: handleScroll,
                              sx: {
                                maxHeight: '200px', // Set a max-height to enable scrolling
                                overflowY: 'auto',  // Enable vertical scrolling
                                fontSize: '0.8125rem',
                                '@media (max-width: 1440px)': {
                                  fontSize: '0.775rem',
                                },
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Category *"
                                variant="standard"
                                InputLabelProps={{
                                  style: { color: darkMode ? "#ffffff" : "#000000" },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {filterloading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                                helperText={touched.category && errors.category}
                                error={touched.category && Boolean(errors.category)}
                              />
                            )}
                          />
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        sx={{                    color: darkMode ? "#ffffff" : "#7b809a",
                          mt: 1, display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                      >
                        or
                      </Grid>
                      {showClasses && (
                        <Grid
                          container
                          spacing={3}
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item xs={12} sm={8}>
                          <Autocomplete
                                sx={
                                  darkMode
                                    ? {
                                        "& .MuiInput-underline": {
                                          borderBottom: "2px solid #ffffff",
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-root .MuiInput-input": {
                                          WebkitTextFillColor: "white",
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                          color: "white !important",
                                        },
                                        "& .MuiInput-input": {
                                          padding: `6px 4px 4px 0px !important`,
                                        },
                                        "& .MuiInputBase-input": {
                                          height: `1.2em !important`,
                                        },
                                        "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                          color: "white !important",
                                        },
                                      }
                                    : {
                                        "& .MuiInputBase-input.Mui-disabled": {
                                          WebkitTextFillColor: "black",
                                        },
                                        "& .MuiInput-input": {
                                          padding: `6px 4px 4px 0px !important`,
                                        },
                                        "& .MuiInputBase-input": {
                                          height: `1.2em !important`,
                                        },
                                      }
                                }
                                value={{ name: className }} // Bind to Formik value
                                options={classesList}
                                getOptionLabel={(option) => option.name || ""}
                                onFocus={() => {
                                  // Always trigger data fetching on focus
                                  setClassSearch(''); // Clear any existing search term
                                  setClassOffset(0); // Reset the offset
                                  setHasMoreClasses(true); // Reset hasMore for loading more items
                                  fetchClasses('', 0); // Fetch initial data when input is focused
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setClassSearch(newInputValue);
                                  setClassOffset(0); // Reset the offset
                                  setHasMoreClasses(true); // Reset for loading more items
                                  fetchClasses(newInputValue, 0); // Fetch new data
                                  handleChangeClass(event, newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                  setFieldValue("className", newValue); // Update Formik field on selection
                                }}
                                ListboxProps={{
                                  onScroll: handleClassScroll,
                                  sx: {
                                    maxHeight: '200px', // Set a max-height to enable scrolling
                                    overflowY: 'auto',  // Enable vertical scrolling
                                    fontSize: '0.8125rem',
                                    '@media (max-width: 1440px)': {
                                      fontSize: '0.775rem',
                                    },
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Class"
                                    variant="standard"
                                    InputLabelProps={{
                                      style: { color: darkMode ? "#ffffff" : "#000000" },
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {classLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    helperText={touched.className && errors.className}
                                    error={touched.className && Boolean(errors.className)}
                                  />
                                )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {showClasses && (
                        <Grid
                          container
                          spacing={3}
                          sx={{
                            color: darkMode ? "#ffffff" : "#7b809a",
                            mt: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          or
                        </Grid>
                      )}
                      <Grid
                        container
                        spacing={3}
                        sx={{ mb: 1, display: "flex", flexWrap: "wrap", justifyContent: "center" }}
                      >
                        <Grid item xs={12} sm={8}>
                        <Autocomplete
                          sx={
                            darkMode
                              ? {
                                  "& .MuiInput-underline": {
                                    borderBottom: "2px solid #ffffff",
                                  },
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-root .MuiInput-input": {
                                    WebkitTextFillColor: "white",
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator": {
                                    color: "white !important",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                  "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator": {
                                    color: "white !important",
                                  },
                                }
                              : {
                                  "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                  },
                                  "& .MuiInput-input": {
                                    padding: `6px 4px 4px 0px !important`,
                                  },
                                  "& .MuiInputBase-input": {
                                    height: `1.2em !important`,
                                  },
                                }
                          }
                          value={values.customer} // Bind to Formik value
                          options={customerList}
                          getOptionLabel={(option) => option.name || ''}
                          onFocus={() => {
                            // Always trigger data fetching on focus
                            setCustomerSearch(''); // Clear any existing search term
                            setCustomerOffset(0); // Reset the offset
                            setHasMoreCustomers(true); // Reset hasMore for loading more items
                            fetchCustomers('', 0); // Fetch initial data when input is focused
                          }}
                          onInputChange={(event, newInputValue) => {
                            setCustomerSearch(newInputValue);
                            handleChangeCustomer(event, newInputValue);
                            setCustomerOffset(0); // Reset the offset
                            setHasMoreCustomers(true); // Reset hasMore for loading more items
                            fetchCustomers(newInputValue, 0); // Fetch new data
                          }}
                          onChange={(event, newValue) => {
                            setFieldValue('customer', newValue); // Update Formik field on selection
                          }}
                          ListboxProps={{
                            onScroll: handleScrollCustomer,
                            sx: {
                              maxHeight: '200px', // Set a max-height to enable scrolling
                              overflowY: 'auto', // Enable vertical scrolling
                              fontSize: '0.8125rem',
                              '@media (max-width: 1440px)': {
                                fontSize: '0.775rem',
                              },
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer"
                              variant="standard"
                              InputLabelProps={{
                                style: { color: darkMode ? '#ffffff' : '#000000' },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {customerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                              helperText={touched.customer && errors.customer}
                              error={touched.customer && Boolean(errors.customer)}
                            />
                          )}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <MDBox>
                      {loadingCircul && (
                        <CircularProgress
                          size={50}
                          sx={{
                            color: green[500],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </MDBox>
                    <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                      <MDButton
                        disabled={isDisable}
                        variant="gradient"
                        color="light"
                        onClick={onClose}
                      >
                        Cancel
                      </MDButton>
                      <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <MDButton
                          disabled={isDisable}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          {isDisable ? "Updating.." : "Update"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </MDBox>
    </>
  );
}

export default BulkUpdateDialog;
