import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";

function DataTableBodyCell({ noBorder, align, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        lineHeight: "1.25",
        fontSize: " 0.75rem",
        letterSpacing: " 0.03333em",
        color: "rgb(123, 128, 154)",
        fontWeight: 600,
        textTransform: "none",
        verticalAlign: "unset",
        borderBottom: noBorder ? "none" : `${darkMode ? borderWidth[2] : borderWidth[1]} solid ${darkMode ? '#1a2035' :light.main}`,
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
