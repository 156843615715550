import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CircularProgress, Dialog, DialogContent, DialogTitle, FormHelperText, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Field, Form, Formik } from "formik";
import Hoc from "hoc";
import FormField from "layouts/admin/accountants/components/FormField";
import { AddNewSchema } from "layouts/admin/accountants/components/schemas/addnewvalidations";
import { CreatePassSchema } from "layouts/admin/accountants/components/schemas/edit-accountant";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import { eventBus } from "utils/eventbus";
import { EditAccountantInfo, GetuserDetailsList, checkEmail } from "../../../../services/accountantservices";
import { useAPICall } from "../../../../utils/useapicall";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import LoaderProfile from "../components/imgLoader/LoaderProfile";

const useStyles = makeStyles((theme) => ({
  backgroundColorOnInnerBoxOfCustomIcon: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
      cursor: "not-allowed",
      "& .MuiSvgIcon-root .innerBox": {
        fill: "#eee",
      },
    },
  },
}));

function EditAccountant() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [profileEmail, setprofileEmail] = useState("");
  const [profileFname, setprofileFname] = useState("");
  const [profileLname, setprofileLname] = useState("");
  const [startCheckLoader,setStartCheckLoader] = useState(false);
  const [profileBuinessName, setprofileBusinessName] = useState("");
  const [profileNumber, setprofileNumber] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [AccbussinessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPassSnackbar, setPassShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showPassErrorSnackbar, setShowPassErrorSnackbar] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [btnDisable1, setBtnDisable1] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [passBtnDisable, setPassBtnDisable] = useState(false);
  const [waitForApi, setWaitForApi] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("Failed");
  const [isArchived, setIsArchived] = useState(false);
  const [editable, SetEditable] = useState(false);
  const [changePassword, SetChangePassword] = useState(false);
  const [showNPassword, setShowNPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [errorMessages, setErrorMessages] = useState("");
  const [emailId, setemailId] = useState("");
  const [loaderClass, setLoaderClass] = useState({});
  const [emailStatus, setEmailStatus] = useState("");
  const [styleMt, setStyleMt] = useState("8px");
  const [{ isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse },checkEmailAPICall] = useAPICall(checkEmail, { onLoad: false });
  const handleNewClickShowPassword = () => setShowNPassword(true);
  const handleNewClickClosePassword = () => setShowNPassword(false);
  const handleReClickShowPassword = () => setShowRePassword(true);
  const handleReClickClosePassword = () => setShowRePassword(false);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", !showForm);
    !showForm === true
      ? setLoaderClass({ opacity: "0.6", borderRadius: "13px" })
      : setLoaderClass({});
  }, [showForm]);
  
  useEffect(() => {
    if (isCheckEmError) {
      setStartCheckLoader(false);
      return () => null;
    }
    if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
      setEmailStatus(checkEmResponse.data.data.status);
      if (checkEmResponse.data.data.status === "ARCHIVED") {
        setStyleMt("6px");
        setErrorMessages("This user is deleted you want to restore it? Click here");
      } else if (checkEmResponse.data.data.status === "ACTIVE") {
        setStyleMt("6px");
        setErrorMessages("This Email already has a Business");
      } else if (checkEmResponse.data.data.status === "BLOCKED") {
        setStyleMt("6px");
        setErrorMessages("This user is blocked to unblock it - Click here");
      } else {
        setStyleMt("0px");
        setErrorMessages("");
        setBtnDisable(false);
      }
      setStartCheckLoader(false);
    }
  }, [checkEmResponse]);

  useEffect(() => {
   
    const timeOutId = setTimeout(async () => {
      if (emailId.length !== 0 && emailId !== profileEmail) {
        setBtnDisable(true);
        setStartCheckLoader(true);
        await checkEmailAPICall({
          payload: [
            {
              email: `${emailId}`,
              checkBy: "email",
            },
          ],
        });
      }else if(emailId == profileEmail){
        setStartCheckLoader(false);
        setBtnDisable(false);
      }else{
        setStartCheckLoader(false);
        setBtnDisable(false);
      }
    }, 2000);
    return () => clearTimeout(timeOutId);
  }, [emailId]);

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    businessname: profileBuinessName,
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: "",
  };
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditAccountantInfo, {
    onLoad: false,
  });
  const [
    { isLoading: isUpdatePassLoading, isError: isUpdatePassError, response: UpadatePassResponse },
    UpdatePassAPICall,
  ] = useAPICall(EditAccountantInfo, { onLoad: false });
  const [
    { isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse },
    getUserDteailsAPICall,
  ] = useAPICall(GetuserDetailsList, { onLoad: false });

  useEffect(async () => {
    await getUserDteailsAPICall({
      payload: [{ bussinessId: `${AccbussinessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isGetUserLoading) {
      return () => null;
    }

    if (isGetUserError) {
      return () => null;
    }

    if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
      const adminresult = userDetailsDataResponse?.data?.data?.accountantOwner;
      setemailId(adminresult?.email);
      setprofileEmail(adminresult?.email);
      setprofileFname(adminresult?.fname);
      setprofileLname(adminresult?.lname);
      setprofileBusinessName(adminresult?.business?.name);
      setprofileNumber(adminresult?.phone);
      setPhone(adminresult?.phone);
      setActiveUser(adminresult?.isBlocked);
      setIsArchived(adminresult?.isArchived);
      setPassBtnDisable(adminresult?.isArchived);
      setBtnDisable(adminresult?.isArchived);
      setShowForm(true);
      setWaitForApi(true);
    }
  }, [userDetailsDataResponse]);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setPassShowSnackbar(true);
      setTimeout(() => {
        setPassShowSnackbar(false);
      }, 3000);

      setBtnDisable(false);
      SetEditable(false);
      // navigate('/accountants/list');
    }
  }, [response]);

  useEffect(() => {
    if (isUpdatePassLoading) {
      return () => null;
    }

    if (isUpdatePassError) {
      response?.code === "DATA401" ? navigate("/admin/logout") : console.log("somting else");
      if (response?.data?.message) {
        setErrorMessage(response.data.message);
      }
      setBtnDisable(false);
      setPassBtnDisable(false);
      setShowPassErrorSnackbar(true);
      setTimeout(() => {
        setShowPassErrorSnackbar(false);
      }, 3000);
      return () => null;
    }

    if (UpadatePassResponse && UpadatePassResponse.data.isSuccess === true) {
      setPassShowSnackbar(true);
      setTimeout(() => {
        setPassShowSnackbar(false);
      }, 3000);
      setPassBtnDisable(false);
      SetChangePassword(false);
    }
  }, [UpadatePassResponse]);

  const handlePassSubmit = async (values) => {
    setPassBtnDisable(true);

    await UpdatePassAPICall({
      payload: [
        {
          password: `${values.password}`,
          confirmPassword: `${values.repeatPassword}`,
          bussinessId: `${AccbussinessId}`,
        },
      ],
    });
  };

  const handleSubmit = async (values) => {
    setBtnDisable1(true);
    await setAPICall({
      payload: [
        {
          email: `${values.email}`,
          businessName: `${values.businessname}`,
          phone: phone,
          fname: `${values.firstname}`,
          lname: `${values.lastname}`,
          bussinessId: `${AccbussinessId}`,
          isBlocked: activeUser,
        },
      ],
    });
  };
  const handleClose = () => {
    window.location.reload();
  };

  const ToggleChange = async () => {
    setActiveUser(!activeUser);
  };

  let emails = "";
  const handleChange = (e) => {
    setBtnDisable(true);
    if(e?.target?.value.length == 0){
      setStartCheckLoader(false);
    }
    setemailId(e.target.value);
    emails = e.target.value;
    setErrorMessages("");
  };

  const validate = (value) => {
    const finalEmail = emails ? emails : emailId.slice(0, -1);
    let errorMessage;
    if (!finalEmail) {
      errorMessage = "Email address is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(finalEmail)) {
      errorMessage = "Your email address is invalid";
    } else {
      errorMessage = "";
    }
    return errorMessage;
  };

  return (
    <>
      {showForm ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card
                        id="basic-info"
                        style={{ backgroundColor: darkMode ? "#202940" : "#ffffff" }}
                        sx={loaderClass}
                      >
                        <Formik
                          initialValues={initialValues}
                          validationSchema={AddNewSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ values, errors, touched, isSubmitting,handleBlur }) => (
                            <Form autoComplete="off">
                              <Card
                                sx={{
                                  height: "100%",
                                  backgroundColor: darkMode ? "#202940" : "#ffffff",
                                }}
                              >
                                <MDBox p={3}>
                                  <MDBox>
                                    <MDBox lineHeight={0}>
                                      <MDTypography variant="h5">Edit Accountant</MDTypography>
                                    </MDBox>
                                    <MDBox mt={1.625}>
                                      <Grid item xs={12} mb={1}>
                                        <FormField
                                          type="text"
                                          label="Business Name"
                                          name="businessname"
                                          disabled
                                        />
                                      </Grid>
                                      <MDBox lineHeight={0} mb={1} mt={1}>
                                        <MDTypography variant="h6" textTransform="capitalize">
                                          Accountant Info
                                        </MDTypography>
                                      </MDBox>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="First Name *"
                                            name="firstname"
                                            disabled={!editable}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="Last Name *"
                                            disabled={!editable}
                                            name="lastname"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12} mb={1}>
                                      <Field
                                        name="email"
                                        validate={validate}
                                      >
                                        {({ field }) => (
                                          <TextField
                                            {...field}
                                            label="Email *"
                                            variant="standard"
                                            fullWidth
                                            disabled={!editable}
                                            value={emailId}
                                            InputProps={{
                                              style: { borderBottom: darkMode && "1px solid", color: darkMode ? "#ffffff" : "#000" },
                                              endAdornment: startCheckLoader ? (
                                                <InputAdornment position="end">
                                                  <CircularProgress size={20} />
                                                </InputAdornment>
                                              ) : null,
                                            }}
                                            InputLabelProps={{
                                              style: { color: darkMode ? "#ffffff" : "#000000" },
                                            }}
                                            helperText={touched.email && errors.email ? (
                                              <FormHelperText sx={{ color: 'red' }}>
                                                {errors.email}
                                              </FormHelperText>
                                            ) : null}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleBlur(e);
                                              setStartCheckLoader(true);
                                            }}
                                            sx={{
                                              '& .MuiInput-underline:before': {
                                                borderBottom: '1px solid #d2d6da', // Adjust underline color and style
                                              },
                                              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                                borderBottom: '1px solid #d2d6da', // Adjust underline color and style on hover
                                              },
                                              '& .MuiInput-underline:after': {
                                                borderBottom: '1px solid #d2d6da', // Adjust underline color and style when active
                                              },
                                              '& .MuiInputBase-input.Mui-disabled': {
                                                color:darkMode ? '#ffffff': '#334e6e', // Adjust text color when disabled
                                                WebkitTextFillColor:darkMode ? '#ffffff': '#334e6e', // Adjust placeholder color in some browsers when disabled
                                              },
                                            }}
                                          />
                                        )}
                                      </Field>
                                      <MDBox>
                                        <MDTypography
                                          component="div"
                                          variant="caption"
                                          color="error"
                                          fontWeight="regular"
                                        >
                                          {
                                            <div
                                              style={{
                                                color: "#fb8c00",
                                                cursor: "pointer",
                                                marginTop: styleMt,
                                              }}
                                              // onClick={
                                              //   emailStatus != "ACTIVE" ? handleRestore : handleRestoreNone
                                              // }
                                            >
                                              {errorMessages}
                                            </div>
                                          }
                                        </MDTypography>
                                      </MDBox>
                                    </Grid>
                                      <Grid item xs={12}>
                                        <NumberFormat
                                          customInput={TextField}
                                          variant="standard"
                                          type="tel"
                                          fullWidth
                                          value={phone}
                                          disabled={!editable}
                                          label="Phone Numbers"
                                          InputLabelProps={{
                                            style: darkMode ? { color: "#ffffff" } : {},
                                          }}
                                          InputProps={{
                                            style: darkMode ? { color: "#ffffff" } : {},
                                          }}
                                          sx={
                                            darkMode
                                              ? {
                                                  "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "white",
                                                  },
                                                  mb: 1,
                                                  mt: 0.2,
                                                  fontSize: `0.823rem !important`,
                                                }
                                              : {
                                                  "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "black",
                                                  },
                                                  mb: 1,
                                                  mt: 0.2,
                                                  fontSize: `0.823rem !important`,
                                                }
                                          }
                                          format="(###) ###-####"
                                          mask=""
                                          onValueChange={(value) => setPhone(value.formattedValue)}
                                        />
                                      </Grid>
                                    </MDBox>
                                    <Grid sx={{ display: "flex" }}>
                                      <Grid item xs={3}>
                                        <MDBox mt={{ xs: 2, sm: 3 }} justifyContent="left">
                                          <Grid sx={{ pr: 11 }}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={!activeUser}
                                                  className={
                                                    classes.backgroundColorOnInnerBoxOfCustomIcon
                                                  }
                                                  onChange={ToggleChange}
                                                  disabled={!editable}
                                                />
                                              }
                                              label={
                                                <spna style={darkMode ? { color: "#ffffff" } : {}}>
                                                  Is Active
                                                </spna>
                                              }
                                              //disabled={isArchived}
                                            />
                                          </Grid>
                                          <MDTypography
                                            variant="caption"
                                            sx={{ cursor: "pointer" }}
                                            color="info"
                                            onClick={() => SetChangePassword(true)}
                                          >
                                            Change Password
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid item xs={9}>
                                        <MDBox
                                          sx={{ display: "flex" }}
                                          mt={{ xs: 2, sm: 3 }}
                                          justifyContent="end"
                                        >
                                          {!editable && (
                                            <MDButton
                                              type="submit"
                                              variant="gradient"
                                              color="info"
                                              onClick={() => SetEditable(true)}
                                            >
                                              Edit
                                            </MDButton>
                                          )}
                                          {editable && (
                                            <MDBox display="flex">
                                              <MDButton
                                                disabled={btnDisable}
                                                variant="gradient"
                                                color="light"
                                                onClick={handleClose}
                                              >
                                                Cancel
                                              </MDButton>
                                              <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                <MDButton
                                                  disabled={btnDisable}
                                                  type="submit"
                                                  variant="gradient"
                                                  color="info"
                                                >
                                                  {isArchived
                                                    ? "update"
                                                    : btnDisable1
                                                    ? "updating..."
                                                    : "update"}
                                                </MDButton>
                                              </MDBox>
                                            </MDBox>
                                          )}
                                        </MDBox>
                                      </Grid>
                                    </Grid>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </Form>
                          )}
                        </Formik>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/* change Password dialog */}

          <MDBox>
            <Dialog
              open={changePassword}
              onClose={() => SetChangePassword(false)}
              aria-labelledby="alert-dialog-title"
              sx={
                darkMode
                  ? {
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "570px",
                          backgroundColor: "#202940 !important",
                          color: "white !important",
                        },
                      },
                    }
                  : {
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "570px",
                        },
                      },
                    }
              }
            >
              <DialogTitle
                id="scroll-dialog-title"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  color: darkMode ? "#ffffff" : "#000000",
                }}
              >
                Change Password
              </DialogTitle>
              <DialogContent>
                <Grid item xs={12}>
                  {/* <Card id="change-password"> */}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={CreatePassSchema}
                    onSubmit={handlePassSubmit}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form autoComplete="off">
                        {/* <Card sx={{ height: "100%" }}> */}
                        <MDBox p={3}>
                          <MDBox>
                            <MDBox mt={1.625}>
                              <Grid item xs={12}>
                                <FormField
                                  type={showNPassword ? "text" : "password"}
                                  label="New Password *"
                                  name="password"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton sx={{ fontSize: "0.9rem" }}>
                                          {!showNPassword ? (
                                            <VisibilityOff style={darkMode ? { color: "#ffffff" } : {}} onClick={handleNewClickShowPassword} />
                                          ) : (
                                            <Visibility style={darkMode ? { color: "#ffffff" } : {}} onClick={handleNewClickClosePassword} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormField
                                  type={showRePassword ? "text" : "password"}
                                  label="Confirm New Password *"
                                  name="repeatPassword"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton sx={{ fontSize: "0.9rem" }}>
                                          {!showRePassword ? (
                                            <VisibilityOff style={darkMode ? { color: "#ffffff" } : {}} onClick={handleReClickShowPassword} />
                                          ) : (
                                            <Visibility style={darkMode ? { color: "#ffffff" } : {}} onClick={handleReClickClosePassword} />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            </MDBox>
                            <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                              <MDBox mt={5} display="flex" justifyContent="end">
                                <MDButton
                                  variant="gradient"
                                  color="light"
                                  onClick={() => SetChangePassword(false)}
                                >
                                  Cancel
                                </MDButton>
                                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                  <MDButton
                                    disabled={passBtnDisable}
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                  >
                                    {isArchived
                                      ? "update password"
                                      : passBtnDisable
                                      ? "updating..."
                                      : "update password"}
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                        {/* </Card> */}
                      </Form>
                    )}
                  </Formik>
                  {/* </Card> */}
                </Grid>
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="success"
              // autoHideDuration={3000}
              content="Accountant's has been successfully updated."
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              // autoHideDuration={3000}
              content="Accountant's Updation failed!"
              open={showErrorSnackbar}
              close={() => setShowErrorSnackbar(false)}
            />
            <MDSnackbar
              color="success"
              icon="done"
              title="success"
              content="Accountant's has been successfully updated."
              open={showPassSnackbar}
              // autoHideDuration={3000}
              close={() => setPassShowSnackbar(false)}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title="Failed."
              content={ErrorMessage}
              // autoHideDuration={3000}
              open={showPassErrorSnackbar}
              close={() => setShowPassErrorSnackbar(false)}
            />
          </MDBox>
        </DashboardLayout>
      ) : (
        <MDBox mt={4} display="flex" justifyContent="center">
          <LoaderProfile />
        </MDBox>
      )}
    </>
  );
}

export default Hoc(EditAccountant);
