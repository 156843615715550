import Cookies from 'universal-cookie';
import { useSelector } from "react-redux";

const cookies = new Cookies();

export const getUserPermissions = () => {
    // Try to get user_permissions from cookies
    const userPermissions = cookies.get('user_permissions');

    if (userPermissions) {
        // If found in cookies, return it (ensure it's parsed if it was stringified)
        return userPermissions;
    } else {
        // If not found, access the Redux state
        const permission = useSelector(state => state?.roleReducer?.permission);
        return permission;  // Return the permission from Redux
    }
};
